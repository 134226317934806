import { useMemo, Fragment } from "react";

import { IconTypes, dayjs } from "@flash-tecnologia/hros-web-ui-v2";
import { StyledIcon, StyledText, StyledTitle } from "@utils";

import {
  BottomLine,
  DateContainer,
  IconContainer,
  ItemContainer,
  Line,
  Separator,
  TimelineContainer,
  TopLine,
} from "./styled";

export type TimelineProps = {
  items: {
    label: string;
    icon: IconTypes;
    start: string | Date | dayjs.Dayjs;
    end: string | Date | dayjs.Dayjs;
    active?: boolean;
  }[];
};

export const Timeline = ({ items }: TimelineProps) => {
  const renderTop = useMemo(() => {
    return items?.map((item, index) => {
      const isFirst = index === 0;
      const isLast = index + 1 === items.length;

      const isStartValid = item.start !== "" && dayjs(item.start).isValid();
      const isEndValid = item.end !== "" && dayjs(item.end).isValid();

      const isActive =
        item.active || (isStartValid && dayjs().isSameOrAfter(item.start));

      const isFinished = isEndValid && dayjs().isAfter(item.end);

      return (
        <Fragment key={item.label}>
          {!isFirst && <Line active={isActive} />}
          <IconContainer
            firstChild={isFirst}
            lastChild={isLast}
            active={isActive}
          >
            <StyledIcon
              name={isFinished ? "IconCheck" : item.icon}
              setColor={isActive ? "secondary50" : "neutral30"}
              size={18}
            />
          </IconContainer>
        </Fragment>
      );
    });
  }, [items]);

  const renderBottom = useMemo(() => {
    return items?.map((item) => {
      const isStartValid = item.start !== "" && dayjs(item.start).isValid();
      const isEndValid = item.end !== "" && dayjs(item.end).isValid();
      const isActive =
        item.active || (isStartValid && dayjs().isSameOrAfter(item.start));

      return (
        <ItemContainer key={item.label}>
          <StyledTitle
            variant="body4"
            children={item.label}
            setColor={isActive ? "secondary50" : "neutral30"}
          />
          <DateContainer>
            <StyledText
              variant="caption"
              setColor="neutral30"
              children={
                isStartValid ? dayjs(item.start).format("DD/MM/YY") : ""
              }
            />
            <Separator variant="caption"> - </Separator>
            <StyledText
              variant="caption"
              setColor="neutral30"
              children={isEndValid ? dayjs(item.end).format("DD/MM/YY") : ""}
            />
          </DateContainer>
        </ItemContainer>
      );
    });
  }, [items]);

  return (
    <TimelineContainer>
      <TopLine children={renderTop} center={items?.length === 1} />
      <BottomLine children={renderBottom} center={items?.length === 1} />
    </TimelineContainer>
  );
};
