import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { trpc } from "@api/client";

import { dispatchToast, track } from "@utils";

import { Evaluations } from "./Evaluations";
import { Feedbacks } from "./Feedbacks";
import { Idp } from "./Idp";
import { MyTeam } from "./MyTeam";

import { Container, StyledTab } from "./styled";

import type { Cycle } from "server/src/services/cycle/types";

interface ITabsProps {
  cycle: Cycle;
}

export const Tabs = ({ cycle }: ITabsProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: employeeHierarchy } =
    trpc.employee.getEmployeeHierarchy.useQuery(undefined, {
      refetchOnMount: false,
      onError: () => {
        dispatchToast({
          content:
            "Erro ao carregar as informações do usuário. Tente novamente em breve.",
          type: "error",
        });
      },
    });

  const tabItens = useMemo(() => {
    const hasFeedbackTab = !!cycle?.steps?.find(
      ({ type }) => type === "feedback"
    );

    const hasIdpTab = !!cycle?.steps?.find(({ type }) => type === "idp");

    const items = [
      {
        key: "evaluation",
        label: "Minhas avaliações",
        component: <Evaluations cycleId={cycle?._id} />,
      },
    ];

    if (hasFeedbackTab)
      items.push({
        key: "feedback",
        label: "Feedbacks",
        component: <Feedbacks cycleId={cycle?._id} />,
      });

    if (hasIdpTab)
      items.push({
        key: "idp",
        label: "PDI",
        component: <Idp cycleId={cycle?._id} />,
      });

    if (employeeHierarchy?.isLeader)
      items.push({
        key: "myTeam",
        label: "Meus liderados",
        component: <MyTeam cycleId={cycle?._id} />,
      });

    return items;
  }, [cycle, employeeHierarchy]);

  const selectedTab = useMemo(() => {
    const tab = searchParams.get("tab") || "evaluation";

    const itemIndex = tabItens.findIndex((i) => i.key === tab);

    return itemIndex !== -1 ? itemIndex : 0;
  }, [searchParams, tabItens]);

  const onTabChanged = (tab: number) => {
    const item = tabItens[tab];

    if (!item) return;

    const trackLabels = {
      evaluation: "evaluations",
      feedback: "feedbacks",
      idp: "idps",
      myTeam: "mySubordinates",
    };

    const trackByTab = `people_strategic_hr_performance_employee_cycles_${
      trackLabels[item.key]
    }_clicked`;

    track({ name: trackByTab });

    searchParams.set("tab", item.key);

    setSearchParams(searchParams);
  };

  return (
    <Container>
      <StyledTab
        selected={selectedTab}
        tabItens={tabItens}
        onTabChanged={onTabChanged}
      />
    </Container>
  );
};
