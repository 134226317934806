import { createContext, useContext, useMemo, useState, useEffect } from "react";
import { compact, flatMap } from "lodash";

import {
  Checkbox,
  PillButton,
  Table as TableDS,
  tableControllers,
} from "@flash-tecnologia/hros-web-ui-v2";

import { pageSizeOptions } from "@utils";

import { usePagination } from "./hooks";

import { FilterDrawer } from "@components/Drawers/FilterDrawer";

import { DataType, useTransferList } from "../Root";
import { Grid } from "./Grid";
import { Header } from "./Header";

import {
  Container,
  FilterPillButton,
  SearchAndFilterContainer,
} from "./styled";

import type { ITableProps } from "../types";

import { Table as TableType } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/shared/table.types";

type TableContextProps = {
  loading: boolean;
  table: TableType<any>;
  data: any[];
  isExpandable: boolean;
};

const TableContext = createContext({} as TableContextProps);

export const Table = <T extends DataType>({
  loading,
  tableTitle,
  tableTagLabel,
  data,
  filters,
  columns,
  pagination: controlledPagination,
  options,
  viewByProps,
  filterInitialValues,
  filterBanner,
  searchValue,
  onSearchChange,
  onFilterChange,
  onPaginationChanged,
  customFilterEmptyState,
  customTableEmptyState,
}: ITableProps<T>) => {
  const [drawerFilter, setDrawerFilter] = useState(false);

  const { selected, addSelected, removeSelected } = useTransferList();

  const { paginatedData, pagination, handlePaginationChanged } = usePagination({
    initialPagination: undefined,
    controlledPagination,
    data,
    onPaginationChanged,
  });

  const { page, pageSize, totalCount } = pagination;

  const isExpandable = useMemo(
    () => !!options?.expandable,
    [options?.expandable]
  );

  const tableColumns = useMemo(() => {
    columns.unshift({
      id: "select",
      header: ({ table }) => {
        const tableRows = table.getRowModel().rows;

        const tableData = tableRows.map(({ original }) => original);

        const tableSubRows = compact(
          flatMap(tableRows, (item) =>
            item?.subRows?.map((subRow) => subRow.original)
          )
        );

        return (
          <Checkbox
            checked={false}
            disabled={isExpandable ? !tableSubRows.length : !tableData.length}
            onChange={() =>
              addSelected(isExpandable ? tableSubRows : tableData)
            }
          />
        );
      },
      cell: ({ row }) => {
        const rowData = row.original;

        const subRowData = row?.subRows?.map((row) => row.original) || [];

        if (isExpandable && !row.depth) {
          const everySubRowIsSelected =
            !!subRowData.length &&
            subRowData.every((rowData) =>
              selected.find(({ _id }) => _id === rowData._id)
            );

          const anySubRowIsSelected = subRowData.some((rowData) =>
            selected.find(({ _id }) => _id === rowData._id)
          );

          return (
            <Checkbox
              checked={everySubRowIsSelected}
              indeterminate={anySubRowIsSelected && !everySubRowIsSelected}
              onChange={(event) => {
                if (event.target.checked) return addSelected(subRowData);

                return removeSelected(subRowData);
              }}
            />
          );
        }

        const checked = !!selected.find(({ _id }) => _id === rowData._id);

        return (
          <Checkbox
            id={row.original._id}
            checked={checked}
            onChange={(event) => {
              if (event.target.checked) return addSelected([rowData]);

              return removeSelected([rowData]);
            }}
          />
        );
      },
    });

    return columns;
  }, [columns, selected, isExpandable, removeSelected, addSelected]);

  const table = tableControllers.useTableColumns<T>({
    total: totalCount,
    columns: tableColumns as any,
    data: paginatedData,
    options,
    pagination: {
      pageNumber: page,
      pageSize,
    },
    onPaginationChange: ({ pageNumber, pageSize }) =>
      handlePaginationChanged(pageNumber, pageSize),
  });

  useEffect(() => {
    return () => handlePaginationChanged(1, pageSize);
  }, [viewByProps?.selected]);

  return (
    <TableContext.Provider
      value={{
        loading,
        table,
        data,
        isExpandable,
      }}
    >
      <Container>
        <Header
          tableTitle={tableTitle}
          tableTagLabel={tableTagLabel}
          viewByProps={viewByProps}
        />

        <SearchAndFilterContainer>
          <TableDS.Search
            label="Pesquisar..."
            value={searchValue}
            onChange={(changed) => {
              if (changed?.target?.value === undefined) return;

              const timer = setTimeout(() => {
                onSearchChange && onSearchChange(changed?.target?.value ?? "");
              }, 700);

              return () => timer && clearTimeout(timer);
            }}
          />
          {onFilterChange && (
            <FilterPillButton
              hasFilters={
                filterInitialValues && !!Object.keys(filterInitialValues).length
              }
            >
              <PillButton
                variant="default"
                size="large"
                type="secondary"
                icon="IconFilter"
                onClick={() => setDrawerFilter(true)}
              />
            </FilterPillButton>
          )}
        </SearchAndFilterContainer>

        <Grid customEmptyState={customTableEmptyState} />

        <TableDS.Pagination
          count={totalCount}
          onPaginationChange={({ pageNumber, pageSize }) =>
            handlePaginationChanged(pageNumber, pageSize)
          }
          pagination={{
            pageNumber: page,
            pageSize,
          }}
          showItemRange
          pageSizeOptions={pageSizeOptions}
        />
        {onFilterChange && (
          <FilterDrawer
            isOpen={drawerFilter}
            isLoading={false}
            filters={filters ?? []}
            initialValues={filterInitialValues}
            onClose={() => setDrawerFilter(false)}
            onClear={() => {
              setDrawerFilter(false);
              onSearchChange && onSearchChange("");
              onFilterChange && onFilterChange({});
            }}
            onFilter={(filteredItems) => {
              setDrawerFilter(false);
              onFilterChange && onFilterChange(filteredItems);
            }}
            customEmptyState={customFilterEmptyState}
            filterBanner={filterBanner}
          />
        )}
      </Container>
    </TableContext.Provider>
  );
};

export const useTable = () => {
  return useContext(TableContext);
};
