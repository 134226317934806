import { useMemo, useState } from "react";

import { trpc } from "@api/client";

import { dispatchToast } from "@utils";

import { TableFilters } from "@components/TableFilters";
import { SearchField } from "@components/SearchField";

import { EvaluationMatrix } from "./EvaluationMatrix";
import { EmptyState } from "./EmptyState";
import { ViewSelector } from "./ViewSelector";

import { useEmptyState, useFilters, useSelectView } from "./hooks";

import { MainContainer } from "./styled";

import type { Evaluation } from "./types";

interface IMatrixTabProps {
  evaluation: Evaluation;
}

export const Matrix = ({ evaluation }: IMatrixTabProps) => {
  const [search, setSearch] = useState<string>("");

  const { showEmptyState, ...rest } = useEmptyState(evaluation);

  const { selectedView, setSelectedView } = useSelectView();

  const { filteredValues, setFilteredValues, mapFiltersWithValues } =
    useFilters();

  const [
    { data: calibrationData, isLoading: loadingMatrix },
    { data: originalData, isLoading: loadingMatrixOriginal },
    { data: filters, isLoading: loadingFilters },
  ] = trpc.useQueries((t) => [
    t.performance.evaluation.getEvaluationDetailsMatrix(
      {
        evaluationId: evaluation?._id || "",
        params: {
          search,
          filters: {
            departments: filteredValues.departmentIds,
            evaluateds: filteredValues.evaluateds,
            reportsTo: filteredValues.reportsTo,
            roles: filteredValues.roleId,
          },
        },
      },
      {
        enabled:
          !!evaluation?._id &&
          !showEmptyState &&
          selectedView === "calibration",
        onError: () => {
          dispatchToast({
            type: "error",
            content:
              "Erro ao buscar dados da matriz pós-calibração, tente novamente em breve!",
          });
        },
      }
    ),
    t.performance.evaluation.getEvaluationDetailsMatrixOriginal(
      {
        evaluationId: evaluation?._id || "",
        params: {
          search,
          filters: {
            departments: filteredValues.departmentIds,
            evaluateds: filteredValues.evaluateds,
            reportsTo: filteredValues.reportsTo,
            roles: filteredValues.roleId,
          },
        },
      },
      {
        enabled:
          !!evaluation?._id && !showEmptyState && selectedView === "original",
        onError: () => {
          dispatchToast({
            type: "error",
            content:
              "Erro ao buscar dados da matriz pré-calibração, tente novamente em breve!",
          });
        },
      }
    ),
    t.performance.evaluation.getEvaluationDetailsMatrixFilters(
      {
        evaluationId: evaluation?._id || "",
      },
      {
        enabled: !!evaluation?._id && !showEmptyState,
        onError: () => {
          dispatchToast({
            type: "error",
            content:
              "Erro ao buscar os filtros da matriz, tente novamente em breve!",
          });
        },
      }
    ),
  ]);

  const filtersWithValues = useMemo(
    () => mapFiltersWithValues(filters || []),
    [filters]
  );

  if (!evaluation) return <></>;

  return (
    <MainContainer>
      {!loadingFilters && !!filtersWithValues?.length && (
        <TableFilters
          style={{ marginBottom: "24px" }}
          filters={filtersWithValues}
          disabled={
            showEmptyState || selectedView === "original"
              ? !originalData?.quadrants.length
              : !calibrationData?.quadrants.length
          }
          onFilter={setFilteredValues}
        />
      )}

      {showEmptyState && <EmptyState {...rest} />}

      {!showEmptyState && (
        <>
          <SearchField
            placeholder="Buscar por pessoa"
            disabled={
              selectedView === "original"
                ? !originalData?.quadrants.length || loadingMatrixOriginal
                : !calibrationData?.quadrants.length || loadingMatrix
            }
            onChange={(searched) => setSearch(searched)}
          />
          <>
            <ViewSelector
              value={selectedView}
              onChange={setSelectedView}
              disabledCalibrated={
                !["in_calibration", "calibration_finished"].includes(
                  evaluation.evaluationStatus
                )
              }
            />

            <EvaluationMatrix
              type={selectedView}
              data={
                selectedView === "original" ? originalData : calibrationData
              }
              evaluation={evaluation}
              loading={
                selectedView === "original"
                  ? loadingMatrixOriginal
                  : loadingMatrix
              }
            />
          </>
        </>
      )}
    </MainContainer>
  );
};
