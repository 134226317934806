import { useTheme } from "styled-components";

import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";

import type {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

import { StyledText } from "@utils";

import { LegendArea, LegendDot, LegendText, TooltipBackground } from "./styled";

interface IGraphBarProps {
  type?: "grades" | "quadrants";
  data: {
    name: string;
    employees: number;
    employeesPercentage: number;
    calibratedEmployees?: number;
    calibratedEmployeesPercentage?: number;
  }[];
}

export const GraphBar = ({ data }: IGraphBarProps) => {
  const theme = useTheme();

  const CustomTooltip = ({
    payload,
    active,
  }: TooltipProps<ValueType, NameType>) => {
    if (!active || !payload || payload.length === 0) return null;

    const itemPayload = payload?.[0]?.payload;

    return (
      <TooltipBackground>
        <StyledText variant="body4" fontWeight={700} setColor="neutral100">
          {itemPayload?.name ?? ""}
        </StyledText>
        <StyledText variant="body4" setColor="neutral100">
          % participantes: {itemPayload?.employeesPercentage ?? ""}%
        </StyledText>
        <StyledText variant="body4" setColor="neutral100">
          Nº participantes: {itemPayload?.employees ?? ""}
        </StyledText>
        {itemPayload?.calibratedEmployees ? (
          <>
            <StyledText variant="body4" setColor="neutral100">
              % participantes calibrados:{" "}
              {itemPayload?.calibratedEmployeesPercentage ?? ""}%
            </StyledText>
            <StyledText variant="body4" setColor="neutral100">
              Nº participantes calibrados:{" "}
              {itemPayload?.calibratedEmployees ?? ""}
            </StyledText>
          </>
        ) : null}
      </TooltipBackground>
    );
  };

  const hasCalibration = Boolean(
    data?.find(({ calibratedEmployees }) => !!calibratedEmployees)
  );

  return (
    <>
      <ResponsiveContainer height={310}>
        <BarChart
          width={600}
          height={310}
          data={data}
          margin={{
            top: 30,
            right: 0,
            bottom: 0,
            left: 0,
          }}
        >
          <CartesianGrid
            stroke="#F8F6F8"
            strokeDasharray="0 0"
            vertical={false}
          />
          <XAxis
            dataKey="name"
            axisLine={false}
            tickLine={false}
            tick={{
              stroke: theme.colors.neutral[50],
              strokeWidth: 0.5,
            }}
            tickMargin={12}
          />
          <YAxis
            allowDecimals={false}
            axisLine={false}
            tickLine={false}
            allowDataOverflow
            tick={{ stroke: theme.colors.neutral[50], strokeWidth: 0.5 }}
            tickCount={4}
          />
          <Tooltip
            content={<CustomTooltip />}
            cursor={{ fill: "transparent" }}
          />
          <Bar
            dataKey="employees"
            barSize={64}
            fill={theme.colors.secondary[50]}
            label={{
              position: "top",
              stroke: theme.colors.neutral[50],
              fontSize: 14,
              fontWeight: 600,
            }}
          />
          {hasCalibration ? (
            <Bar
              dataKey="calibratedEmployees"
              barSize={64}
              fill={theme.colors.secondary[95]}
              label={{
                position: "top",
                stroke: theme.colors.neutral[50],
                fontSize: 14,
                fontWeight: 600,
              }}
            />
          ) : null}
        </BarChart>
      </ResponsiveContainer>
      {hasCalibration && (
        <LegendArea>
          <LegendText variant="body4" fontWeight={600}>
            <LegendDot color={theme.colors.secondary[50]} /> Nota final original
          </LegendText>
          <LegendText variant="body4" fontWeight={600}>
            <LegendDot color={theme.colors.secondary[95]} />
            Nota final calibrada
          </LegendText>
        </LegendArea>
      )}
    </>
  );
};
