import { CSSProperties } from "react";

import { VariantsType } from "@flash-tecnologia/hros-web-ui-v2";

import { StyledIcon, StyledText, avatarMaker } from "@utils";

import { AvatarContainer, ProfileArea, RoleContainer } from "./styled";

interface ProfileTableProps {
  name: string;
  email?: string | React.ReactNode;
  role?: string;
  customVariant?: { title?: VariantsType; subtitle?: VariantsType };
  avatarCustomStyle?: CSSProperties;
}

export const ProfileTable = ({
  name,
  email,
  role,
  customVariant,
  avatarCustomStyle,
}: ProfileTableProps) => {
  const renderDescription = (description: string | React.ReactNode) => {
    const isString = typeof description === "string";

    if (isString)
      return (
        <StyledText
          variant="body4"
          setColor="neutral50"
          weight={400}
          children={description}
        />
      );

    return description;
  };

  return (
    <ProfileArea>
      <AvatarContainer
        children={avatarMaker(name) || "-"}
        style={avatarCustomStyle}
      />
      <div>
        <StyledText
          variant={customVariant?.title || "body3"}
          setColor="neutral40"
          weight={600}
          children={name || "-"}
        />
        {role && (
          <RoleContainer>
            <StyledIcon name="IconBriefcase" size={14} setColor="neutral60" />
            <StyledText
              setColor="neutral50"
              variant={customVariant?.subtitle || "body4"}
              children={role}
            />
          </RoleContainer>
        )}
        {email && renderDescription(email)}
      </div>
    </ProfileArea>
  );
};
