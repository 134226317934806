import { FormikProps } from "formik";

import { TextField } from "@flash-tecnologia/hros-web-ui-v2";
import { Grid } from "@mui/material";

import {
  RequiredAsterisk,
  StyledIcon,
  StyledText,
  StyledTitle,
  track,
} from "@utils";

import { FlashTip } from "./FlashTip";
import { Matrix } from "../Matrix";

import { BorderFields, Divider, ErrorContainer, InputsRow } from "./styled";

import type { CalibrationMatrixFormProps } from "../../../../../types";

interface IGradeIntervalSectionProps {
  formik: FormikProps<CalibrationMatrixFormProps>;
  disabledEdit?: boolean;
}

type RenderInputsProps = {
  axisValue?: {
    centerInit: number;
    centerEnd: number;
  };
  type: "vertical" | "horizontal";
};

export const GradeIntervalSection = ({
  formik,
  disabledEdit,
}: IGradeIntervalSectionProps) => {
  const RenderInputs = ({ type }: RenderInputsProps) => {
    const values = formik.values;

    const isTouched =
      formik.touched[`${type}CenterInit`] || formik.touched[`${type}CenterEnd`];

    const hasError =
      isTouched &&
      (Boolean(formik.errors[`${type}CenterInit`]) ||
        Boolean(formik.errors[`${type}CenterEnd`]));

    return (
      <>
        <InputsRow>
          <BorderFields>
            <TextField
              label={
                <>
                  Inicio
                  <RequiredAsterisk />
                </>
              }
              value={1}
              disabled={true}
              fullWidth
            />
          </BorderFields>
          <Divider>-</Divider>
          <div>
            <TextField
              label={
                <>
                  Início do quadrante 2<RequiredAsterisk />
                </>
              }
              value={values?.[`${type}CenterInit`]?.toString() || ""}
              imaskProps={{
                mask: Number,
                scale: 1,
                radix: ".",
                mapToRadix: [".", ","],
                min: 1.1,
                normalizeZeros: true,
              }}
              disabled={disabledEdit}
              onBlur={(e) => {
                const newValue = Number(e.target.value);
                if (newValue === values?.[`${type}CenterInit`]) return;

                track({
                  name:
                    type === "horizontal"
                      ? "people_strategic_hr_performance_company_evaluations_createevaluation_calibrationmatrix_xaxis_intermediatevalue1_fill"
                      : "people_strategic_hr_performance_company_evaluations_createevaluation_calibrationmatrix_yaxis_intermediatevalue1_fill",
                });

                formik.handleChange({
                  target: {
                    id: `${type}CenterInit`,
                    value: newValue,
                  },
                });
              }}
              fullWidth
              error={
                formik.touched[`${type}CenterInit`] &&
                Boolean(formik.errors[`${type}CenterInit`])
              }
            />
          </div>
          <Divider>-</Divider>

          <div>
            <TextField
              label={
                <>
                  Final do quadrante 2<RequiredAsterisk />
                </>
              }
              value={values?.[`${type}CenterEnd`]?.toString() || ""}
              imaskProps={{
                mask: Number,
                scale: 1,
                radix: ".",
                mapToRadix: [".", ","],
                min: 1.1,
                normalizeZeros: true,
              }}
              disabled={disabledEdit}
              onBlur={(e) => {
                const newValue = Number(e.target.value);

                if (newValue === values?.[`${type}CenterEnd`]) return;

                track({
                  name:
                    type === "horizontal"
                      ? "people_strategic_hr_performance_company_evaluations_createevaluation_calibrationmatrix_xaxis_intermediatevalue2_fill"
                      : "people_strategic_hr_performance_company_evaluations_createevaluation_calibrationmatrix_yaxis_intermediatevalue2_fill",
                });

                formik.handleChange({
                  target: {
                    id: `${type}CenterEnd`,
                    value: newValue,
                  },
                });
              }}
              error={
                formik.touched[`${type}CenterEnd`] &&
                Boolean(formik.errors[`${type}CenterEnd`])
              }
              fullWidth
            />
          </div>
          <Divider>-</Divider>

          <BorderFields>
            <TextField
              label={
                <>
                  Final
                  <RequiredAsterisk />
                </>
              }
              value={values?.scaleEnd?.toString() || ""}
              disabled={true}
              fullWidth
            />
          </BorderFields>
        </InputsRow>
        {hasError && (
          <ErrorContainer>
            <StyledIcon name="IconAlertCircle" setColor="error40" size={12} />
            <StyledText
              variant="caption"
              setColor="neutral50"
              children={
                formik.errors[`${type}CenterInit`] ||
                formik.errors[`${type}CenterEnd`]
              }
            />
          </ErrorContainer>
        )}
      </>
    );
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={12} lg={8}>
          <StyledTitle
            variant="body2"
            setColor="neutral30"
            children={"Eixo horizontal"}
            style={{ marginBottom: 16 }}
          />
          <RenderInputs type="horizontal" />
          <StyledTitle
            variant="body2"
            setColor="neutral30"
            children={"Eixo vertical"}
            style={{ marginBottom: 16, marginTop: 24 }}
          />
          <RenderInputs type="vertical" />
          <FlashTip />
        </Grid>
        <Grid item md={12} lg={4} width={"100%"}>
          <Matrix />
        </Grid>
      </Grid>
    </>
  );
};
