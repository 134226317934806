import styled from "styled-components";

import { Select } from "@mui/material";

export const StyledSelect = styled(Select)`
  &.MuiInputBase-root {
    height: 40px;

    text-align: center;
    font-family: ${({ theme }) => theme.fonts.secondary};
    color: ${({ theme }) => theme.colors.neutral[40]};
    font-weight: 600;
    font-size: 14px;

    border: 1px solid ${({ theme }) => theme.colors.neutral[80]};
    border-radius: 40px;

    fieldset {
      border: none !important;
    }
  }
`;
