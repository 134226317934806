import styled from "styled-components";

export const BannerArea = styled.div`
  div:first-child {
    background-color: ${({ theme }) =>
      theme.colors.feedback.info[90]} !important;
  }
  .MuiButtonBase-root {
    position: relative !important;
    display: inline-block !important;
    margin-right: 2px !important;
  }
  svg {
    position: relative;
    display: inline-block;
    margin: 0px 2px;
    top: 2px;
  }
`;
