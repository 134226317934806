import { useMemo } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";

import { MenuItem } from "@mui/material";

import {
  DataGrid,
  EmptyState,
  Icons,
  dayjs,
  Typography,
  Pagination,
  Tag,
  Button,
  Dot,
} from "@flash-tecnologia/hros-web-ui-v2";

import { routes } from "@routes";

import { pageSizeOptions, renderHtmlText } from "@utils";

import { OverflowCheck } from "@components/OverflowCheck";

import { EvaluationOptions } from "./EvaluationOptions";

import {
  CellArea,
  CycleCellArea,
  ItensPerPageArea,
  PaginationArea,
  PaginationContainer,
  StyledSelectField,
  StyledText,
  StyledTypography,
} from "./styled";

import type { TableGridCommonProps } from "@components/PaginationTable/types";

interface ITableGridProps extends TableGridCommonProps {
  onCreateClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const TableGrid = ({
  data,
  pagination,
  loading,
  isFiltered,
  onPaginationChanged,
  onCreateClick,
}: ITableGridProps) => {
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        Header: "Informações privadas",
        disableSortBy: true,
        accessor: "privateName",
        Cell: ({ row: { original } }) => {
          const { privateName, privateDescription = "" } = original;

          return (
            <CellArea>
              <StyledText variant="body3">
                {OverflowCheck({ text: privateName, width: "210px" })}
              </StyledText>
              <Typography
                variant="body4"
                children={renderHtmlText(privateDescription)}
              />
            </CellArea>
          );
        },
      },
      {
        Header: "Informações públicas",
        disableSortBy: true,
        accessor: "name",
        Cell: ({ row: { original } }) => {
          const { name, description } = original;
          return (
            <CellArea>
              <StyledText variant="body3">
                {OverflowCheck({ text: name, width: "210px" })}
              </StyledText>
              <Typography
                variant="body4"
                children={renderHtmlText(description)}
              />
            </CellArea>
          );
        },
      },
      {
        Header: "Ciclo",
        disableSortBy: true,
        accessor: "cycleName",
        Cell: ({ value }) => {
          return (
            <CycleCellArea>
              <StyledText variant="body3">
                {OverflowCheck({
                  text: renderHtmlText(value),
                  width: "210px",
                })}
              </StyledText>
            </CycleCellArea>
          );
        },
      },
      {
        Header: "Status",
        accessor: "evaluationStatus",
        disableSortBy: true,

        Cell: ({ value }) => {
          const info = {
            active: { label: "Avaliação ativa", variant: "success" },
            scheduled: { label: "Avaliação agendada", variant: "info" },
            draft: { label: "Avaliação em rascunho", variant: "error" },
            finished: {
              label: "Avaliação encerrada",
              variant: "gray",
            },
            in_calibration: {
              label: "Em calibração",
              variant: "gray",
            },
            calibration_finished: {
              label: "Calibração encerrada",
              variant: "gray",
            },
          };

          return (
            <div style={{ display: "flex", gap: "8px" }}>
              <Tag variant={info?.[value]?.variant || "gray"}>
                <Dot variant={info?.[value]?.variant} />
                <Typography
                  variant="caption"
                  style={{
                    fontWeight: 600,
                    width: "max-content",
                  }}
                >
                  {info?.[value]?.label || " - "}
                </Typography>
              </Tag>
            </div>
          );
        },
      },
      // {
      //   Header: "Andamento das respostas",
      //   accessor: "progress",
      //   Cell: ({ row }) => (
      //     <div style={{ display: "flex", gap: "8px", width: "max-content" }}>
      //       <ProgressArea>
      //         <ProgressWrapper>
      //           <ProgressBar totalDone={row?.original?.progress}>
      //             <span />
      //           </ProgressBar>
      //         </ProgressWrapper>
      //         <div>{row?.original?.progress}%</div>
      //       </ProgressArea>
      //     </div>
      //   ),
      // },
      {
        Header: "Tipo de avaliação",
        accessor: "types",
        disableSortBy: true,
        Cell: ({ value }) => {
          return (
            <div style={{ display: "flex", gap: "8px" }}>
              {(value || []).map((v, index) => {
                const labels = {
                  byLeader: "Líder",
                  byLed: "Liderado",
                  self: "Autoavaliação",
                };

                return (
                  <Tag key={index} variant="gray" style={{ flexShrink: 0 }}>
                    <Typography variant="caption" style={{ fontWeight: 600 }}>
                      {labels[v?.type]}
                    </Typography>
                  </Tag>
                );
              })}
            </div>
          );
        },
      },
      {
        Header: "Início",
        disableSortBy: true,
        accessor: "initialDate",
        Cell: ({ row: { original } }) => {
          const { startDate } = original;
          return (
            <StyledText variant="body3">
              {startDate && dayjs(startDate).isValid()
                ? dayjs(startDate).format("DD/MM/YYYY")
                : " - "}
            </StyledText>
          );
        },
      },
      {
        Header: "Encerramento",
        disableSortBy: true,
        accessor: "finalDate",
        Cell: ({ row: { original } }) => {
          const { endDate } = original;
          return (
            <StyledText variant="body3">
              {endDate && dayjs(endDate).isValid()
                ? dayjs(endDate).format("DD/MM/YYYY")
                : " - "}
            </StyledText>
          );
        },
      },
      {
        Header: "Criado em",
        disableSortBy: true,
        accessor: "createdAt",
        Cell: ({ row: { original } }) => {
          const { createdAt } = original;
          return (
            <StyledText variant="body3">
              {createdAt && dayjs(createdAt).isValid()
                ? dayjs(createdAt).format("DD/MM/YYYY")
                : " - "}
            </StyledText>
          );
        },
      },
      {
        Header: "Ações",
        accessor: "action",
        sticky: "right",
        disableSortBy: true,
        Cell: ({ row }: any) => {
          const status = row.original.evaluationStatus;
          const types = (row.original.types || []).map((t) => t.type);

          const hasLeadType = types.includes("byLeader");

          const inCalibration = status === "in_calibration" && hasLeadType;

          const daysToExpire = dayjs(row?.original?.endDate).diff(
            dayjs(),
            "day"
          );

          return (
            <div
              style={{
                display: "flex",
                gap: "15px",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              {inCalibration && (
                <Button
                  size="small"
                  variant="primary"
                  children="Calibrar"
                  style={{ marginTop: "2.5px" }}
                  onClick={() => {
                    const evaluationId = row?.original?._id;

                    navigate({
                      pathname:
                        routes.PageManagerEvaluationDetails(evaluationId),
                      search: createSearchParams({
                        tab: "results",
                      }).toString(),
                    });
                  }}
                />
              )}

              <EvaluationOptions
                status={status}
                evaluationId={row?.original?._id || ""}
                daysToExpire={daysToExpire}
              />
            </div>
          );
        },
      },
    ],
    [data]
  );

  const { page, pageSize, totalCount } = pagination;

  if (!loading && !isFiltered && totalCount === 0) {
    return (
      <div style={{ marginBottom: "40px" }}>
        <EmptyState
          buttonText={
            <>
              Criar nova avaliação <Icons name="IconPlus" />
            </>
          }
          description={"Você ainda não criou avaliações"}
          buttonProps={{
            size: "medium",
            onClick: onCreateClick,
          }}
        />
      </div>
    );
  }

  return (
    <DataGrid
      hasPagination={true}
      columns={columns}
      data={data || []}
      loading={loading}
      initialState={{ pageSize: Number(pageSize) }}
      emptyState={{
        isFiltered: true,
        message: loading ? "" : "Nenhum avaliação encontrada",
      }}
      pageSizeOptions={pageSizeOptions}
      customPagination={({ setPageSize }) => (
        <PaginationArea>
          <ItensPerPageArea>
            <StyledSelectField
              value={pageSize ?? 10}
              onChange={(e) => {
                const newPageSize = Number(e?.target?.value);

                setPageSize(newPageSize);
                onPaginationChanged(page, newPageSize);
              }}
            >
              {pageSizeOptions?.map((opt) => (
                <MenuItem key={opt?.value} value={opt?.value}>
                  {opt?.label}
                </MenuItem>
              ))}
            </StyledSelectField>
            <StyledTypography variant="body3" style={{ fontWeight: 700 }}>
              Resultados:&nbsp;
              {page === 1 ? 1 : page * pageSize - pageSize + 1}-
              {page * pageSize > totalCount ? totalCount : page * pageSize}
              &nbsp;de&nbsp;{totalCount || 0}&nbsp;
              {totalCount === 1 ? "item" : "itens"}
            </StyledTypography>
          </ItensPerPageArea>
          <PaginationContainer>
            <Pagination
              defaultPage={1}
              page={page}
              count={Math.ceil(totalCount / pageSize)}
              onChange={(_, value) => {
                onPaginationChanged(value, pageSize);
              }}
            />
          </PaginationContainer>
        </PaginationArea>
      )}
    />
  );
};
