import { QueryClient } from "@tanstack/react-query";
import { createTRPCReact, httpBatchLink } from "@trpc/react-query";
import { inferRouterOutputs } from "@trpc/server";
import type { AppRouter } from "server";
import {
  getAccessToken,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";

type TrpcClientCustomProps = {
  selectedCompany: ReturnType<typeof useSelectedCompany>["selectedCompany"];
};

export const trpc = createTRPCReact<AppRouter>();

export type RouterOutput = inferRouterOutputs<AppRouter>;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      retryDelay: 3000,
      staleTime: 1000 * 60 * 5,
    },
    mutations: {
      retry: false,
    },
  },
});

export const trpcClient = ({ selectedCompany }: TrpcClientCustomProps) =>
  trpc.createClient({
    links: [
      httpBatchLink({
        // url: "http://localhost:3000/trpc",
        url: `${process.env.BFF_URL}/trpc`,
        headers: async () => {
          const token = await getAccessToken();
          return {
            Authorization: token,
            "x-flash-auth": `Bearer ${token}`,
            "x-selected-company": selectedCompany?.id,
          };
        },
      }),
    ],
  });
