import { TipCard } from "@components/Cards";

export const FlashTip = () => {
  return (
    <div style={{ marginTop: 24 }}>
      <TipCard
        icon="IconSparkles"
        title={"Dica da Flash!"}
        subTitle={
          <>
            Sugestões para a distribuição dos intervalos de notas na matriz.
            <br />
            Ex.: escala de 1 a 5 (Essa é apenas uma referência, já que a escala
            pode variar conforme necessário):
            <ul style={{ listStylePosition: "inside" }}>
              <li>
                <b>Divisão equilibrada:</b> Baixo: 1,0 - 2,3; Médio: 2,4 - 3,7;
                Alto: 3,8 - 5,0
              </li>
              <li>
                {" "}
                <b>Intervalo médio:</b> Baixo: 1,0 - 2,5; Médio: 2,6 - 4,0;
                Alto: 4,1 - 5,0
              </li>
            </ul>
          </>
        }
      />
    </div>
  );
};
