import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  DataGrid,
  Skeleton,
  EmptyState,
  dayjs,
  Icons,
  Tooltip,
} from "@flash-tecnologia/hros-web-ui-v2";

import { ProfileTable } from "@components/ProfileTable";
import { TableTag } from "@components/TableTag";

import { routes } from "@routes";

import {
  StyledIcon,
  StyledText,
  pageSizeOptions,
  renderHtmlText,
  track,
} from "@utils";

import {
  DataGridContainer,
  CellArea,
  TooltipText,
  StyledButton,
  ProfileArea,
  TagArea,
  Description,
  OptionsArea,
  LabelRow,
  TooltipContainer,
} from "./styled";

import type { TableGridCommonProps } from "@components/Table/types";

export const TableGrid = ({ data, rawData, loading }: TableGridCommonProps) => {
  const navigate = useNavigate();

  const { cycleId = "" } = useParams();

  const columns = useMemo(
    () => [
      {
        Header: "Informações",
        disableSortBy: true,
        accessor: "name",
        Cell: ({ row: { original } }) => {
          const { name, description = "" } = original;
          return (
            <CellArea>
              <TooltipText variant="body3">
                {name}
                <TooltipContainer>
                  <Tooltip title={renderHtmlText(description)}>
                    <div>
                      <Icons
                        name="IconInfoCircle"
                        fill="transparent"
                        size={16}
                      />
                    </div>
                  </Tooltip>
                </TooltipContainer>
              </TooltipText>
              <Description variant="body4">
                {renderHtmlText(description)}
              </Description>
            </CellArea>
          );
        },
      },
      {
        Header: "Avaliador",
        disableSortBy: true,
        accessor: "createdBy",
        Cell: ({ row: { original } }) => {
          const { evaluatorName } = original;
          return (
            <ProfileArea>
              <ProfileTable name={evaluatorName ?? "-"} />
            </ProfileArea>
          );
        },
      },
      {
        Header: "Avaliado",
        disableSortBy: true,
        accessor: "evaluated",
        Cell: ({ row: { original } }) => {
          const { evaluatedName } = original;
          return (
            <ProfileArea>
              <ProfileTable name={evaluatedName ?? "-"} />
            </ProfileArea>
          );
        },
      },
      {
        Header: "Tipo de avaliação",
        disableSortBy: true,
        accessor: "type",
        Cell: ({ row: { original } }) => {
          const { type } = original;

          const labels = {
            byLeader: { label: "Avaliar meu liderado", icon: "IconUsers" },
            byLed: { label: "Avaliar meu líder", icon: "IconUserUp" },
            self: { label: "Autoavaliação", icon: "IconUser" },
          };

          return (
            <TagArea>
              <TableTag
                label={
                  <LabelRow>
                    <StyledIcon
                      name={labels[type]?.icon}
                      setColor="secondary50"
                      size={12}
                      style={{ alignSelf: "center" }}
                    />
                    {labels[type]?.label}
                  </LabelRow>
                }
                variant={"pink"}
              />
            </TagArea>
          );
        },
      },
      {
        Header: "Status da resposta",
        disableSortBy: true,
        accessor: "status",
        Cell: ({ row: { original } }) => {
          const { status } = original;

          const labels = {
            answered: { label: "Respondido", color: "green" },
            started: { label: "Em andamento", color: "info" },
            unanswered: { label: "Não respondido", color: "yellow" },
          };

          return (
            <TagArea>
              <TableTag
                label={labels[status]?.label}
                variant={labels[status]?.color}
                hasDot={true}
              />
            </TagArea>
          );
        },
      },
      {
        Header: "Status da avaliação",
        disableSortBy: true,
        accessor: "availabilityStatus",
        Cell: ({ row: { original } }) => {
          const { availabilityStatus } = original;

          const labels = {
            available: { label: "Avaliação ativa", color: "green" },
            expired: { label: "Avaliação encerrada", color: "gray" },
            scheduled: { label: "Avaliação agendada", color: "info" },
            draft: { label: "Avaliação em rascunho", color: "yellow" },
          };

          return (
            <TagArea>
              <TableTag
                label={labels[availabilityStatus]?.label}
                variant={
                  labels[availabilityStatus]?.color as
                    | "green"
                    | "gray"
                    | "info"
                    | "yellow"
                }
                hasDot={true}
              />
            </TagArea>
          );
        },
      },
      {
        Header: "Início",
        disableSortBy: true,
        accessor: "startDate",
        Cell: ({ row: { original } }) => {
          const { startDate } = original;
          return (
            <StyledText variant="body3">
              {dayjs(startDate).format("DD/MM/YY")}
            </StyledText>
          );
        },
      },
      {
        Header: "Encerramento",
        disableSortBy: true,
        accessor: "endDate",
        Cell: ({ row: { original } }) => {
          const { endDate } = original;
          return (
            <StyledText variant="body3">
              {dayjs(endDate).format("DD/MM/YY")}
            </StyledText>
          );
        },
      },
      {
        Header: "Ação",
        disableSortBy: true,
        accessor: "action",
        sticky: "right",
        Cell: ({ row: { original } }) => {
          const { _id, answerStatus } = original;

          const labels = {
            answer: {
              label: "Responder",
              variant: "primary",
              icon: "IconArrowRight",
              showDetails: false,
              onClick: () => {
                track({
                  name: "people_strategic_hr_performance_employee_cycles_evaluations_answer_clicked",
                });
                navigate(routes.PageViewEvaluation(cycleId, _id, "index"));
              },
            },
            continue: {
              label: "Continuar",
              variant: "primary",
              icon: "IconArrowRight",
              showDetails: false,
              onClick: () => {
                navigate(routes.PageViewEvaluation(cycleId, _id, "index"));
              },
            },
            edit: {
              label: "Editar",
              variant: "secondary",
              icon: "IconPencil",
              showDetails: true,
              onClick: () => {
                navigate(routes.PageViewEvaluation(cycleId, _id, "index"));
              },
            },
            visualize: {
              label: "Ver detalhes",
              variant: "secondary",
              icon: "IconArrowRight",
              showDetails: false,
              onClick: () => {
                navigate(routes.PagePersonEvaluationDetails(_id));
              },
            },
          };

          return (
            <OptionsArea>
              <StyledButton
                variant={labels[answerStatus]?.variant}
                size={"small"}
                onClick={() => {
                  labels[answerStatus]?.onClick();
                  if (answerStatus === "notStarted") {
                    track({
                      name: "people_strategic_hr_performance_employee_cycles_evaluations_answer_clicked",
                    });
                  }
                }}
                children={
                  <>
                    {labels[answerStatus]?.label}
                    <Icons
                      name={labels[answerStatus]?.icon}
                      fill="transparent"
                      size={19}
                    />
                  </>
                }
              />
              {/* {labels[answerStatus]?.showDetails && (
                <RightButton
                  variant="line"
                  size="small"
                  icon="IconArrowRight"
                />
              )} */}
            </OptionsArea>
          );
        },
      },
    ],
    [data]
  );

  if (loading) return <Skeleton variant="rounded" height={"400px"} />;

  if (!rawData.length)
    return (
      <EmptyState
        buttonText={""}
        description={"Nenhuma avaliação foi encontrada!"}
        buttonProps={{ size: "small", hidden: true }}
      />
    );

  return (
    <DataGridContainer>
      <DataGrid
        hasPagination={true}
        columns={columns}
        data={data || []}
        initialState={{ pageSize: Number(10) }}
        emptyState={{
          isFiltered: false,
          message: "Nenhuma avaliação foi encontrada!",
        }}
        pageSizeOptions={pageSizeOptions}
      />
    </DataGridContainer>
  );
};
