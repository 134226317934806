import { Tag, Typography } from "@flash-tecnologia/hros-web-ui-v2";

import { useTransferList } from "../../Root";

import { Search } from "./search";

import { HeaderStyled, TitleStyled } from "./styled";

const Title = ({ count, label }: { count: number; label: string }) => {
  return (
    <>
      <TitleStyled>
        <Typography variant="headline8">{label}</Typography>
        <Tag variant="gray" as="span">
          {count}
        </Tag>
      </TitleStyled>
    </>
  );
};

const Header = ({ selectedLabel }: { selectedLabel: string }) => {
  const { selected } = useTransferList();

  return (
    <>
      <HeaderStyled>
        <Title count={selected?.length || 0} label={selectedLabel} />
        <Search />
      </HeaderStyled>
    </>
  );
};

export { Search, Header };
