import { useTheme } from "styled-components";

import {
  Divider,
  GenericProfilePicture,
  Icons,
  dayjs,
} from "@flash-tecnologia/hros-web-ui-v2";

import { FloatingCard, InfoCard } from "@components/Cards";

import { StyledText, StyledTitle } from "@utils";

import {
  DescriptionDot,
  DescriptionItem,
  DescriptionRowContainer,
  EmployeeInfoContainer,
  FlexColumn,
  StyledDescriptionIcon,
  StyledSkeleton,
  TitlePeopleContainer,
  TitlePeopleItem,
  TitleRowContainer,
  TitleTextsContainer,
} from "./styled";

import type { EmployeeEvaluation } from "server/src/services/evaluation/types";

type LabelType = {
  [key: string]: {
    title: string;
    type: string;
  };
};

type DetailsType = Omit<EmployeeEvaluation, "steps">;

interface IEvaluationDetailsProps {
  evaluation: DetailsType | undefined;
  isLoading?: boolean;
}

export const EvaluationDetails = ({
  evaluation,
  isLoading,
}: IEvaluationDetailsProps) => {
  const theme = useTheme();

  const {
    name,
    type = "",
    evaluated,
    evaluator,
    criteriaTotal,
    sectionsTotal,
    startDate,
    endDate,
  } = evaluation || {};

  const labels: LabelType = {
    self: { title: "Se autoavalie", type: "Autoavaliação" },
    byLed: { title: "Avalie seu líder", type: "Avaliação do líder" },
    byLeader: { title: "Avalie seu liderado", type: "Avaliação do liderado" },
  };

  const formatDate = (date: Date | string | undefined) => {
    if (!date) return "";

    return dayjs(date).isValid() ? dayjs(date).format("DD/MM/YYYY") : "";
  };

  if (isLoading) return <StyledSkeleton />;

  return (
    <FloatingCard gap={theme.spacings.xs2}>
      <TitleRowContainer>
        <TitleTextsContainer>
          <StyledText variant="body4" setColor="secondary50" weight={600}>
            {name}
          </StyledText>

          <StyledTitle variant="headline7" setColor="neutral20">
            {labels[type]?.title}
          </StyledTitle>
        </TitleTextsContainer>

        <TitlePeopleContainer>
          {!!evaluator && (
            <TitlePeopleItem>
              <StyledTitle variant="caption" setColor="neutral50">
                Avaliador
              </StyledTitle>
              <EmployeeInfoContainer>
                <GenericProfilePicture
                  enableEdit={false}
                  size={32}
                  name={evaluator?.name || ""}
                />
                <FlexColumn>
                  <StyledText variant="body4" setColor="neutral40" weight={600}>
                    {evaluator?.name}
                  </StyledText>

                  {/* <StyledText variant="caption" setColor="neutral60">
                    {evaluator?.email}
                  </StyledText> */}
                </FlexColumn>
              </EmployeeInfoContainer>
            </TitlePeopleItem>
          )}

          {!!evaluator && !!evaluated && (
            <Icons
              name="IconArrowRight"
              color={theme.colors.neutral60}
              style={{ flexShrink: 0 }}
              size={24}
            />
          )}

          {!!evaluated && (
            <TitlePeopleItem>
              <StyledTitle variant="caption" setColor="neutral50">
                Avaliado
              </StyledTitle>
              <EmployeeInfoContainer>
                <GenericProfilePicture
                  enableEdit={false}
                  size={32}
                  name={evaluated?.name || ""}
                />
                <FlexColumn>
                  <StyledText variant="body4" setColor="neutral40" weight={600}>
                    {evaluated?.name}
                  </StyledText>

                  {/* <StyledText variant="caption" setColor="neutral60">
                     {evaluated?.email}
                  </StyledText> */}
                </FlexColumn>
              </EmployeeInfoContainer>
            </TitlePeopleItem>
          )}
        </TitlePeopleContainer>
      </TitleRowContainer>

      <Divider orientation="horizontal" />

      <DescriptionRowContainer>
        <DescriptionItem>
          <StyledDescriptionIcon icon="IconNotes" variant="line" size="small" />
          <StyledText variant="body4" setColor="secondary50" weight={600}>
            {labels[type]?.type}
          </StyledText>
        </DescriptionItem>

        <DescriptionDot />

        <DescriptionItem>
          <StyledDescriptionIcon
            icon="IconQuestionMark"
            variant="line"
            size="small"
          />
          <StyledText variant="body4" setColor="secondary50" weight={600}>
            {sectionsTotal || 0} seções e {criteriaTotal || 0} critérios
          </StyledText>
        </DescriptionItem>
      </DescriptionRowContainer>

      <DescriptionRowContainer>
        <DescriptionItem>
          <StyledDescriptionIcon
            icon="IconCalendar"
            variant="line"
            size="small"
          />
          <StyledText variant="body4" setColor="secondary50" weight={600}>
            Prazo de resposta: {formatDate(startDate)} - {formatDate(endDate)}
          </StyledText>
        </DescriptionItem>
      </DescriptionRowContainer>
      {type === "byLeader" && (
        <InfoCard
          content="O liderado não terá acesso às notas via plataforma. Consulte o RH sobre a possibilidade de divulgá-las no feedback."
          icon="IconAlertCircle"
          variant="info"
        />
      )}
    </FloatingCard>
  );
};
