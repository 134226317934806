import styled from "styled-components";

export const SelectButton = styled.button<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;

  border-radius: 12px;
  padding: 10px 16px;

  background-color: ${({ theme, selected }) => {
    return selected ? theme.colors.secondary[95] : theme.colors.neutral[100];
  }};

  color: ${({ theme, selected }) => {
    return selected ? theme.colors.secondary : theme.colors.neutral[40];
  }};

  transition: 0.5s;
`;
export const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 16px;
  padding: 10px 8px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0px;
  margin-bottom: 32px;
  gap: 24px;
`;
