import { trpc } from "@api/client";

import { dispatchToast, getAccessTokenSync } from "@utils";

interface IUseAnswersInfoProps {
  isOpen: boolean;
  evaluatedId?: string;
  evaluationId?: string;
}

const useAnswersInfo = ({
  isOpen,
  evaluatedId = "",
  evaluationId = "",
}: IUseAnswersInfoProps) => {
  const { employeeId = "" } = getAccessTokenSync();

  const { data: employeeData, isFetching: loadingEmployeeData } =
    trpc.employee.getEmployeeDataByEvaluation.useQuery(
      {
        evaluationId,
        employeeId: evaluatedId,
      },
      {
        onError: () =>
          dispatchToast({
            content:
              "Erro ao buscar dados do avaliado. Tente novamente em breve.",
            type: "error",
          }),
        enabled: !!isOpen && !!evaluationId && !!employeeId,
      }
    );

  const {
    data: employeeResults,
    isFetching: loadingResults,
    isError: resultsError,
  } = trpc.performance.evaluation.getEmployeeResultsByEvaluator.useQuery(
    {
      evaluatedId,
      evaluationId,
      evaluatorId: employeeId,
      type: "byLeader",
    },
    {
      onError: () => {
        dispatchToast({
          content:
            "Erro ao buscar as respostas do avaliador. Tente novamente em breve.",
          type: "error",
        });
      },
      enabled: !!isOpen && !!employeeId && !!evaluationId && !!evaluatedId,
    }
  );

  return {
    employeeData,
    loadingEmployeeData: loadingEmployeeData,
    employeeResults,
    loadingResults,
    disabledSend: loadingResults || resultsError,
  };
};

export { useAnswersInfo };
