import { Tag } from "@flash-tecnologia/hros-web-ui-v2";

import { RoundedSelect } from "../../../../RoundedSelect";

import { StyledTitle } from "@utils";

import { Container, TitleContainer } from "./styled";

import type { ITransferListProps } from "../../types";

interface IHeaderProps {
  tableTitle: string;
  tableTagLabel?: string;
  viewByProps?: ITransferListProps<any>["viewByProps"];
}

export const Header = ({
  tableTitle,
  tableTagLabel,
  viewByProps,
}: IHeaderProps) => {
  return (
    <Container>
      <TitleContainer>
        <StyledTitle
          variant="headline8"
          setColor="neutral30"
          children={tableTitle}
        />

        {tableTagLabel && (
          <Tag
            variant={"gray"}
            size={"small"}
            as="div"
            style={{ flexShrink: 0 }}
          >
            {tableTagLabel}
          </Tag>
        )}
      </TitleContainer>

      {viewByProps && (
        <RoundedSelect
          onChange={(e) =>
            viewByProps.onSelectedChanged((e.target.value || "") as string)
          }
          renderValue={viewByProps.renderLabel}
          options={viewByProps.options}
          value={viewByProps.selected}
        />
      )}
    </Container>
  );
};
