import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  DataGrid,
  Skeleton,
  EmptyState,
  Tag,
  Dot,
  PillButton,
  Menu,
  Icons,
} from "@flash-tecnologia/hros-web-ui-v2";

import { trpc } from "@api/client";
import { routes } from "@routes";

import { StyledText, dispatchToast, pageSizeOptions, track } from "@utils";

import { DeleteIDPModal } from "@components/Modals";
import { IdpDrawer } from "@components/Drawers";

import {
  DeleteOption,
  Option,
  ProgressArea,
  ProgressBar,
  ProgressWrapper,
} from "./styled";

interface TableGridProps {
  data: any[];
  rawData: any[];
  pageSize: number;
  loading: boolean;
  selectedItem?: string;
  refetch: () => void;
}

export const TableGrid = ({
  data,
  rawData,
  pageSize,
  loading,
  refetch,
}: TableGridProps) => {
  const navigate = useNavigate();

  const utils = trpc.useContext();

  const [deleteIdp, setDeleteIdp] = useState({ idpId: "", isOpen: false });
  const [openDrawer, setOpenDrawer] = useState({ open: false, _id: "" });

  const { mutate: duplicateIdp } =
    trpc.performance.idp.duplicateIdp.useMutation({
      onSuccess: () => {
        dispatchToast({
          type: "success",
          content: "Plano duplicado com sucesso.",
        });

        utils.performance.idp.getIdpInsightsByEmployee.invalidate();
        refetch();
      },
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Erro ao duplicar, tente novamente em breve.",
        });
      },
    });

  const columns = useMemo(
    () => [
      {
        Header: "Plano de ação",
        accessor: "name",
        Cell: ({ row }) => (
          <div style={{ display: "flex", gap: "8px", width: "max-content" }}>
            <StyledText variant="body3" style={{ fontWeight: 600 }}>
              {row?.original?.name}
            </StyledText>
          </div>
        ),
      },
      {
        Header: "Tipo de plano",
        accessor: "from",
        Cell: ({ row }) => (
          <div style={{ display: "flex", gap: "8px", width: "max-content" }}>
            <StyledText variant="body3" style={{ fontWeight: 600 }}>
              {row?.original?.type === "independent"
                ? "Independente"
                : "Atrelado à avaliação"}
            </StyledText>
          </div>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          const info = {
            created: { label: "Não iniciado", variant: "gray" },
            progressing: { label: "Em andamento", variant: "info" },
            delayed: { label: "Atrasado", variant: "error" },
            finished: { label: "Concluído", variant: "success" },
          };

          return (
            <div style={{ display: "flex", gap: "8px", width: "150px" }}>
              <Tag variant={info[value]?.variant}>
                <Dot variant={info[value]?.variant} />
                <StyledText variant="caption" style={{ fontWeight: 600 }}>
                  {info[value]?.label}
                </StyledText>
              </Tag>
            </div>
          );
        },
      },
      {
        Header: "Progresso",
        accessor: "progress",
        Cell: ({ row }) => (
          <div style={{ display: "flex", gap: "8px", width: "max-content" }}>
            <ProgressArea>
              <ProgressWrapper>
                <ProgressBar totalDone={row?.original?.progress}>
                  <span />
                </ProgressBar>
              </ProgressWrapper>
              <div>{row?.original?.progress}%</div>
            </ProgressArea>
          </div>
        ),
      },
      {
        Header: "Qtd. de ações",
        accessor: "actions",
        Cell: ({ row }) => (
          <div style={{ display: "flex", gap: "8px", width: "max-content" }}>
            <StyledText variant="body3" style={{ fontWeight: 600 }}>
              {row?.original?.actions?.length}
            </StyledText>
          </div>
        ),
      },
      {
        Header: "Criado por",
        accessor: "employeeId",
        Cell: ({ row }) => (
          <div style={{ display: "flex", gap: "8px", width: "max-content" }}>
            <StyledText variant="body3" style={{ fontWeight: 600 }}>
              {row?.original?.createdBy?.name}
            </StyledText>
          </div>
        ),
      },
      {
        Header: "Ações",
        accessor: "action",
        sticky: "right",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Menu
              type={"select"}
              options={[
                {
                  onClick: () => {
                    track({
                      name: "performance_idp_persondetails_planslist_seeplandetails_clicked",
                    });
                    setOpenDrawer({ open: true, _id: row?.original?._id });
                  },
                  children: (
                    <Option>
                      <Icons
                        name="IconDeviceDesktopAnalytics"
                        fill="transparent"
                      />
                      <StyledText variant="body3" style={{ fontWeight: 600 }}>
                        Ver detalhes do plano de ação
                      </StyledText>
                    </Option>
                  ),
                },
                {
                  onClick: () => {
                    navigate(
                      routes.PageEditIDP.replace(":origin", "manage-idps")
                        .replace(":step", "1")
                        .replace(":idpId", row.original._id)
                    );
                  },
                  children: (
                    <Option>
                      <Icons name="IconPencil" fill="transparent" />
                      <StyledText variant="body3" style={{ fontWeight: 600 }}>
                        Editar plano de ação
                      </StyledText>
                    </Option>
                  ),
                },
                {
                  onClick: () => duplicateIdp({ _id: row.original._id }),

                  children: (
                    <Option>
                      <Icons name="IconCopy" fill="transparent" />
                      <StyledText variant="body3" style={{ fontWeight: 600 }}>
                        Duplicar plano de ação
                      </StyledText>
                    </Option>
                  ),
                },
                {
                  onClick: () =>
                    setDeleteIdp({ idpId: row.original._id, isOpen: true }),
                  children: (
                    <DeleteOption>
                      <Icons name="IconTrash" fill="transparent" />
                      <StyledText variant="body3" style={{ fontWeight: 600 }}>
                        Excluir plano de ação
                      </StyledText>
                    </DeleteOption>
                  ),
                },
              ]}
              disableAutoFocusItem={true}
              anchorOrigin={{ vertical: "center", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <PillButton
                variant="default"
                size="small"
                type="secondary"
                icon="IconDotsVertical"
              />
            </Menu>
          );
        },
      },
    ],
    []
  );

  if (loading) return <Skeleton variant="rounded" height={"400px"} />;

  if (!rawData.length)
    return (
      <EmptyState
        buttonText={""}
        description={"Ainda não existem PDIs disponíveis."}
        buttonProps={{ size: "small", hidden: true }}
      />
    );

  return (
    <>
      <DataGrid
        hasPagination={true}
        columns={columns}
        data={data || []}
        initialState={{ pageSize: Number(pageSize) }}
        emptyState={{
          isFiltered: true,
          message: "Nenhum PDI foi encontrado.",
        }}
        pageSizeOptions={pageSizeOptions}
      />
      <DeleteIDPModal
        open={deleteIdp.isOpen}
        idpId={deleteIdp.idpId}
        refetch={() => refetch()}
        onClose={() => setDeleteIdp({ idpId: "", isOpen: false })}
      />
      <IdpDrawer
        isOpen={openDrawer?.open}
        onClose={() => setOpenDrawer({ open: false, _id: "" })}
        dataId={openDrawer?._id ?? ""}
        origin="manage-idps"
      />
    </>
  );
};
