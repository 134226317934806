import { Divider, Grades, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

import { CommentaryField } from "./components";

import { StyledText, StyledTitle } from "@utils";

import {
  CommentaryContainer,
  CriterialContainer,
  CriterialTextsContainer,
  CriterialTitleContainer,
  CriterialsContainer,
  MainContainer,
} from "./styled";

import type {
  Evaluation,
  SectionsAnsweredType,
} from "server/src/services/evaluation/types";

interface IBodyProps {
  loadingSections?: boolean;
  sections: SectionsAnsweredType[];
  scale: Evaluation["scale"];
  generalCommentary: string;
  generalCommentaryEnabled: boolean;
}

export const Body = ({
  loadingSections,
  sections = [],
  scale,
  generalCommentary,
  generalCommentaryEnabled,
}: IBodyProps) => {
  if (loadingSections) return <Skeleton width={"100%"} height={"400px"} />;

  return (
    <MainContainer>
      {sections.map(({ name, criterials }) => {
        return (
          <>
            <StyledTitle
              variant="body2"
              setColor="secondary50"
              children={name}
              style={{ marginBottom: 24 }}
            />

            {(criterials || []).map((item, index) => {
              const value = item?.value?.toString() || "";

              return (
                <CriterialsContainer key={item._id}>
                  <CriterialContainer>
                    <CriterialTextsContainer>
                      <StyledTitle variant="headline8" setColor="neutral30">
                        {index + 1}.
                      </StyledTitle>

                      <CriterialTitleContainer>
                        <StyledTitle variant="headline8" setColor="neutral30">
                          {item.name}
                        </StyledTitle>

                        <StyledText variant="body3" setColor="neutral30">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.description || "",
                            }}
                            style={{
                              lineHeight: "normal",
                              width: "100%",
                              listStylePosition: "inside",
                            }}
                          />
                        </StyledText>
                      </CriterialTitleContainer>
                    </CriterialTextsContainer>

                    <Grades
                      value={value}
                      contentArray={(scale || []).map((scale) => {
                        const disabled = scale?.value?.toString() !== value;

                        return {
                          label: scale?.value?.toString() || "",
                          title: scale?.title || "",
                          subtitle: scale?.subtitle || "",
                          value: scale?.value?.toString() || "",
                          disabled: disabled,
                        };
                      })}
                      showTooltip={false}
                    />

                    <CommentaryField
                      commentaryField={
                        <CommentaryContainer>
                          <StyledTitle
                            variant="caption"
                            setColor="neutral50"
                            children="Comentário"
                          />
                          <StyledText variant="body3" setColor="neutral30">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.commentary || "",
                              }}
                              style={{
                                lineHeight: "normal",
                                width: "100%",
                                listStylePosition: "inside",
                              }}
                            />
                          </StyledText>
                        </CommentaryContainer>
                      }
                      commentaryEnabled={item?.commentaryEnabled}
                      commentaryExists={!!item?.commentary}
                      isGeneralCommentary={false}
                    />
                  </CriterialContainer>

                  <Divider orientation="horizontal" />
                </CriterialsContainer>
              );
            })}
          </>
        );
      })}

      <div>
        <StyledTitle
          variant="body2"
          setColor="secondary50"
          children={"Comentário geral"}
          style={{ marginBottom: 24 }}
        />

        <CommentaryField
          commentaryField={
            <CommentaryContainer>
              <StyledTitle
                variant="caption"
                setColor="neutral50"
                children="Comentário"
              />
              <StyledText variant="body3" setColor="neutral30">
                <div
                  dangerouslySetInnerHTML={{
                    __html: generalCommentary || "",
                  }}
                  style={{
                    lineHeight: "normal",
                    width: "100%",
                    listStylePosition: "inside",
                  }}
                />
              </StyledText>
            </CommentaryContainer>
          }
          commentaryEnabled={generalCommentaryEnabled}
          commentaryExists={!!generalCommentary}
          isGeneralCommentary={true}
        />
      </div>
    </MainContainer>
  );
};
