import styled from "styled-components";

export const CriterialsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const CriterialContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CriterialTextsContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
`;

export const CriterialTitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CommentaryContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  margin-right: 12px;
  width: 48px;
  height: 48px;
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  border-radius: 200px;
`;
