import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";

import { uniqBy } from "lodash";

import { Container } from "./styled";

type RootContextProps<T extends object & { _id: string }> = {
  selected: Array<T>;
  selectedFiltered: Array<T>;
  addSelected: (itemsToAdd: Array<T>) => void;
  removeSelected: (itemsToRemove: Array<T>) => void;
  onSelectedChanged: (selectedItems: Array<T>) => void;
  setSelectedFiltered: Dispatch<SetStateAction<T>>;
};

export type RootProps<T> = {
  children: ReactNode;
  selected: Array<T>;
  onSelectedChanged: (selectedItems: Array<T>) => void;
};

const RootContext = createContext<RootContextProps<any>>(
  {} as RootContextProps<any>
);

export type DataType = {
  _id: string;
};

export function Root<T extends DataType>({
  children,
  selected,
  onSelectedChanged,
}: RootProps<T>) {
  const [selectedFiltered, setSelectedFiltered] = useState<T[]>([]);

  const addSelected = (itemsToAdd: T[]) => {
    onSelectedChanged(uniqBy([...selected, ...itemsToAdd], "_id"));
  };

  const removeSelected = (itemsToRemove: T[]) => {
    const itemsToRemoveIds = itemsToRemove.map(({ _id }) => _id);

    onSelectedChanged(
      selected.filter(({ _id }) => !itemsToRemoveIds.includes(_id))
    );
  };

  return (
    <RootContext.Provider
      value={{
        selected,
        selectedFiltered,
        addSelected,
        removeSelected,
        setSelectedFiltered,
        onSelectedChanged,
      }}
    >
      <Container gap={"s"}>{children}</Container>
    </RootContext.Provider>
  );
}

export const useTransferList = () => {
  return useContext(RootContext);
};
