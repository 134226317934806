import { useState } from "react";

import type { UsePaginationProps } from "../types";

export const usePagination = <T extends object>({
  initialPagination,
  controlledPagination,
  data,
  onPaginationChanged,
}: UsePaginationProps<T>) => {
  const [internalPagination, setInternalPagination] = useState({
    page: initialPagination?.page ?? 1,
    pageSize: initialPagination?.pageSize ?? 10,
  });

  const isControlled = !!controlledPagination;

  const getPagination = () => {
    if (!isControlled)
      return { ...internalPagination, totalCount: data.length };

    return controlledPagination;
  };

  const getPaginatedData = () => {
    if (isControlled) return data;

    const { page, pageSize } = internalPagination;

    return data?.slice((page - 1) * pageSize, page * pageSize) || [];
  };

  const handlePaginationChanged = (page: number, pageSize: number) => {
    onPaginationChanged?.(page, pageSize);

    if (!isControlled) setInternalPagination({ page, pageSize });
  };

  return {
    pagination: getPagination(),
    paginatedData: getPaginatedData(),
    handlePaginationChanged,
  };
};
