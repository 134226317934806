import { useTheme } from "styled-components";

import { Divider, Grades } from "@flash-tecnologia/hros-web-ui-v2";

import { FloatingCard } from "@components/Cards";
import { TextEditor } from "@components/TextEditor";

import { StyledText, StyledTitle } from "@utils";

import {
  CriterialContainer,
  CriterialsContainer,
  CriterialTextsContainer,
  CriterialTitleContainer,
  StyledSkeleton,
  TitleContainer,
} from "./styled";

import type { EmployeeEvaluation, EmployeeEvaluationStep } from "../../types";

interface IEvaluationStepProps {
  scale?: EmployeeEvaluation["scale"];
  step: EmployeeEvaluationStep | undefined;
}

const Commentary = ({ step }: IEvaluationStepProps) => {
  const theme = useTheme();

  const { generalCommentary } = step || {};

  return (
    <FloatingCard gap={theme.spacings.xs2}>
      <TitleContainer>
        <StyledTitle variant="headline8" setColor="secondary50">
          Comentário (Opcional)
        </StyledTitle>

        <StyledText variant="body3" setColor="neutral30">
          Utilize o campo abaixo para observações caso deseje complementar algum
          assunto ou abordar algo que não foi contemplado durante a avaliação.
        </StyledText>
      </TitleContainer>

      <TextEditor
        id={`general-commentary`}
        styles={{
          container: { width: "100%" },
          editor: { height: "150px" },
        }}
        value={generalCommentary}
        placeholder="Comentário (opcional)"
        readOnly
      />
    </FloatingCard>
  );
};

const Sections = ({ scale, step }: IEvaluationStepProps) => {
  const theme = useTheme();

  const { name, description, criterials = [] } = step?.section || {};

  return (
    <FloatingCard gap={theme.spacings.s}>
      <TitleContainer>
        <StyledTitle variant="headline8" setColor="secondary50">
          {name}
        </StyledTitle>
        <StyledText variant="body3" setColor="neutral30">
          {description}
        </StyledText>
      </TitleContainer>

      {(criterials || []).map((item, index) => {
        const isLastItem = index === criterials.length - 1;

        const value = item?.value?.toString() || "";

        return (
          <CriterialsContainer key={item._id}>
            <CriterialContainer>
              <CriterialTextsContainer>
                <StyledTitle variant="headline8" setColor="neutral30">
                  {index + 1}.
                </StyledTitle>

                <CriterialTitleContainer>
                  <StyledTitle variant="headline8" setColor="neutral30">
                    {item.name}
                  </StyledTitle>

                  <StyledText variant="body3" setColor="neutral30">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.description || "",
                      }}
                      style={{
                        lineHeight: "normal",
                        width: "100%",
                        listStylePosition: "inside",
                      }}
                    />
                  </StyledText>
                </CriterialTitleContainer>
              </CriterialTextsContainer>

              <Grades
                value={value}
                variant="soft"
                contentArray={(scale || []).map((scale) => ({
                  label: scale?.value?.toString() || "",
                  title: scale?.title || "",
                  subtitle: scale?.subtitle || "",
                  value: scale?.value?.toString() || "",
                  disabled: true,
                }))}
                showTooltip={true}
              />

              {item.commentaryEnabled && (
                <TextEditor
                  id={`commentary-${item._id}`}
                  styles={{
                    container: { width: "100%" },
                    editor: { height: "120px" },
                  }}
                  value={item.commentary}
                  placeholder="Comentário (opcional)"
                  readOnly
                />
              )}
            </CriterialContainer>

            {!isLastItem && <Divider orientation="horizontal" />}
          </CriterialsContainer>
        );
      })}
    </FloatingCard>
  );
};

export const Evaluation = ({
  scale,
  step,
  isLoading,
}: IEvaluationStepProps & { isLoading: boolean }) => {
  if (isLoading) return <StyledSkeleton />;
  if (!step) return <></>;

  const { type } = step;

  const stepByType = {
    section: <Sections scale={scale} step={step} />,
    generalCommentary: <Commentary step={step} />,
  };

  return stepByType[type] || <></>;
};
