import { useEffect, useMemo, useState } from "react";
import { dayjs, Tab, TabProps } from "@flash-tecnologia/hros-web-ui-v2";

import { CycleDetail } from "./CycleDetail";
import { Evaluations } from "./Evaluations";
import { Feedbacks } from "./Feedbacks";
import { Idps } from "./Idps";

import type { Cycle } from "server/src/services/cycle/types";
import { track } from "@utils";

interface ITabsProps {
  cycle: Cycle | undefined;
}

export const Tabs = ({ cycle }: ITabsProps) => {
  const [tab, setTab] = useState(0);

  const feedbackStarted = !!cycle?.steps?.find(
    ({ type, startDate }) =>
      type === "feedback" && dayjs().isSameOrAfter(startDate)
  );

  const idpStarted = !!cycle?.steps?.find(
    ({ type, startDate }) => type === "idp" && dayjs().isSameOrAfter(startDate)
  );

  useEffect(() => {
    if (feedbackStarted) setTab(1);
    if (idpStarted) setTab(2);
  }, [cycle]);

  const tabItens = useMemo(() => {
    const items: TabProps["tabItens"] = [];
    const steps = cycle?.steps || [];

    items?.push({
      label: "Avaliações",
      component: <Evaluations />,
    });

    if (steps.find(({ type }) => type === "feedback")) {
      items?.push({
        label: "Feedbacks",
        component: <Feedbacks hasStarted={feedbackStarted} />,
      });
    }

    if (steps.find(({ type }) => type === "idp")) {
      items?.push({
        label: "PDI",
        component: <Idps hasStarted={idpStarted} />,
      });
    }

    items?.push({
      label: "Detalhes do ciclo",
      component: <CycleDetail cycle={cycle} />,
    });

    return items;
  }, [cycle]);

  return (
    <Tab
      id="cycle_detail_tabs"
      selected={tab}
      tabItens={tabItens}
      onTabChanged={(tab) => {
        const hasFeedback = cycle?.steps.find(
          ({ type }) => type === "feedback"
        );
        const hasIdp = cycle?.steps.find(({ type }) => type === "idp");
        if (hasFeedback && tab === 1) {
          track({
            name: "people_strategic_hr_performance_company_cycles_cycledetails_feedbacks_clicked",
          });
        }
        if (
          (hasFeedback && hasIdp && tab === 2) ||
          (!hasFeedback && hasIdp && tab === 1)
        ) {
          track({
            name: "people_strategic_hr_performance_company_cycles_cycledetails_idps_clicked",
          });
        }

        setTab(tab);
      }}
    />
  );
};
