import * as yup from "yup";

export const EDITOR_MAX_LENGTH = 3000;

export const validationSchema = yup.object({
  to: yup.string().required("Este campo deve ser preenchido"),
  requestMessage: yup.string().notRequired(),
  type: yup
    .string()
    .oneOf(["evaluation", "voluntary"])
    .required("Este campo deve ser preenchido"),
  evaluationId: yup.string().when("type", {
    is: (type) => type === "evaluation",
    then: (schema) => schema.required("Este campo deve ser preenchido"),
    otherwise: (schema) => schema.notRequired(),
  }),
});
