import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { EmptyState } from "@flash-tecnologia/hros-web-ui-v2";

import { Grid } from "@mui/material";

import Banner from "@components/Banner";

import { FeedbackReceived } from "./Tabs/FeedbackReceived";
import { FeedbackSent } from "./Tabs/FeedbackSent";

import { routes } from "@routes";

import { trpc } from "@api/client";

import { StyledText, StyledPLine, dispatchToast } from "@utils";

import { StyledLinkButton, TabButton } from "./styled";

interface FeedbacksProps {
  cycleId: string;
}

export const Feedbacks = ({ cycleId }: FeedbacksProps) => {
  const navigate = useNavigate();

  const [tab, setTab] = useState<"feedbacksReceived" | "feedbacksSent" | null>(
    "feedbacksReceived"
  );

  const {
    data: evaluationTypes,
    isLoading: loadingTypes,
    isError,
  } = trpc.performance.evaluation.getEvaluationTypesByCycle.useQuery(
    { cycleId: cycleId },
    {
      enabled: !!cycleId,
    }
  );

  const { data: receivedFeedbacks, isLoading: loadingReceived } =
    trpc.performance.feedback.getEmployeeReceivedFeedbacksByCycleId.useQuery(
      { cycleId: cycleId },
      {
        enabled: !!cycleId,
        onError: () => {
          dispatchToast({
            content:
              "Erro ao buscar feeedbacks recebidos. Tente novamente em breve.",
            type: "error",
          });
        },
      }
    );

  const { data: sentFeedbacks, isLoading: loadingSent } =
    trpc.performance.feedback.getLeaderFeedbacksByCycle.useQuery(
      { cycleId: cycleId },
      {
        enabled: !!cycleId,
        onError: () => {
          dispatchToast({
            content:
              "Erro ao buscar feeedbacks enviados. Tente novamente em breve.",
            type: "error",
          });
        },
      }
    );

  const isByLeaderNotSelected =
    !loadingTypes &&
    !isError &&
    !evaluationTypes?.find((t) => t === "byLeader");

  if (isByLeaderNotSelected)
    return (
      <div style={{ marginBottom: "20px" }}>
        <EmptyState
          description={
            <StyledText
              variant="body3"
              weight={600}
              setColor="neutral30"
              style={{
                maxWidth: "70%",
                textAlign: "center",
                display: "flex",
                justifySelf: "center",
              }}
              children={
                "Não é possível receber feedback associado ao ciclo do seu líder pois não há avaliações com a modalidade “Líder avalia liderado” neste ciclo. Ainda é possível enviar ou solicitar feedbacks espontâneos a todas as pessoas."
              }
            />
          }
          buttonText={"Enviar feedback"}
          buttonProps={{
            size: "large",
            onClick: () => navigate(routes.PageSendFeedback),
          }}
        />
      </div>
    );

  return (
    <div>
      <Banner
        type="info"
        icon="IconInfoCircle"
        title="Apenas os feedbacks associados a esse ciclo são listados aqui."
        subTitle="Para ver o restante dos feedbacks enviados e recebidos, clique na
        opção abaixo ou acesse a área de Feedbacks no menu principal."
        children={
          <StyledLinkButton
            variant="neutral"
            onClick={() => navigate(routes.PageMyFeedbacks)}
          >
            Ver todos os meus feedbacks
          </StyledLinkButton>
        }
        hasHideBanner={false}
        style={{ marginBottom: "24px" }}
      />

      <Grid container spacing={2}>
        <Grid item sm={12} md={2} width={"100%"} marginBottom={"24px"}>
          <TabButton
            active={tab === "feedbacksReceived"}
            onClick={() => setTab("feedbacksReceived")}
            style={{ marginBottom: "16px" }}
          >
            <StyledPLine numberOfLines={1}>
              <StyledText
                variant="body4"
                style={{ fontWeight: "700", textAlign: "left" }}
              >
                Feedbacks recebidos
              </StyledText>
            </StyledPLine>
          </TabButton>

          <TabButton
            active={tab === "feedbacksSent"}
            onClick={() => setTab("feedbacksSent")}
            style={{ marginBottom: "16px" }}
          >
            <StyledPLine numberOfLines={1}>
              <StyledText
                variant="body4"
                style={{ fontWeight: "700", textAlign: "left" }}
              >
                Feedbacks a enviar
              </StyledText>
            </StyledPLine>
          </TabButton>
        </Grid>

        <Grid item sm={12} md={10} width={"100%"}>
          {tab === "feedbacksReceived" && (
            <FeedbackReceived
              receivedFeedbacks={receivedFeedbacks}
              loading={loadingReceived || loadingTypes}
            />
          )}
          {tab === "feedbacksSent" && (
            <FeedbackSent
              sentFeedbacks={sentFeedbacks}
              loading={loadingSent || loadingTypes}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};
