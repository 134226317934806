import { useEffect, useLayoutEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useFormik } from "formik";

import { Grid } from "@mui/material";

import { trpc } from "@api/client";

import { PageTemplate } from "@components/PageTemplate";

import { ConfirmationModalV2 } from "@components/Modals";

import { ConfigurationForm } from "./ConfigurationForm";

import { FormLoading } from "../FormLoading";

import { routes } from "@routes";

import {
  StyledText,
  StyledTitle,
  StyledIcon,
  track,
  dispatchToast,
} from "@utils";

import { useConfigurationStepInfo } from "./hooks";

import { MainContainer, LeftContainer } from "../../styled";

import { validationSchema } from "./schema";

import type { BasicStepProps, ConfigurationFormProps } from "../../types";

export const ConfigurationStep = ({
  breadcrumbs,
  isLoading,
  isManager,
  isFromRequest,
  isCreate,
  isEdit,
  formValues,
  params,
  error,
  onNavigateByStep,
  onStepSubmit,
  setWithoutSaveModalOpen,
  setWithSaveModalOpen,
}: BasicStepProps) => {
  const navigate = useNavigate();

  const utils = trpc.useContext();

  const [sendFeedbackModal, setSendFeedbackModal] = useState(false);

  const formik = useFormik<ConfigurationFormProps>({
    initialValues: {
      message: "",
      companyValues: [],
      evaluationCriterials: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const companyValues = values?.companyValues || [];

      const evaluationCriterials = values?.evaluationCriterials || [];

      const commentedCriterials = (evaluationCriterials || []).filter(
        (item) => !!item.commentary
      );

      const basicInfoForm = formValues?.basicInfoForm;

      if (!basicInfoForm) return;

      if (isCreate) {
        sendFeedback(
          {
            requestId: params?.requestId || undefined,
            to: basicInfoForm.to,
            type: basicInfoForm.type,
            message: values.message,
            evaluationId: basicInfoForm.evaluationId || undefined,
            companyValues: companyValues,
            evaluationCriterials: commentedCriterials,
          },
          {
            onSuccess: () => {
              dispatchToast({
                type: "success",
                content: "Feedback enviado com sucesso!",
              });

              utils.performance.feedback.getFeedbackFull.invalidate();
              utils.performance.feedback.getEmployeeSentFeedbacks.invalidate();

              navigate(
                isManager ? routes.PageManageFeedbacks : routes.PageMyFeedbacks
              );
            },
          }
        );

        return;
      }

      if (isEdit) {
        updateFeedback(
          {
            feedbackId: params?._id || "",
            params: {
              to: basicInfoForm.to,
              type: basicInfoForm.type,
              message: values.message || undefined,
              evaluationId: basicInfoForm.evaluationId,
              companyValues: companyValues,
              evaluationCriterials: commentedCriterials,
            },
          },
          {
            onSuccess: () => {
              sendFeedbackById(
                {
                  feedbackId: params?._id || "",
                },
                {
                  onSuccess: () => {
                    dispatchToast({
                      type: "success",
                      content: "Feedback enviado com sucesso!",
                    });

                    utils.performance.feedback.getFeedbackFull.invalidate();
                    utils.performance.feedback.getEmployeeSentFeedbacks.invalidate();

                    navigate(
                      isManager
                        ? routes.PageManageFeedbacks
                        : routes.PageMyFeedbacks
                    );
                  },
                }
              );
            },
          }
        );
        return;
      }
    },
  });

  useEffect(() => {
    if (!formValues?.configurationForm) return;

    formik.setValues({
      ...formik.values,
      ...formValues.configurationForm,
    });
  }, [formValues?.configurationForm]);

  useLayoutEffect(() => {
    if (formValues?.basicInfoForm) return;

    onNavigateByStep("basic-info");
  }, [formValues?.basicInfoForm]);

  const {
    sections,
    commentaries,
    loadingCriterials,
    isError,
    isUpdating,
    updateFeedback,
    sendFeedback,
    sendFeedbackById,
  } = useConfigurationStepInfo({
    basicInfoForm: formValues?.basicInfoForm,
    feedbackId: params?._id,
  });

  const loading = isLoading || loadingCriterials;

  const hasError = isError || error;

  if (!formValues?.basicInfoForm) return <></>;

  return (
    <PageTemplate
      routes={breadcrumbs}
      footer={{
        cancelProps: {
          disabled: false,
          title: "Sair sem salvar",
          callback: () => {
            track({
              name: "performance_myfeedbacks_sendfeedback_exitwithoutsaving_clicked",
            });

            setWithoutSaveModalOpen();
          },
        },
        skipProps: {
          disabled: hasError || loading || isUpdating,
          title: "Sair e salvar rascunho",
          callback: async () => {
            track({
              name: "performance_myfeedbacks_sendfeedback_exitandsavedraft_clicked",
            });

            if (!formValues.basicInfoForm) {
              dispatchToast({
                type: "error",
                content:
                  "Favor preencher todos os campos obrigatórios da etapa anterior!",
              });

              return;
            }

            onStepSubmit({ configurationForm: formik.values });

            setWithSaveModalOpen();
          },
        },
        confirmProps: {
          disabled: hasError || loading || isUpdating,
          title: (
            <>
              <StyledIcon name="IconCheck" fill="transparent" />
              Enviar feedback
            </>
          ),
          width: "250px",
          loading: isUpdating,
          callback: async () => {
            track({
              name: "performance_myfeedbacks_sendfeedback_sendfeedback_clicked",
            });

            if (!formValues.basicInfoForm) {
              dispatchToast({
                type: "error",
                content:
                  "Favor preencher todos os campos obrigatórios da etapa anterior!",
              });

              return;
            }

            const errors = await formik.validateForm();

            if (Object.keys(errors).length) {
              dispatchToast({
                type: "error",
                content: "Favor preencher todos os campos obrigatórios!",
              });

              return formik.handleSubmit();
            }

            setSendFeedbackModal(true);
          },
        },
        goBackProps: {
          disabled: loading || isUpdating,
          title: (
            <>
              <StyledIcon name="IconArrowLeft" fill="transparent" />
              Voltar
            </>
          ),
          callback: () => {
            !hasError && onStepSubmit({ configurationForm: formik.values });

            onNavigateByStep("basic-info");
          },
        },
      }}
    >
      <MainContainer>
        <StyledTitle
          setColor="neutral20"
          variant="headline6"
          children={"Enviar novo feedback"}
        />
      </MainContainer>

      <Grid container paddingBottom={"40px"} spacing={2}>
        <Grid
          item
          sm={12}
          md={4}
          lg={3}
          paddingRight={"24px"}
          style={{ width: "100%" }}
        >
          <LeftContainer>
            <StyledTitle
              setColor="secondary50"
              variant="headline7"
              children={"Configurações"}
              style={{ paddingBottom: "16px" }}
            />

            <StyledText
              setColor="neutral50"
              variant="body3"
              children={
                "Configure os aspectos avaliados e o conteúdo do feedback."
              }
              style={{ paddingBottom: "16px" }}
            />
          </LeftContainer>
        </Grid>
        <Grid item sm={12} md={8} lg={9} style={{ width: "100%" }}>
          {loading ? (
            <FormLoading />
          ) : (
            <ConfigurationForm
              formik={formik}
              basicInfoForm={formValues.basicInfoForm}
              isFromRequest={isFromRequest}
              commentaries={commentaries || []}
              sections={sections || []}
            />
          )}
        </Grid>
      </Grid>

      <ConfirmationModalV2
        open={sendFeedbackModal}
        closeButton
        icon="IconInfoCircle"
        iconTitle="Atenção!"
        title="Tem certeza que deseja enviar o feedback?"
        subTitle={
          <StyledText variant="body4" setColor="neutral40">
            Os feedbacks corporativos não são anônimos, portanto o destinatário
            poderá ver quem os enviou.
            <br />
            <br />O <b>participante verá todos os feedbacks</b> após o envio,
            por isso, certifique-se de que o conteúdo seja construtivo e ajude
            no crescimento do colaborador.
          </StyledText>
        }
        cancel={{ title: "Voltar" }}
        confirm={{
          title: "Enviar feedback",
        }}
        isLoading={isUpdating}
        onClose={() => setSendFeedbackModal(false)}
        onConfirm={() => formik.handleSubmit()}
        surveyId="modal_send_feedback_survey"
      />

      {/* <ConfirmationModalV2
        open={removeAllCommsOpen}
        closeButton
        iconTitle="Atenção!"
        variantType="error"
        icon="IconAlertCircle"
        title="Tem certeza que deseja limpar e excluir todos os comentários?"
        subTitle="Todas as informações serão perdidas."
        cancel={{ title: "Cancelar" }}
        confirm={{
          title: "Limpar comentários",
          icon: "IconTrash",
        }}
        onClose={() => setRemoveAllCommsOpen(false)}
        onConfirm={() => console.log("remover todos os comentários")}
      /> */}
    </PageTemplate>
  );
};
