import { DatePicker, dayjs, Dayjs } from "@flash-tecnologia/hros-web-ui-v2";
import { StyledTitle } from "@utils";
import { Container, TipText } from "./styled";

interface IDateFilterProps {
  title: string;
  tipInfo?: string;
  initialValue?: Date | string;
  onDateChange: (value: Dayjs | undefined) => void;
}

export const DateFilter = ({
  title,
  tipInfo,
  initialValue = undefined,
  onDateChange,
}: IDateFilterProps) => {
  return (
    <Container>
      <StyledTitle variant="headline8" font-weight={700}>
        {title}
      </StyledTitle>
      <div>
        <DatePicker
          onDateChange={(value) => {
            dayjs(initialValue) != dayjs(value) && onDateChange(value);
          }}
          label={title}
          value={initialValue}
        />
        {tipInfo && (
          <TipText variant="caption" setColor="neutral60" tag={"p"}>
            {tipInfo}
          </TipText>
        )}
      </div>
    </Container>
  );
};
