import { dispatchToast } from "@utils";

import { trpc } from "@api/client";

import type { BasicInfoFormProps } from "../../types";

interface IUseConfigurationStepInfoProps {
  basicInfoForm?: BasicInfoFormProps;
  feedbackId?: string;
}

const useConfigurationStepInfo = ({
  basicInfoForm,
  feedbackId,
}: IUseConfigurationStepInfoProps) => {
  const { evaluationId, to, type } = basicInfoForm || {};

  const { data, isFetching, isError } =
    trpc.performance.feedback.getFeedbackCriterialsBySelection.useQuery(
      {
        evaluationId: evaluationId || "",
        to: to || "",
        feedbackId,
      },
      {
        enabled: type === "evaluation" && !!evaluationId && !!to,
        onError: () => {
          dispatchToast({
            type: "error",
            content: "Erro ao buscar os critérios da avaliação!",
          });
        },
      }
    );

  const { mutate: updateFeedback, isLoading: updateFeedbackLoading } =
    trpc.performance.feedback.updateFeedback.useMutation({
      onError: (e: any) => {
        let message =
          "Erro ao atualizar feedback, favor tentar novamente mais tarde!";

        const samePersonError = e?.data?.error === "FEEDBACK_SAME_PERSON_ERROR";

        const feedbackRequestNotExistsError =
          e?.data?.error === "FEEDBACK_REQUEST_NOT_EXISTS_ERROR";

        if (samePersonError)
          message = "Erro, destinatário é a mesma pessoa que está enviando!";

        if (feedbackRequestNotExistsError)
          message = "Erro, solicitação de feedback não encontrada!";

        dispatchToast({
          type: "error",
          content: message,
        });
      },
    });

  const { mutate: sendFeedback, isLoading: sendFeedbackLoading } =
    trpc.performance.feedback.sendFeedback.useMutation({
      onError: (e: any) => {
        let message =
          "Erro ao enviar feedback, favor tentar novamente mais tarde!";

        const samePersonError = e?.data?.error === "FEEDBACK_SAME_PERSON_ERROR";

        const feedbackRequestNotExistsError =
          e?.data?.error === "FEEDBACK_REQUEST_NOT_EXISTS_ERROR";

        if (samePersonError)
          message = "Erro, destinatário é a mesma pessoa que está enviando!";

        if (feedbackRequestNotExistsError)
          message = "Erro, solicitação de feedback não encontrada!";

        dispatchToast({
          type: "error",
          content: message,
        });
      },
    });

  const { mutate: sendFeedbackById, isLoading: sendFeedbackByIdLoading } =
    trpc.performance.feedback.sendFeedbackById.useMutation({
      onError: (e: any) => {
        let message =
          "Erro ao enviar feedback, favor tentar novamente mais tarde!";

        const samePersonError = e?.data?.error === "FEEDBACK_SAME_PERSON_ERROR";

        const feedbackRequestNotExistsError =
          e?.data?.error === "FEEDBACK_REQUEST_NOT_EXISTS_ERROR";

        if (samePersonError)
          message = "Erro, destinatário é a mesma pessoa que está enviando!";

        if (feedbackRequestNotExistsError)
          message = "Erro, solicitação de feedback não encontrada!";

        dispatchToast({
          type: "error",
          content: message,
        });
      },
    });

  return {
    isError: isError,
    loadingCriterials: isFetching,
    commentaries: data?.commentaries || [],
    sections: data?.sections || [],
    isUpdating:
      updateFeedbackLoading || sendFeedbackLoading || sendFeedbackByIdLoading,
    updateFeedback,
    sendFeedback,
    sendFeedbackById,
  };
};

export { useConfigurationStepInfo };
