import { useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { uniq, compact } from "lodash-es";
import { Grid } from "@mui/material";

import { Icons, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";

import { PageTemplate } from "@components/PageTemplate";
import Banner from "@components/Banner";

import { AddEvaluateds } from "./components/AddEvaluateds";

import {
  EvaluationWithSaveModal,
  EvaluationWithoutSaveModal,
} from "@components/Modals";

import { trpc } from "@api/client";
import { routes } from "@routes";

import {
  StyledTitle,
  StyledText,
  dispatchToast,
  track,
  getObjDiffs,
} from "@utils";

import type { BasicStepProps } from "../../Steps/types";
import type { EmployeeWithLeaderType } from "../../../../../../../server/src/services/employee/types";

import { Header, LeftContainer } from "../../Steps/styled";

type DataType = Partial<EmployeeWithLeaderType> & {
  _id: string;
  count?: number;
  checked?: boolean;
  subRows?: DataType[];
};

export const EvaluatedsStep = ({
  evaluationId,
  isEdit,
  isLoading,
  error,
  steps,
  breadcrumbs,
  evaluation,
  disabledEdit,
  refetch,
  onNavigateByStep,
}: BasicStepProps) => {
  const navigate = useNavigate();

  const utils = trpc.useContext();

  const [selectedParticipants, setSelectedParticipants] = useState<DataType[]>(
    []
  );

  const [exitAndSaveModal, setExitAndSaveModal] = useState(false);
  const [withoutSaveModal, setWithoutSaveModal] = useState(false);

  const typeExists = useCallback(
    (
      types: {
        type: "self" | "byLeader" | "byLed";
      }[],
      type: string
    ): boolean => (types || []).some((item) => item.type === type),
    []
  );

  const hasSelfType = typeExists(evaluation?.types || [], "self");
  const hasLedType = typeExists(evaluation?.types || [], "byLed");
  const hasLeaderType = typeExists(evaluation?.types || [], "byLeader");

  const isTypesEnabled = useCallback(() => {
    const hasSomeoneWithoutLed = !!selectedParticipants?.find(
      (p) => !p.isLeader
    );

    const hasSomeoneWithoutLeader = !!selectedParticipants?.find(
      (p) => !p.hasLeader
    );

    if (hasLedType && hasSomeoneWithoutLed) {
      dispatchToast({
        type: "error",
        content:
          "Alguns participantes não têm time associados, impedindo a realização das avaliações de liderados.",
      });

      return false;
    }

    if (hasLeaderType && hasSomeoneWithoutLeader) {
      dispatchToast({
        type: "error",
        content:
          "Alguns participantes não estão associados a um líder, impedindo a realização das avaliações do líderes.",
      });

      return false;
    }

    return true;
  }, [hasLedType, hasLeaderType, evaluation, selectedParticipants]);

  const { data: peopleToAdd = [], isFetching: isFetchingPeople } =
    trpc.employee.findEmployeesWithLeaders.useQuery(undefined, {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Erro ao buscar pessoas, favor tentar novamente mais tarde!",
        });
      },
    });

  const [
    { data: departments = [] },
    { data: groups = [] },
    { data: roles = [] },
  ] = trpc.useQueries((t) => [
    t.company.getDepartmentsByCompanyId(undefined, {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onError: () => {
        dispatchToast({
          type: "error",
          content:
            "Erro ao buscar departamentos, favor tentar novamente mais tarde!",
        });
      },
    }),
    t.company.getGroupsByCompanyIdProcedure(undefined, {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Erro ao buscar grupos, favor tentar novamente mais tarde!",
        });
      },
    }),
    t.company.getRolesByCompanyId(undefined, {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Erro ao buscar cargos, favor tentar novamente mais tarde!",
        });
      },
    }),
  ]);

  const { mutate: updateEvaluationParticipants, isLoading: isMutating } =
    trpc.performance.evaluation.updateEvaluationParticipants.useMutation({
      onSuccess: (_, variables) => {
        refetch?.();

        if (variables?.nextStep === "manageEvaluations") {
          return onNavigateByStep("manageEvaluations");
        }

        onNavigateByStep("form");
      },
      onError: (e: any) => {
        e?.data?.error === "ADD_EMPLOYEE_EVAL_ERROR" && refetch?.();

        dispatchToast({
          type: "error",
          content:
            "Erro ao vincular participantes na avaliação. Tente novamente em breve",
        });
      },
    });

  useEffect(() => {
    if (!evaluation?.evaluateds?.length && selectedParticipants.length) {
      setSelectedParticipants([]);
      return;
    }

    if (!evaluation?.evaluateds?.length) return;

    const selected = compact(
      evaluation.evaluateds.map((evaluated) => {
        return peopleToAdd?.find((p) => p._id === evaluated._id);
      })
    );

    setSelectedParticipants(selected || []);
  }, [JSON.stringify(evaluation?.evaluateds), JSON.stringify(peopleToAdd)]);

  const showBanner = useMemo(() => {
    const hasSomeoneWithoutLed = !!peopleToAdd?.find((p) => !p.isLeader);

    const hasSomeoneWithoutLeader = !!peopleToAdd?.find((p) => !p.hasLeader);

    if (
      (hasLedType && hasSomeoneWithoutLed) ||
      (hasLeaderType && hasSomeoneWithoutLeader)
    )
      return true;
    return false;
  }, [peopleToAdd, hasLedType, hasLeaderType]);

  const leaderFilter: { _id: string; key: string; label: string }[] =
    useMemo(() => {
      const leaders = uniq(
        peopleToAdd
          ?.filter(({ reportsTo }) => reportsTo)
          ?.map(({ reportsTo }) => reportsTo)
      );

      const mappedLeaders = compact(
        leaders?.map((leader) => {
          return peopleToAdd?.find(({ _id }) => _id === leader);
        })
      );

      return mappedLeaders?.map((lead) => ({
        _id: lead._id,
        key: lead._id,
        label: lead?.name,
      }));
    }, [JSON.stringify(peopleToAdd)]);

  return (
    <PageTemplate
      stepper={{
        steps: steps,
        activeStep: 3,
      }}
      routes={breadcrumbs || []}
      footer={{
        cancelProps: {
          title: "Sair sem salvar",
          callback: () => setWithoutSaveModal(true),
        },
        ...(evaluation?.evaluationStatus === "draft" && {
          draftProps: {
            disabled: isFetchingPeople || isLoading || isMutating || error,
            title: "Sair e salvar rascunho",
            callback: () => setExitAndSaveModal(true),
          },
        }),
        goBackProps: {
          title: (
            <>
              <Icons name="IconArrowLeft" fill="transparent" />
              Voltar
            </>
          ),
          callback: () => onNavigateByStep("configurations"),
        },
        confirmProps: {
          title: (
            <>
              Continuar
              <Icons name="IconArrowRight" fill="transparent" />
            </>
          ),
          loading: isMutating,
          disabled:
            error ||
            isFetchingPeople ||
            isLoading ||
            !selectedParticipants.length,
          callback: () => {
            track({
              name: "people_strategic_hr_performance_company_evaluations_createevaluation_settings_continue_clicked",
            });

            const isEditDisabled = disabledEdit.all;

            if (isEditDisabled) return onNavigateByStep("form");

            const typesEnabled = isTypesEnabled();

            if (!typesEnabled) return;

            const updatedValues = selectedParticipants.map((participants) => ({
              _id: participants._id,
            }));

            const baseValues = evaluation?.evaluateds || [];

            const hasChanges = getObjDiffs(
              { evaluateds: updatedValues },
              { evaluateds: baseValues }
            );

            if (!hasChanges) return onNavigateByStep("form");

            updateEvaluationParticipants({
              evaluationId: evaluationId || "",
              participants: selectedParticipants,
            });
          },
        },
      }}
    >
      <Header>
        <StyledTitle
          setColor="neutral20"
          variant="headline6"
          children={`${isEdit ? "Editar" : "Criar"} Avaliação`}
        />
      </Header>
      <Grid container paddingBottom={"40px"} spacing={2}>
        <Grid item sm={12} md={12} lg={3} style={{ width: "100%" }}>
          <LeftContainer>
            <StyledTitle
              setColor="secondary50"
              variant="headline7"
              children={"Avaliados"}
            />

            <StyledText
              setColor="neutral50"
              variant="body3"
              children={"Selecione os participantes que serão avaliados."}
            />

            {showBanner && (
              <Banner
                type="error"
                icon="IconX"
                title="Participantes sem líder e liderados"
                subTitle="Alguns participantes não estão associados a um líder ou liderado, impedindo a realização das avaliações."
                hasHideBanner={false}
                children={
                  <LinkButton
                    style={{ alignSelf: "flex-start" }}
                    variant="error"
                    onClick={() => window.open(`/employees`, "_blank")}
                  >
                    Associar líderes e liderados{" "}
                    <Icons name="IconExternalLink" />
                  </LinkButton>
                }
              />
            )}
          </LeftContainer>
        </Grid>
        <Grid item sm={12} md={12} lg={9} style={{ width: "100%" }}>
          <AddEvaluateds
            selectedBase={selectedParticipants}
            base={peopleToAdd}
            filtersBase={{
              startDate: [],
              endDate: [],
              reportsTo: leaderFilter,
              departments: [
                ...(departments || []).map((department) => ({
                  _id: department._id,
                  key: department._id,
                  label: department.name,
                })),
                { _id: "0", key: "0", label: "Sem departamento" },
              ],
              groups: [
                ...(groups || []).map((group) => ({
                  _id: group._id,
                  key: group._id,
                  label: group.name,
                })),
                { _id: "0", key: "0", label: "Sem grupo" },
              ],
              roles: [
                ...(roles || []).map((role) => ({
                  _id: role._id,
                  key: role._id,
                  label: role.name,
                })),
                { _id: "0", key: "0", label: "Sem cargo" },
              ],
            }}
            loading={isFetchingPeople || isLoading}
            onSelectedChanged={setSelectedParticipants}
            hasSelfType={hasSelfType}
            hasLedType={hasLedType}
            hasLeaderType={hasLeaderType}
            disabledEdit={disabledEdit}
          />
        </Grid>
      </Grid>

      <EvaluationWithoutSaveModal
        open={withoutSaveModal}
        onClose={() => setWithoutSaveModal(false)}
        onConfirm={() => {
          utils.performance.evaluation.getAllEvaluationsPaginated.invalidate();
          navigate(routes.PageManageEvaluations);
        }}
      />

      <EvaluationWithSaveModal
        open={exitAndSaveModal}
        isLoading={isMutating}
        onConfirm={() => {
          if (!selectedParticipants?.length || disabledEdit?.all)
            onNavigateByStep("manageEvaluations");

          const typesEnabled = isTypesEnabled();

          const updatedValues = selectedParticipants.map((participants) => ({
            _id: participants._id,
          }));

          const baseValues = evaluation?.evaluateds || [];

          const hasChanges = getObjDiffs(
            { evaluateds: updatedValues },
            { evaluateds: baseValues }
          );

          if (!hasChanges || !typesEnabled) {
            return onNavigateByStep("manageEvaluations");
          }

          updateEvaluationParticipants({
            nextStep: "manageEvaluations",
            evaluationId: evaluationId || "",
            participants: selectedParticipants,
          });
        }}
        onClose={() => setExitAndSaveModal(false)}
      />
    </PageTemplate>
  );
};
