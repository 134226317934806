import { useState, SetStateAction } from "react";
import { cloneDeep } from "lodash-es";

import { CheckboxFilter } from "@flash-tecnologia/hros-web-ui-v2";
import { DateFilter } from "../DateFilter";

import type { FilterType } from "../../types";

type OptionsProps = Parameters<typeof CheckboxFilter>[0]["options"];

interface IFilterItemProps extends FilterType {
  setFilterList: (value: SetStateAction<FilterType[]>) => void;
}

export const FilterItem = ({
  accessor,
  label,
  tipText,
  options,
  date,
  type,
  setFilterList,
}: IFilterItemProps) => {
  const [filterOptions, setFilterOptions] = useState<OptionsProps>(
    options.map((e) => ({
      label: e?.label,
      value: e?.value,
      checked: !!e?.checked,
      onChange: (value, checked) => handleOnChange(value, checked),
    }))
  );

  const handleSelectAll = (checked: boolean) => {
    setFilterOptions((current) => {
      const clonedOptions = cloneDeep(current);

      const mappedOptions = clonedOptions.map((option) => ({
        ...option,
        checked,
      }));

      return [...mappedOptions];
    });

    setFilterList((current) => {
      const clonedCurrent = cloneDeep(current);

      const itemIndex = current.findIndex((c) => c.accessor === accessor);

      if (itemIndex === -1) return current;

      const mappedOptions = clonedCurrent[itemIndex].options.map((option) => ({
        ...option,
        checked,
      }));

      clonedCurrent[itemIndex].options = mappedOptions;

      return [...clonedCurrent];
    });
  };

  const handleOnChange = (value: string, checked: boolean) => {
    setFilterOptions((current) => {
      const clonedOptions = cloneDeep(current);

      const itemIndex = clonedOptions.findIndex(
        (option) => option.value === value
      );

      if (itemIndex === -1) return current;

      clonedOptions[itemIndex].checked = checked;

      return [...clonedOptions];
    });

    setFilterList((current) => {
      const clonedCurrent = cloneDeep(current);

      const itemIndex = current.findIndex((c) => c.accessor === accessor);

      if (itemIndex === -1) return current;

      const optionIndex = clonedCurrent[itemIndex].options.findIndex(
        (option) => option.value === value
      );

      if (optionIndex === -1) return current;

      clonedCurrent[itemIndex].options[optionIndex].checked = checked;

      return [...clonedCurrent];
    });
  };

  if (type === "checkbox") {
    if (!filterOptions?.length) return <></>;
    return (
      <CheckboxFilter
        title={label}
        options={filterOptions}
        onAllSelection={handleSelectAll}
        allSelectLabel="Selecionar todos"
      />
    );
  }

  const handleDateChange = (value) => {
    setFilterList((current) => {
      const clonedCurrent = cloneDeep(current);

      const itemIndex = current.findIndex((c) => c.accessor === accessor);

      if (itemIndex === -1) return current;

      clonedCurrent[itemIndex].date = value;

      return clonedCurrent;
    });
  };

  return (
    <DateFilter
      title={label}
      initialValue={date}
      tipInfo={tipText}
      onDateChange={handleDateChange}
    />
  );
};
