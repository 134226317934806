import { FormikProps } from "formik";

import { FormCard } from "@components/Cards";
import { TextEditor } from "@components/TextEditor";

import { CompanyValues } from "../CompanyValues";
import { EvaluationSections } from "../EvaluationSections";

import { MainContainer } from "./styled";

import type {
  ConfigurationFormProps,
  BasicInfoFormProps,
  FeedbackCommentaries,
  FeedbackCriterialSections,
} from "../../../types";

interface IConfigurationFormProps {
  formik: FormikProps<ConfigurationFormProps>;
  isFromRequest?: boolean;
  basicInfoForm: BasicInfoFormProps;
  commentaries: FeedbackCommentaries;
  sections: FeedbackCriterialSections;
}

export const ConfigurationForm = ({
  formik,
  basicInfoForm,
  isFromRequest,
  commentaries,
  sections,
}: IConfigurationFormProps) => {
  const { type } = basicInfoForm;

  return (
    <MainContainer>
      {type === "evaluation" && (
        <EvaluationSections
          formik={formik}
          commentaries={commentaries}
          sections={sections}
          basicInfoForm={basicInfoForm}
        />
      )}

      <FormCard
        title="Feedback geral"
        description="Escreva o feedback de forma construtiva e de acordo com os parâmetros configurados anteriormente."
        mandatory={true}
        error={!!formik.touched.message && Boolean(formik.errors.message)}
        style={{ marginBottom: "38px" }}
      >
        <TextEditor
          id={`message`}
          styles={{
            container: { width: "100%" },
            editor: { height: "120px" },
          }}
          value={formik.values.message}
          maxLength={3000}
          placeholder="Feedback"
          onChange={(value) => {
            formik.handleChange({
              target: {
                id: "message",
                value: value,
              },
            });
          }}
          error={formik.touched.message && Boolean(formik.errors.message)}
          helperText={formik.touched.message ? formik.errors.message : ""}
        />
      </FormCard>

      {type === "voluntary" && (
        <CompanyValues
          formik={formik}
          isLoading={false}
          isFromRequest={isFromRequest}
        />
      )}
    </MainContainer>
  );
};
