import styled from "styled-components";

import { LinkButton } from "@flash-tecnologia/hros-web-ui-v2";

import { StyledText } from "@utils";

export const ValueArea = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

export const ValueNumber = styled.div`
  width: 5%;
`;

export const Line = styled.div<{ marginBottom?: string; marginTop?: string }>`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-top: ${({ marginTop }) => marginTop};
`;

export const Comment = styled(StyledText)<{ expandComment?: boolean }>`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${({ expandComment }) => (expandComment ? "none" : 3)};
  -webkit-box-orient: vertical;
`;

export const ExpandButton = styled(LinkButton)``;

export const ExpandArea = styled.div`
  display: inline-block;
  position: relative;
  top: -2px;
`;

export const InfoArea = styled.div`
  width: 95%;
`;

export const EmptyStateArea = styled.div`
  div {
    background: transparent;
    border: none;
  }
`;
