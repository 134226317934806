import { StyledText, track } from "@utils";

import { ViewContainer, RadioContainer, StyledRadio } from "./styled";

import type { MatrixViewTypes } from "../types";

interface IViewSelectorProps {
  value: MatrixViewTypes;
  disabledCalibrated?: boolean;
  onChange: (value: MatrixViewTypes) => void;
}

export const ViewSelector = ({
  value,
  disabledCalibrated,
  onChange,
}: IViewSelectorProps) => {
  return (
    <ViewContainer>
      <RadioContainer
        onClick={() => {
          track({
            name: "people_strategic_hr_performance_company_evaluations__evaluationsdetails_matrix_precalibrationmatrix_click",
          });
          onChange("original");
        }}
      >
        <StyledRadio checked={value === "original"} />
        <StyledText
          variant="body3"
          setColor="neutral30"
          children="Matriz pré-calibração"
          fontWeight={600}
        />
      </RadioContainer>

      <RadioContainer
        disabled={disabledCalibrated}
        onClick={() => {
          if (disabledCalibrated) return;
          track({
            name: "people_strategic_hr_performance_company_evaluations__evaluationsdetails_matrix_postcalibrationmatrix_click",
          });
          onChange("calibration");
        }}
      >
        <StyledRadio
          checked={value === "calibration"}
          disabled={disabledCalibrated}
        />
        <StyledText
          variant="body3"
          setColor="neutral30"
          children="Matriz pós-calibração"
          fontWeight={600}
        />
      </RadioContainer>
    </ViewContainer>
  );
};
