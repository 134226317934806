import {
  GenericProfilePicture,
  Icons,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

import { useTransferList } from "../../Root";

import {
  BodyStyled,
  EmptyListContainer,
  IconStyled,
  List,
  ListItemName,
  ListItemStyled,
  ListItemTrashStyled,
} from "./styled";

const ListItem = ({ data }: { data: { _id: string; name: string } }) => {
  const { removeSelected } = useTransferList();

  return (
    <>
      <ListItemStyled>
        <GenericProfilePicture size={40} name={data.name} />
        <ListItemName>
          <Typography variant="body4">{data.name}</Typography>
        </ListItemName>
        <ListItemTrashStyled>
          <Icons
            name="IconTrash"
            size={16}
            onClick={() => removeSelected([data])}
          />
        </ListItemTrashStyled>
      </ListItemStyled>
    </>
  );
};

const EmptyList = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  return (
    <EmptyListContainer>
      <IconStyled>
        <Icons name="IconUsers" size={24} color="#6B5B66" />
      </IconStyled>
      <BodyStyled>
        <Typography variant="headline9">{title}</Typography>
        <Typography variant="body4">{description}</Typography>
      </BodyStyled>
    </EmptyListContainer>
  );
};

export const SelectedList = () => {
  const { selected, selectedFiltered } = useTransferList();

  if (!selectedFiltered.length) {
    return (
      <EmptyList
        title={
          selected?.length
            ? "Nenhum participante encontrado"
            : "Nenhuma pessoa selecionada"
        }
        description={
          selected?.length
            ? "Por favor, tente novamente."
            : "Selecione as pessoas que deseja adicionar na lista ao lado"
        }
      />
    );
  }

  return (
    <>
      <List>
        {selectedFiltered.map((item) => (
          <ListItem key={item._id} data={item} />
        ))}
      </List>
    </>
  );
};
