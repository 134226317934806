import { useEffect } from "react";

import { useFormik } from "formik";

import { Grid } from "@mui/material";

import { PageTemplate } from "@components/PageTemplate";

import { BasicInfoForm } from "./BasicInfoForm";

import { FormLoading } from "../FormLoading";

import {
  StyledText,
  StyledTitle,
  StyledIcon,
  track,
  dispatchToast,
} from "@utils";

import { MainContainer, LeftContainer } from "../../styled";

import { validationSchema } from "./schema";

import type { BasicStepProps, BasicInfoFormProps } from "../../types";

export const BasicInfoStep = ({
  breadcrumbs,
  isFromRequest,
  isLeader,
  isLoading,
  formValues,
  params,
  error,
  onNavigateByStep,
  onStepSubmit,
  setWithoutSaveModalOpen,
  setWithSaveModalOpen,
}: BasicStepProps) => {
  const formik = useFormik<BasicInfoFormProps>({
    initialValues: {
      type: params?.type || "voluntary",
      to: params?.receiverId || "",
      evaluationId: "",
      requestMessage: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      onStepSubmit({ basicInfoForm: values });
      onNavigateByStep("configuration");
    },
  });

  useEffect(() => {
    if (!formValues?.basicInfoForm) return;

    formik.setValues({
      ...formik.values,
      ...formValues.basicInfoForm,
    });
  }, [formValues?.basicInfoForm]);

  return (
    <PageTemplate
      routes={breadcrumbs}
      footer={{
        cancelProps: {
          disabled: false,
          title: "Sair sem salvar",
          callback: () => {
            track({
              name: "performance_myfeedbacks_sendfeedback_exitwithoutsaving_clicked",
            });

            setWithoutSaveModalOpen();
          },
        },
        skipProps: {
          disabled: error || isLoading,
          title: "Sair e salvar rascunho",
          callback: async () => {
            track({
              name: "performance_myfeedbacks_sendfeedback_exitandsavedraft_clicked",
            });

            const errors = await formik.validateForm();

            if (Object.keys(errors).length) {
              dispatchToast({
                content: "Favor preencher os campos obrigátorios.",
                type: "error",
              });

              return formik.handleSubmit();
            }

            onStepSubmit({ basicInfoForm: formik.values });

            setWithSaveModalOpen();
          },
        },
        confirmProps: {
          disabled: error || isLoading,
          title: (
            <>
              Continuar
              <StyledIcon name="IconArrowRight" fill="transparent" />
            </>
          ),
          width: "250px",
          callback: () => formik.handleSubmit(),
        },
      }}
    >
      <MainContainer>
        <StyledTitle
          setColor="neutral20"
          variant="headline6"
          children={"Enviar novo feedback"}
        />
      </MainContainer>

      <Grid container paddingBottom={"40px"} spacing={2}>
        <Grid
          item
          sm={12}
          md={5}
          lg={4}
          paddingRight={"24px"}
          style={{ width: "100%" }}
        >
          <LeftContainer>
            <StyledTitle
              setColor="secondary50"
              variant="headline7"
              children={"Informações básicas"}
              style={{ paddingBottom: "16px" }}
            />

            <StyledText
              setColor="neutral50"
              variant="body3"
              children={
                "Envie um feedback a um colega. Configure os aspectos avaliados e o conteúdo do feedback."
              }
              style={{ paddingBottom: "16px" }}
            />
          </LeftContainer>
        </Grid>
        <Grid item sm={12} md={7} lg={8} style={{ width: "100%" }}>
          {isLoading ? (
            <FormLoading />
          ) : (
            <BasicInfoForm
              formik={formik}
              isFromRequest={isFromRequest}
              isLeader={isLeader}
              params={params}
              onStepSubmit={onStepSubmit}
            />
          )}
        </Grid>
      </Grid>
    </PageTemplate>
  );
};
