import { LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import { StyledText } from "@utils";
import styled from "styled-components";

export const ValueArea = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

export const ValueNumber = styled.div`
  width: 5%;
`;

export const Line = styled.div<{ marginBottom?: string; marginTop?: string }>`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-top: ${({ marginTop }) => marginTop};
`;

export const Comment = styled(StyledText)<{ expandComment?: boolean }>`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${({ expandComment }) => (expandComment ? "none" : 3)};
  -webkit-box-orient: vertical;
`;

export const ExpandButton = styled(LinkButton)``;

export const ExpandArea = styled.div`
  display: inline-block;
  position: relative;
  top: -2px;
`;

export const InfoArea = styled.div`
  width: 95%;
`;

export const Tag = styled.div<{ setColor?: string }>`
  display: flex;
  gap: 5px;
  align-items: center;
  border-radius: 14px;
  padding: 4px 12px;
  white-space: nowrap;
  color: ${({ theme, setColor }) => {
    switch (setColor) {
      case "green":
        return theme.colors.feedback.success[10];
      case "yellow":
        return theme.colors.feedback.error[10];
      case "gray":
        return theme.colors.neutral[10];

      default:
        return "";
    }
  }};

  background-color: ${({ theme, setColor }) => {
    switch (setColor) {
      case "green":
        return theme.colors.feedback.success[90];
      case "yellow":
        return theme.colors.feedback.error[90];
      case "gray":
        return theme.colors.neutral[90];

      default:
        return "";
    }
  }};
`;

export const FilterArea = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  gap: 16px;
`;

export const EmptyStateArea = styled.div`
  div {
    background: transparent;
    border: none;
  }
`;
