import styled from "styled-components";

export const InputsRow = styled.div`
  display: flex;
  aligin-items: center;
  justify-content: space-between;
  gap: 8px;
`;

export const BorderFields = styled.div`
  min-width: 82px;
  width: 82px;
`;

export const Divider = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1px;
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  margin-top: 8px;
`;
