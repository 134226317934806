import { useTheme } from "styled-components";

import { Table } from "@components/Table";
import { FloatingCard } from "@components/Cards";

import { StyledTitle, StyledText } from "@utils";

import { TableGrid } from "./TableGrid";

import { AverageContainer, TitleContainer } from "./styled";

import { EvaluationReviewData, EvaluationTypes } from "../../types";

interface IReviewData {
  reviewData: EvaluationReviewData[];
  errorCriterial: { _id: string }[] | undefined;
  answerAverage: number;
  maxGrade: number;
  evaluationType?: EvaluationTypes;
}

export const ReviewStep = ({
  reviewData = [],
  errorCriterial,
  answerAverage,
  maxGrade,
  evaluationType,
}: IReviewData) => {
  const theme = useTheme();

  return (
    <FloatingCard gap={theme.spacings.s}>
      <TitleContainer>
        <StyledTitle variant="headline8" setColor="secondary50">
          Revise suas respostas antes de enviar:
        </StyledTitle>

        <StyledText variant="body3" setColor="neutral30">
          Você ainda poderá realizar modificações na avaliação enquanto o prazo
          de resposta não expirar.
        </StyledText>
      </TitleContainer>
      {evaluationType === "byLeader" && (
        <AverageContainer>
          <StyledTitle
            variant="body3"
            children={`A nota final do liderado é`}
            weight={700}
            setColor="neutral40"
          />
          <StyledTitle
            variant="body2"
            children={answerAverage}
            setColor="secondary50"
          />
          <StyledTitle
            variant="body3"
            children={`/ ${maxGrade}`}
            setColor="neutral40"
          />
        </AverageContainer>
      )}
      <Table
        data={reviewData}
        loading={false}
        tableGridRender={(props) => (
          <TableGrid errorCriterial={errorCriterial} {...props} />
        )}
      />
    </FloatingCard>
  );
};
