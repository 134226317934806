import styled from "styled-components";

import { TransferListEmptyState } from "@assets/index";

export const EmptyStateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20;
  text-align: center;
  padding: 52px 0px;
  background-color: ${({ theme }) => theme.colors.neutral[95]};
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  button {
    margin-top: 24px;
  }
`;

export const EmptyStateImg = styled.div`
  width: 160px;
  height: 156px;
  background-image: url(${TransferListEmptyState});
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  margin-bottom: 24px;
`;
