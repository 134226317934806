import { Grid } from "@mui/material";

import { reverse, cloneDeep } from "lodash-es";

import { MatrixItem } from "./MatrixItem";

import type {
  MatrixQuadrantGridType,
  Evaluation,
  MatrixViewTypes,
} from "../../../types";

interface IMatrixProps {
  quadrants: MatrixQuadrantGridType[];
  evaluation: Evaluation;
  loading?: boolean;
  type: MatrixViewTypes;
}

export const Matrix = ({
  evaluation,
  quadrants,
  loading,
  type,
}: IMatrixProps) => {
  const reversedQuadrants = reverse(cloneDeep(quadrants));

  return (
    <Grid container spacing={2} direction={"row-reverse"} minWidth={1190}>
      {reversedQuadrants.map((quadrant) => (
        <Grid item width={"100%"} xs={4} key={quadrant._id}>
          <MatrixItem
            evaluation={evaluation}
            quadrant={quadrant}
            loading={loading}
            type={type}
          />
        </Grid>
      ))}
    </Grid>
  );
};
