import { StyledTitle, avatarMaker } from "@utils";

import {
  ArrowIcon,
  AvatarContainer,
  FlexCenter,
  MainContainer,
  PeopleArea,
  PersonContainer,
  ProfileContainer,
  StyledLoading,
} from "./styled";

interface IInfoGridProps {
  loading?: boolean;
  evaluated?: string;
  evaluator?: string;
}

export const InfoGrid = ({ evaluator, evaluated, loading }: IInfoGridProps) => {
  if (loading) return <StyledLoading children={<MainContainer />} />;

  if (!evaluator || !evaluated) return <></>;

  return (
    <MainContainer>
      <PeopleArea>
        <FlexCenter>
          <StyledTitle variant="caption" setColor="neutral40">
            Avaliador
          </StyledTitle>
          <ProfileContainer>
            <AvatarContainer
              children={avatarMaker(evaluator)}
              style={{ alignSelf: "center" }}
            />
            <PersonContainer>
              <StyledTitle
                variant="body4"
                children={evaluator}
                setColor="neutral40"
              />
              {/* <StyledText
              variant="caption"
              children={evaluator.role}
              setColor="neutral60"
            ></StyledText> */}
            </PersonContainer>
          </ProfileContainer>
        </FlexCenter>

        <ArrowIcon name="IconArrowRight" fill="transparent" />

        <FlexCenter rightSide>
          <StyledTitle variant="caption" setColor="neutral40">
            Avaliado
          </StyledTitle>
          <ProfileContainer>
            <AvatarContainer
              children={avatarMaker(evaluated)}
              style={{ alignSelf: "center" }}
            />
            <PersonContainer>
              <StyledTitle
                variant="body4"
                children={evaluated}
                setColor="neutral40"
              />
              {/* <StyledText
              variant="caption"
              children={evaluated.role}
              setColor="neutral60"
            ></StyledText> */}
            </PersonContainer>
          </ProfileContainer>
        </FlexCenter>
      </PeopleArea>
    </MainContainer>
  );
};
