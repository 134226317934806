import { Grades } from "@flash-tecnologia/hros-web-ui-v2";

import { TextEditor } from "@components/TextEditor";

import {
  Divider,
  PageDescription,
  PageTitle,
  QuestionCounter,
  QuestionDescription,
  QuestionHeader,
  QuestionTitle,
} from "./styled";

import type { Evaluation } from "server/src/services/evaluation/types";

interface IQuestionItemProps {
  number: number;
  name: string;
  description: string;
  value: string | number;
  commentary: string;
  scales: Evaluation["scale"];
}

const QuestionItem = ({
  number,
  name,
  description,
  value,
  commentary,
  scales = [],
}: IQuestionItemProps) => {
  return (
    <div>
      <QuestionHeader>
        <QuestionCounter variant="headline8">{number + 1}.</QuestionCounter>
        <QuestionTitle variant="headline8">{name}</QuestionTitle>
      </QuestionHeader>

      <QuestionDescription
        variant="body3"
        children={
          <div
            dangerouslySetInnerHTML={{
              __html: description || "",
            }}
          />
        }
      />

      <Grades
        value={value?.toString() || ""}
        showTooltip={true}
        variant="soft"
        contentArray={scales.map((scale) => ({
          label: scale?.value?.toString() || "",
          title: scale?.title || "",
          subtitle: scale?.subtitle || "",
          value: scale?.value?.toString() || "",
          disabled: scale?.order !== value,
        }))}
      />

      {commentary && (
        <TextEditor
          id={number?.toString()}
          styles={{
            container: { width: "100%", marginTop: "16px" },
            editor: { height: "120px" },
          }}
          readOnly={true}
          value={commentary}
          placeholder="Comentário (Opcional)"
        />
      )}
      <Divider />
    </div>
  );
};

interface IEvaluationTabProps {
  id: string;
  name: string;
  description: string;
  questions: any[];
  scales: any[];
}

export const EvaluationTab = ({
  id,
  name,
  description,
  questions,
  scales,
}: IEvaluationTabProps) => {
  return (
    <div key={id}>
      <PageTitle variant="headline8">{name}</PageTitle>
      <PageDescription variant="body3">{description}</PageDescription>
      {questions?.map((question, index) => (
        <QuestionItem
          key={question._id}
          number={index}
          name={question?.name || ""}
          description={question?.description || ""}
          value={question?.value}
          commentary={question?.commentary}
          scales={scales}
        />
      ))}
    </div>
  );
};
