import { FormikProps } from "formik";
import { cloneDeep } from "lodash-es";

import { TextField } from "@flash-tecnologia/hros-web-ui-v2";

import { calibrateSections } from "../utils";

import { StyledTitle, StyledText } from "@utils";

import {
  GradeContainer,
  LeftColumn,
  RightColumn,
  MainContainer,
  ColumnContainer,
  Line,
  TextFieldContainer,
} from "./styled";

import type { CalibrationFormType } from "../../../../types";

interface ICalibrationSectionsProps {
  formik: FormikProps<CalibrationFormType>;
}

export const CalibrationSections = ({ formik }: ICalibrationSectionsProps) => {
  const sections = formik.values?.sections || [];
  const scaleSize = formik?.values?.scaleSize;

  const calibrateCriterial = (
    newValue: number | undefined,
    sectionId: string,
    criterialId: string
  ) => {
    const clonedSections = cloneDeep(sections || []);

    const sectionIndex = clonedSections.findIndex((s) => s._id === sectionId);

    if (sectionIndex === -1) return;

    const criterialIndex = (
      clonedSections[sectionIndex].criterials || []
    ).findIndex((c) => c._id === criterialId);

    if (criterialIndex === -1) return;

    clonedSections[sectionIndex].criterials[criterialIndex].calibrationValue =
      newValue;

    const calibratedValues = calibrateSections({
      sections,
      calibratedSection: {
        _id: sectionId,
        criterialId: criterialId,
        calibrationValue: newValue,
      },
    });

    formik.setValues({
      ...formik.values,
      ...calibratedValues,
    });
  };

  return (
    <MainContainer>
      {sections.map((section, sectionIndex) => {
        return (
          <div key={section._id}>
            <ColumnContainer>
              <StyledTitle
                variant="headline8"
                children={section.name}
                setColor="secondary50"
              />

              {(section.criterials || []).map((criterial, criterialIndex) => (
                <GradeContainer key={criterial._id}>
                  <LeftColumn>
                    <StyledText
                      variant="caption"
                      setColor="neutral50"
                      children={criterial.name}
                      style={{ fontWeight: 600 }}
                    />
                    <StyledText
                      variant="body3"
                      setColor="neutral40"
                      children={criterial.value?.toFixed(1)}
                      style={{ fontWeight: 700 }}
                    />
                  </LeftColumn>

                  <RightColumn>
                    <TextFieldContainer>
                      <TextField
                        fullWidth
                        label="Nota calibrada"
                        id={"calibrationValue"}
                        value={criterial.calibrationValue?.toFixed(1) || ""}
                        imaskProps={{
                          mask: Number,
                          scale: 1,
                          radix: ".",
                          mapToRadix: [".", ","],
                          min: 0,
                          max: scaleSize || 5,
                          normalizeZeros: true,
                        }}
                        onChange={(e) => {
                          const newValue = e.target.value
                            ? Number(e.target.value)
                            : undefined;

                          if (newValue === criterial.calibrationValue) return;

                          calibrateCriterial(
                            newValue,
                            section._id,
                            criterial._id
                          );
                        }}
                        error={
                          formik?.touched?.sections?.[sectionIndex]
                            ?.criterials?.[criterialIndex]?.calibrationValue &&
                          Boolean(
                            (formik?.errors?.sections?.[sectionIndex] as any)
                              ?.criterials?.[criterialIndex]?.calibrationValue
                          )
                        }
                        helperText={
                          formik?.touched?.sections?.[sectionIndex]
                            ?.criterials?.[criterialIndex]?.calibrationValue &&
                          ((formik?.errors?.sections?.[sectionIndex] as any)
                            ?.criterials?.[criterialIndex]
                            ?.calibrationValue as string)
                        }
                      />
                    </TextFieldContainer>
                  </RightColumn>
                </GradeContainer>
              ))}
              <GradeContainer>
                <LeftColumn>
                  <StyledText
                    variant="caption"
                    setColor="neutral50"
                    children={"Nota da seção original"}
                    style={{ fontWeight: 600 }}
                  />

                  <StyledTitle
                    variant="body3"
                    setColor="neutral40"
                    children={section?.average?.toFixed(1)}
                    style={{ fontWeight: 700 }}
                  />
                </LeftColumn>
                <RightColumn>
                  <TextField
                    label="Nota da seção calibrada"
                    value={section?.calibrationAverage?.toFixed(1) || ""}
                    fullWidth
                    disabled={true}
                    style={{ height: "60px" }}
                  />
                </RightColumn>
              </GradeContainer>
            </ColumnContainer>
            <Line />
          </div>
        );
      })}

      <ColumnContainer>
        <StyledTitle
          variant="body2"
          children={"Nota final"}
          setColor="secondary50"
        />

        <GradeContainer>
          <LeftColumn>
            <StyledTitle
              variant="caption"
              setColor="neutral50"
              children={"Nota final original"}
              style={{ fontWeight: 600 }}
            />
            <StyledTitle
              variant="body3"
              setColor="neutral40"
              children={formik.values.average?.toFixed(1)}
              style={{ fontWeight: 700 }}
            />
          </LeftColumn>
          <RightColumn>
            <TextFieldContainer>
              <TextField
                label="Nota final calibrada"
                id={"calibrationAverage"}
                value={formik.values.calibrationAverage?.toFixed(1) || ""}
                fullWidth
                disabled={true}
              />
            </TextFieldContainer>
          </RightColumn>
        </GradeContainer>
      </ColumnContainer>
    </MainContainer>
  );
};
