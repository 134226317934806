import { useState } from "react";

import { FormikProps } from "formik";
import { cloneDeep } from "lodash-es";

import { Skeleton, Button, Divider } from "@flash-tecnologia/hros-web-ui-v2";

import { getFromLS } from "@flash-tecnologia/hros-web-utility";

import { TextEditor } from "@components/TextEditor";

import { AddCompanyValuesModal } from "@components/Modals";

import {
  StyledIcon,
  StyledText,
  StyledTitle,
  avatarMaker,
  track,
} from "@utils";

import {
  AvatarContainer,
  CommentaryContainer,
  CriterialContainer,
  CriterialTextsContainer,
  CriterialTitleContainer,
  CriterialsContainer,
} from "./styled";

import type { ConfigurationFormProps } from "../../../../types";

interface TableGridProps {
  formik: FormikProps<ConfigurationFormProps>;
  loading: boolean;
  isFromRequest?: boolean;
  onValuesChanged: (values: any[]) => void;
}

export const ValuesList = ({
  formik,
  loading,
  isFromRequest,
  onValuesChanged,
}: TableGridProps) => {
  const userAuth = getFromLS("userAuth");

  const leaderName = userAuth?.attributes?.name || "";

  const data = formik.values?.companyValues || [];

  const [addModalOpen, setAddModalOpen] = useState(false);

  if (loading) return <Skeleton variant="rounded" height={"400px"} />;

  return (
    <>
      {!isFromRequest && (
        <Button
          size={"medium"}
          variant={data.length ? "secondary" : "primary"}
          onClick={() => {
            track({
              name: "performance_myfeedbacks_sendfeedback_addvalues_clicked",
            });

            setAddModalOpen(true);
          }}
          style={{ width: "100%", marginBottom: "24px" }}
        >
          Adicionar Valores <StyledIcon name="IconPlus" fill="none" />
        </Button>
      )}

      {data.map((item, index) => {
        const isLastItem = index === data.length - 1;

        return (
          <CriterialsContainer key={item._id}>
            <CriterialContainer>
              <CriterialTextsContainer>
                <StyledTitle variant="headline8" setColor="neutral30">
                  {index + 1}.
                </StyledTitle>

                <CriterialTitleContainer>
                  <StyledTitle variant="headline8" setColor="neutral30">
                    {item.name}
                  </StyledTitle>

                  <StyledText variant="body3" setColor="neutral30">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.description || "",
                      }}
                      style={{
                        lineHeight: "normal",
                        width: "100%",
                        listStylePosition: "inside",
                      }}
                    />
                  </StyledText>
                </CriterialTitleContainer>

                {!isFromRequest && (
                  <StyledIcon
                    name="IconTrash"
                    setColor="neutral60"
                    onClick={() => {
                      const selectedValues = cloneDeep(data);

                      const filteredValues = selectedValues.filter(
                        (value) => value._id !== item._id
                      );

                      onValuesChanged(filteredValues);
                    }}
                  />
                )}
              </CriterialTextsContainer>
              <CommentaryContainer>
                {leaderName && (
                  <AvatarContainer children={avatarMaker(leaderName) || "-"} />
                )}

                <div>
                  <TextEditor
                    id={`commentary-${item._id}`}
                    styles={{
                      container: { width: "100%" },
                      editor: { height: "120px" },
                    }}
                    value={item.commentary}
                    maxLength={3000}
                    placeholder="Comentário ou feedback (Opcional)"
                    onBlur={(_, changedValue) => {
                      const cleanValue =
                        changedValue === "<p><br></p>" ? "" : changedValue;

                      const clonedCompanyValues = cloneDeep(
                        formik.values.companyValues || []
                      );

                      clonedCompanyValues[index] = {
                        ...clonedCompanyValues[index],
                        commentary: cleanValue,
                      };

                      formik.setFieldValue(
                        "companyValues",
                        clonedCompanyValues
                      );
                    }}
                  />
                </div>
              </CommentaryContainer>
            </CriterialContainer>

            {!isLastItem && (
              <div style={{ marginBottom: 16 }}>
                <Divider orientation="horizontal" />
              </div>
            )}
          </CriterialsContainer>
        );
      })}

      <AddCompanyValuesModal
        open={addModalOpen}
        selectedValues={data}
        onValuesChanged={onValuesChanged}
        type={"send"}
        onClose={() => setAddModalOpen(false)}
      />
    </>
  );
};
