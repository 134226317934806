import styled from "styled-components";
import { StyledText } from "@utils";

export const Container = styled.div`
  display: grid;
  gap: 16px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 12px;
  padding: 24px 40px;
`;

export const TipText = styled(StyledText)`
  margin: 2px 16px 16px;
  font-weight: 600;
`;
