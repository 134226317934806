import { useMemo } from "react";

import { Checkbox, Tag, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";

import { compact, flatMap, uniqBy } from "lodash";

import { Container, ItemContainer } from "./styled";

import { useTable } from "../../index";

import { useTransferList } from "../../../Root";

export const BulkActions = () => {
  const { table, data, isExpandable } = useTable();

  const { selected, addSelected, removeSelected } = useTransferList();

  const totalSelected = selected.length;

  const tableRows = table.rows || [];

  const tableData = tableRows.map(({ original }) => original);

  const tableSubRows = compact(
    flatMap(tableRows, (item) =>
      item?.subRows?.map((subRow) => subRow.original)
    )
  );

  const everyPageRowIsSelected = useMemo(() => {
    if (isExpandable) {
      return (
        !!tableSubRows.length &&
        tableSubRows.every(({ _id }) =>
          selected.find((item) => item._id === _id)
        )
      );
    }

    return (
      !!tableData.length &&
      tableData.every(({ _id }) => selected.find((item) => item._id === _id))
    );
  }, [
    isExpandable,
    JSON.stringify(tableData),
    JSON.stringify(tableSubRows),
    JSON.stringify(selected),
  ]);

  const anyPageRowIsSelected = useMemo(() => {
    if (isExpandable) {
      return tableSubRows.some(({ _id }) =>
        selected.find((item) => item._id === _id)
      );
    }

    return tableData.some(({ _id }) =>
      selected.find((item) => item._id === _id)
    );
  }, [
    isExpandable,
    JSON.stringify(tableData),
    JSON.stringify(tableSubRows),
    JSON.stringify(selected),
  ]);

  const totalData = useMemo(() => {
    if (isExpandable)
      return uniqBy(compact(flatMap(data, (item) => item?.subRows)), "_id")
        .length;

    return data.length;
  }, [isExpandable, data.length]);

  return (
    <Container role="row">
      <ItemContainer>
        <Checkbox
          onChange={(event) => {
            if (event.target.checked) {
              return addSelected(isExpandable ? tableSubRows : tableData);
            }

            return removeSelected(isExpandable ? tableSubRows : tableData);
          }}
          checked={everyPageRowIsSelected}
          indeterminate={anyPageRowIsSelected && !everyPageRowIsSelected}
          aria-label="column row checkbox"
        />
      </ItemContainer>
      <ItemContainer>
        <Tag variant={"primary"} as={"div"}>
          {totalSelected} pessoa{totalSelected > 1 && "s"} selecionada
          {totalSelected > 1 && "s"}
        </Tag>
      </ItemContainer>
      {anyPageRowIsSelected && (
        <ItemContainer>
          <LinkButton
            variant="default"
            onClick={() => {
              if (selected?.length >= totalData) {
                removeSelected(
                  isExpandable
                    ? compact(flatMap(data, (item) => item?.subRows))
                    : data
                );

                return;
              }

              addSelected(
                isExpandable
                  ? compact(flatMap(data, (item) => item?.subRows))
                  : data
              );
            }}
          >
            {selected?.length >= totalData
              ? "Limpar seleção"
              : "Selecionar todas as páginas"}
          </LinkButton>
        </ItemContainer>
      )}
    </Container>
  );
};
