import styled from "styled-components";

export const CriterialsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

export const CriterialContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
`;

export const CriterialTextsContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
`;

export const CriterialTitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CommentaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
