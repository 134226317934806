import { ReactNode } from "react";

import {
  IconTypes,
  ShapeIcon,
  LinkButton,
  LinkButtonProps,
} from "@flash-tecnologia/hros-web-ui-v2";

import { StyledTitle, StyledText } from "@utils";

import {
  CardContainer,
  ContentContainer,
  TextContainer,
  IconContainer,
} from "./styled";

type TipCardProps = {
  title?: string;
  subTitle?: ReactNode | string;
  children?: ReactNode;
  icon?: IconTypes;
  iconColor?: string;
  button?: {
    title: React.ReactNode | string;
    onClick?: LinkButtonProps["onClick"];
  };
  style?: React.CSSProperties | undefined;
};

export const TipCard = ({
  title,
  subTitle,
  children,
  icon,
  iconColor,
  button,
  style,
}: TipCardProps) => (
  <CardContainer style={style}>
    {icon && (
      <IconContainer>
        <ShapeIcon
          variant={"default"}
          name={icon}
          size={48}
          stroke={"default"}
          color={iconColor}
        />
      </IconContainer>
    )}

    <ContentContainer>
      <TextContainer>
        {title && (
          <StyledTitle
            variant="body3"
            setColor="secondary40"
            style={{ fontWeight: 600 }}
            children={title}
          />
        )}

        {subTitle && (
          <StyledText
            variant="body4"
            style={{ userSelect: "none" }}
            setColor="neutral30"
            children={subTitle}
          />
        )}
      </TextContainer>

      {!!button?.title && (
        <LinkButton
          variant="default"
          style={{ alignSelf: "flex-start" }}
          children={button.title}
          onClick={button.onClick}
        />
      )}

      {children}
    </ContentContainer>
  </CardContainer>
);
