import { useEffect, useMemo } from "react";

import { useFormik } from "formik";

import { StyledText, dispatchToast } from "@utils";

import { Footer, Header, Tabs } from "./components";

import { validationSchema, compareSections } from "./utils";

import { CalibrationFormType, OnSubmitCalibrationType } from "./types";

import { useCalibration } from "./hooks";

import {
  Body,
  EmptyStateContainer,
  EmptyStateImg,
  MainContainer,
  StyledDrawer,
} from "./styled";

import type { EvaluationStatus } from "server/src/services/evaluation/types";

type CalibrationDrawerProps = {
  isOpen: boolean;
  evaluationStatus?: EvaluationStatus;
  employeeId?: string;
  evaluationId?: string;
  onClose: () => void;
  onSubmit?: OnSubmitCalibrationType;
};

export const CalibrationDrawerV2 = ({
  isOpen,
  evaluationStatus,
  employeeId = "",
  evaluationId = "",
  onClose,
  onSubmit,
}: CalibrationDrawerProps) => {
  const {
    calibrationSections,
    employeeData,
    loadingSections,
    loadingEmployeeData,
    isCalibrating,
    calibrateEvaluation,
  } = useCalibration({
    isOpen,
    employeeId,
    evaluationId,
    onClose,
    onSubmit,
  });

  const formik = useFormik<CalibrationFormType>({
    initialValues: {
      average: undefined,
      calibrationAverage: undefined,
      sections: [],
    },
    validationSchema,
    onSubmit: async (values) => {
      const hasChanges = compareSections(
        calibrationSections?.sections || [],
        values.sections
      );

      if (!hasChanges && values.calibrationJustification) {
        dispatchToast({
          content:
            "Não é possível adicionar uma justificativa sem alguma alteração na calibração.",
          type: "error",
        });

        return;
      }

      if (!hasChanges) {
        dispatchToast({
          content: "Não foi possível calibrar, não há nenhuma mudança de nota.",
          type: "error",
        });

        return;
      }

      const parsedSections = values.sections.map((section) => ({
        _id: section._id,
        criterials: section.criterials.map((criterial) => ({
          _id: criterial._id || "",
          calibrationValue: criterial.calibrationValue,
        })),
      }));

      calibrateEvaluation({
        evaluatedId: employeeId,
        evaluationId: evaluationId,
        params: {
          calibrationJustification: values.calibrationJustification,
          calibrationSections: parsedSections,
        },
      });
    },
  });

  const hasChanges = useMemo(
    () =>
      compareSections(
        formik.values.sections || [],
        calibrationSections?.sections || []
      ),
    [formik.values.sections, calibrationSections]
  );

  useEffect(() => {
    formik.setValues({
      average: calibrationSections?.average,
      calibrationAverage: calibrationSections?.calibrationAverage,
      sections: calibrationSections?.sections || [],
      scaleSize: calibrationSections?.scaleSize,
    });
  }, [calibrationSections]);

  const onCalibration = evaluationStatus === "in_calibration";
  return (
    <StyledDrawer
      id={employeeId}
      anchor="right"
      open={isOpen}
      onClose={onClose}
    >
      <MainContainer>
        <Header
          employeeData={employeeData}
          loading={loadingEmployeeData}
          onClose={onClose}
        />

        <Body>
          {onCalibration ? (
            <Tabs
              formik={formik}
              loading={loadingSections}
              employeeId={employeeId}
              evaluationId={evaluationId}
            />
          ) : (
            <EmptyStateContainer>
              <EmptyStateImg />

              <StyledText
                variant="body3"
                style={{ textAlign: "center", maxWidth: 600 }}
                children={"Etapa de calibração não iniciada"}
              />
            </EmptyStateContainer>
          )}
        </Body>
      </MainContainer>

      <Footer
        isLoading={loadingSections}
        isMutating={isCalibrating}
        hasChanges={hasChanges}
        onSubmit={() => formik.handleSubmit()}
        onCancel={onClose}
      />
    </StyledDrawer>
  );
};
