import { useMemo, useState, useEffect } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  Navigate,
} from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

import { Icons } from "@flash-tecnologia/hros-web-ui-v2";

import { trpc } from "@api/client";
import { routes } from "@routes";

import { ConfirmationModal } from "@components/Modals";
import { PageTemplate } from "@components/PageTemplate";

import { SecondStep } from "./Steps/SecondStep";
import { FirstStep } from "./Steps/FirstStep";

import {
  StyledIcon,
  StyledTitle,
  dispatchToast,
  track,
  removeHtmlTags,
} from "@utils";

import { Header } from "./styled";

export const PageCreateIDP = () => {
  const utils = trpc.useContext();

  const { origin = "", step = "", employeeId = "", idpId = "" } = useParams();

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  const isCreating = useMemo(() => !!pathname.match("/create-idp"), [pathname]);

  const availableOrigins = {
    "my-idps": {
      route: routes.PageMyIdps,
      label: "Meu PDI",
    },
    "manage-idps": {
      route: routes.PageManageIDP,
      label: "Gerenciar PDIs",
    },
  };

  const availableOrigin = useMemo<
    { route: string; label: string } | undefined
  >(() => {
    return availableOrigins[origin] || undefined;
  }, [origin]);

  const validationSchema = yup.object({
    basicInfo: yup.object({
      name: yup.string().required("Este campo deve ser preenchido"),
      goal: yup.string().required("Este campo deve ser preenchido"),
      employeeId: yup.string().required("Este campo deve ser preenchido"),
      type: yup
        .string()
        .oneOf(["evaluation", "independent"])
        .required("Este campo deve ser preenchido"),
      evaluationId: yup.string().when("type", {
        is: (type) => type === "evaluation",
        then: (schema) => schema.required("Este campo deve ser preenchido"),
        otherwise: (schema) => schema.notRequired(),
      }),
    }),

    actions: yup.array(
      yup.object({
        topic:
          step === "2"
            ? yup.string().required("Este campo deve ser preenchido")
            : yup.string(),
        type:
          step === "2"
            ? yup.string().required("Este campo deve ser preenchido")
            : yup.string(),
        developmentAction:
          step === "2"
            ? yup
                .string()
                .test(
                  "empty text editor",
                  "Este campo deve ser preenchido",
                  (val) => Boolean(removeHtmlTags(val).trim().length)
                )
                .required("Este campo deve ser preenchido")
            : yup.string(),
        initialDate:
          step === "2"
            ? yup.date().required("Este campo deve ser preenchido")
            : yup.date(),
        finalDate:
          step === "2"
            ? yup.date().required("Este campo deve ser preenchido")
            : yup.date(),
        successIndicators: yup.string(),
        itemsToDevelop: yup.string(),
      })
    ),
  });

  const formik = useFormik<any>({
    initialValues: {
      basicInfo: {
        name: "",
        goal: "",
        employeeId: employeeId || "",
        type: "",
        evaluationId: "",
      },
      actions: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const ownerUrl = pathname.match("/to/") ? `/to/${employeeId}` : "";

      if (step === "1") {
        isCreating
          ? navigate(
              routes.PageCreateIDP.replace(":origin", origin).replace(
                ":step",
                "2"
              ) + ownerUrl
            )
          : navigate(
              routes.PageEditIDP.replace(":origin", origin)
                .replace(":step", "2")
                .replace(":idpId", idpId)
            );
      }

      if (step === "2") {
        const basicInfo = values.basicInfo;

        const actions =
          values?.actions?.map((action) => {
            delete action.index;

            return {
              ...action,
              ...(basicInfo?.type === "evaluation"
                ? { criterialId: action?.topic, topic: undefined }
                : { criterialId: undefined, topic: action?.topic }),
              status:
                isCreating || !action?.status ? "created" : action?.status,
            };
          }) || [];

        if (!actions.length) {
          dispatchToast({
            type: "error",
            content: "Inclua ao menos uma ação para prosseguir!",
          });

          return;
        }

        const submittedValue = {
          ...basicInfo,
          evaluationId: basicInfo?.evaluationId
            ? basicInfo?.evaluationId
            : undefined,
          actions,
        };

        isCreating
          ? createIdp({
              params: submittedValue,
            })
          : editIDP({ params: submittedValue, _id: idpId });
      }
    },
  });

  const {
    data: idp,
    isFetching: isLoadingIdp,
    error,
  } = trpc.performance.idp.getIdpById.useQuery(
    { _id: idpId },
    {
      retry: false,
      retryDelay: 3000,
      refetchOnWindowFocus: false,
      enabled: !!idpId,
      onError: (e: any) => {
        let message = "Erro ao buscar PDI, favor tentar novamente mais tarde!";

        const notExistsError = e?.data?.error === "IDP_NOT_EXISTS_ERROR";

        if (notExistsError) message = "PDI não encontrado!";

        dispatchToast({
          type: "error",
          content: message,
        });
      },
    }
  );

  const { mutate: createIdp, isLoading: isCreateLoading } =
    trpc.performance.idp.createIdp.useMutation({
      onSuccess: () => {
        dispatchToast({
          type: "success",
          content: "Plano criado com sucesso.",
        });

        utils.performance.idp.getEmployeeIdps.invalidate();
        navigate(availableOrigin?.route || routes.PageMyIdps);
      },
      onError: (e: any) => {
        let message = "Erro ao criar o plano, tente novamente em breve.";

        const alreadyExistsError = e?.data?.error === "IDP_EXISTS_ERROR";

        if (alreadyExistsError)
          message = "Erro ao criar plano, nome já existe!";

        dispatchToast({
          type: "error",
          content: message,
        });
      },
    });

  const { mutate: editIDP, isLoading: isEditLoading } =
    trpc.performance.idp.updateIdp.useMutation({
      onSuccess: () => {
        dispatchToast({
          type: "success",
          content: "Plano editado com sucesso.",
        });

        utils.performance.idp.getEmployeeIdps.invalidate();
        navigate(availableOrigin?.route || routes.PageMyIdps);
      },
      onError: (e: any) => {
        let message = "Erro ao editar o plano, tente novamente em breve.";

        const alreadyExistsError = e?.data?.error === "IDP_EXISTS_ERROR";
        const notExistsError = e?.data?.error === "IDP_NOT_EXISTS_ERROR";

        if (notExistsError)
          message = "Erro ao editar o plano, PDI não encontrado!";

        if (alreadyExistsError)
          message = "Erro ao editar plano, nome já existe!";

        dispatchToast({
          type: "error",
          content: message,
        });
      },
    });

  const footer = {
    cancelProps: {
      disabled: false,
      title: "Sair",
      callback: () => {
        if (step === "1") {
          track({
            name: employeeId
              ? "performance_myidp_createnewplan_basicinformation_exit_clicked"
              : "performance_idp_createactionplan_basicinformations_exit_clicked",
          });
        } else {
          track({
            name: employeeId
              ? "performance_myidp_createnewplan_configurations_exit_clicked"
              : "performance_idp_createactionplan_configurations_exit_clicked",
          });
        }
        setIsOpen(true);
      },
    },
    confirmProps: {
      title: (
        <>
          {step === "1"
            ? "Continuar"
            : `${isCreating ? "Criar" : "Editar"} Plano`}
          <StyledIcon name="IconArrowRight" fill="transparent" />
        </>
      ),
      callback: () => {
        if (step === "1") {
          track({
            name: employeeId
              ? "performance_myidp_createnewplan_basicinformation_continue_clicked"
              : "performance_idp_createactionplan_basicinformations_continue_clicked",
          });
        } else {
          track({
            name: employeeId
              ? "performance_myidp_createnewplan_configurations_createplan_clicked"
              : "performance_idp_createactionplan_configurations_createplan_clicked",
          });
        }
        formik.handleSubmit();
      },
      width: "250px",
      disabled:
        Boolean(error) || isLoadingIdp || isEditLoading || isCreateLoading,
      loading: isEditLoading || isCreateLoading,
    },
    ...(step === "2" && {
      goBackProps: {
        title: (
          <>
            <Icons name="IconArrowLeft" fill="transparent" />
            Voltar
          </>
        ),
        callback: () => {
          const ownerUrl = pathname.match("/to/") ? `/to/${employeeId}` : "";

          isCreating
            ? navigate(
                routes.PageCreateIDP.replace(":origin", origin).replace(
                  ":step",
                  "1"
                ) + ownerUrl
              )
            : navigate(
                routes.PageEditIDP.replace(":origin", origin)
                  .replace(":step", "1")
                  .replace(":idpId", idpId)
              );
        },
      },
    }),
  };

  const steps = useMemo(() => {
    switch (step) {
      case "1":
        return (
          <FirstStep
            formik={formik}
            fetching={isLoadingIdp}
            disabled={Boolean(error)}
          />
        );
      case "2":
        return (
          <SecondStep
            formik={formik}
            fetching={isLoadingIdp}
            disabled={Boolean(error)}
          />
        );
      default:
        return <Navigate to="/home" />;
    }
  }, [step, formik, isLoadingIdp, error]);

  useEffect(() => {
    if (idp) {
      formik.setValues({
        basicInfo: {
          name: idp?.name,
          goal: idp?.goal,
          employeeId: idp?.employeeId,
          type: idp?.type,
          evaluationId: idp?.evaluationId,
        },
        actions:
          idp?.actions?.map((action) => {
            return {
              ...action,
              topic: action?.criterialId || action?.topic,
            };
          }) || [],
      });
    }
  }, [idp]);

  if (!availableOrigin) return <Navigate to={`/home`} />;

  return (
    <PageTemplate
      routes={[
        {
          label: availableOrigin.label,
          route: availableOrigin.route,
        },
        {
          label: `${isCreating ? "Criar" : "Editar"} plano de ação`,
        },
      ]}
      stepper={{
        steps: ["Informações básicas", "Configurações"],
        activeStep: step === "1" ? 0 : 1,
      }}
      footer={footer}
    >
      <Header>
        <StyledTitle
          variant="headline6"
          children={`${isCreating ? "Criar" : "Editar"} plano de ação`}
        />
      </Header>
      <div style={{ paddingBottom: "40px" }} children={steps} />
      <ConfirmationModal
        open={isOpen}
        showWarning
        secondaryVariant="default"
        icon="IconAlertCircle"
        title="Deseja sair sem salvar o plano de ação?"
        subTitle="Você perderá todas as configurações do plano."
        confirm={{
          title: "Sair sem salvar",
          icon: "IconArrowRight",
          iconColor: "neutral100",
        }}
        onClose={() => setIsOpen(false)}
        onConfirm={() => navigate(availableOrigin.route)}
      />
    </PageTemplate>
  );
};
