import { FormikProps } from "formik";
import {
  Grades,
  Icons,
  LinkButton,
  TextField,
} from "@flash-tecnologia/hros-web-ui-v2";

import { concat } from "lodash-es";

import { Grid } from "@mui/material";

import { RequiredAsterisk, StyledTitle } from "@utils";

import type { ConfigurationsFormProps } from "../../../../types";

import {
  MainContainer,
  ScalesContainer,
  ScaleTrashIcon,
  StyledDescription,
  StyledSubtitleContainer,
} from "./styled";

interface EvaluationsScaleProps {
  formik: FormikProps<ConfigurationsFormProps>;
  disabledEdit: boolean;
}

export const EvaluationsScale = ({
  formik,
  disabledEdit,
}: EvaluationsScaleProps) => {
  const scale = formik.values.scale || [];

  return (
    <MainContainer>
      <ScalesContainer>
        {scale.map((s, index) => {
          return (
            <Grid key={s.order} container spacing={2} direction="row">
              <Grid item sm={12} md={3} lg={4} width={"100%"}>
                <TextField
                  label={
                    <>
                      Nome do valor {s.order} <RequiredAsterisk />
                    </>
                  }
                  value={s.title}
                  inputProps={{ maxLength: 30 }}
                  onChange={(e) => {
                    const findScaleIndex = scale.findIndex(
                      (q) => q.order === s.order
                    );

                    const currentScale = {
                      ...s,
                      title: e.target.value,
                    };

                    const arrayScale = Array.from(scale);
                    arrayScale.splice(findScaleIndex, 1);
                    arrayScale.splice(findScaleIndex, 0, currentScale);

                    formik.handleChange({
                      target: {
                        id: "scale",
                        value: arrayScale,
                      },
                    });
                  }}
                  error={
                    formik.touched.scale?.[index] &&
                    Boolean(formik.errors.scale?.[index])
                  }
                  helperText={
                    (formik.touched.scale?.[index] &&
                      (formik.errors.scale?.[index] as any)?.title) ||
                    undefined
                  }
                  fullWidth
                  disabled={disabledEdit}
                />
                <StyledSubtitleContainer>
                  <StyledDescription variant="caption">
                    Máximo 30 caracteres
                  </StyledDescription>
                </StyledSubtitleContainer>
              </Grid>
              <Grid item sm={10} md={7} lg={7} width={"100%"}>
                <TextField
                  rows={3}
                  label={`Legenda do valor ${s.order} (Opcional)`}
                  value={s.subtitle}
                  inputProps={{ maxLength: 100 }}
                  onChange={(e) => {
                    const findScaleIndex = scale.findIndex(
                      (q) => q.order === s.order
                    );

                    const currentScale = {
                      ...s,
                      subtitle: e.target.value,
                    };

                    const arrayScale = Array.from(scale);
                    arrayScale.splice(findScaleIndex, 1);
                    arrayScale.splice(findScaleIndex, 0, currentScale);

                    formik.handleChange({
                      target: {
                        id: "scale",
                        value: arrayScale,
                      },
                    });
                  }}
                  disabled={disabledEdit}
                  multiline
                  fullWidth
                />
                <StyledSubtitleContainer>
                  <StyledDescription variant="caption">
                    Máximo 100 caracteres
                  </StyledDescription>
                  <StyledDescription variant="caption">
                    {s?.subtitle?.length}/100
                  </StyledDescription>
                </StyledSubtitleContainer>
              </Grid>
              <Grid item sm={2} md={2} lg={1} width={"100%"}>
                <ScaleTrashIcon
                  name="IconTrash"
                  fill="transparent"
                  style={{ marginTop: "18px" }}
                  disabled={scale.length === 3 || disabledEdit}
                  onClick={() => {
                    const findScaleIndex = scale.findIndex(
                      (q) => q.order === s.order
                    );

                    const arrayScale = Array.from(scale);
                    arrayScale.splice(findScaleIndex, 1);

                    const ordered = arrayScale.map((r: any, index: any) => {
                      return { ...r, order: index + 1 };
                    });

                    formik.handleChange({
                      target: {
                        id: "scale",
                        value: ordered,
                      },
                    });
                  }}
                />
              </Grid>
            </Grid>
          );
        })}

        {scale.length < 5 && (
          <LinkButton
            variant="default"
            style={{ alignSelf: "flex-start" }}
            onClick={() => {
              const concatedScales = concat(scale, {
                order: scale.length + 1,
                title: "",
                subtitle: "",
                value: scale.length + 1,
              });

              formik.handleChange({
                target: {
                  id: "scale",
                  value: concatedScales,
                },
              });
            }}
          >
            <Icons name={"IconPlus"} fill="none" />
            Adicionar nova opção
          </LinkButton>
        )}
      </ScalesContainer>

      <div>
        <StyledTitle variant="headline8" setColor={"neutral30"}>
          Pré-visualização
        </StyledTitle>

        <div style={{ marginTop: "16px" }}>
          <Grades
            showTooltip={true}
            variant="soft"
            contentArray={scale.map((s) => {
              return {
                label: s.order?.toString(),
                title: s.title,
                subtitle: s.subtitle,
                value: s.value?.toString(),
              };
            })}
          />
        </div>
      </div>
    </MainContainer>
  );
};
