import { trpc } from "@api/client";
import { Table } from "./Table";

import { PageContainer } from "./styled";
import { dispatchToast } from "@utils";

export const People = () => {
  const { data, isLoading } =
    trpc.performance.feedback.getEmployeePeopleInsight.useQuery(undefined, {
      onError: () => {
        dispatchToast({
          content:
            "Erro ao carregar a lista de pessoas. Tente novamente em breve.",
          type: "error",
        });
      },
    });

  return (
    <PageContainer>
      <Table
        loading={isLoading}
        data={data?.employeePeopleInsight || []}
        filters={data?.filter || []}
      />
    </PageContainer>
  );
};
