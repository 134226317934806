import { MenuItem, SelectProps } from "@mui/material";

import { IconChevronDown } from "@tabler/icons-react";

import { StyledSelect } from "./styled";

type SelectOption = {
  label: string;
  value: string | number;
};

export type IRoundedSelectProps = Omit<SelectProps, "renderValue"> & {
  options: SelectOption[];
  renderValue?: (value: unknown, options: SelectOption[]) => React.ReactNode;
  iconSize?: string | number | undefined;
};

export const RoundedSelect = (props: IRoundedSelectProps) => {
  const { options, renderValue, iconSize } = props;

  return (
    <StyledSelect
      {...props}
      IconComponent={(props) => (
        <IconChevronDown
          width={iconSize ?? "16px"}
          height={iconSize ?? "16px"}
          {...props}
        />
      )}
      renderValue={
        renderValue ? (value) => renderValue(value, options) : undefined
      }
    >
      {options?.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};
