import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { ConfirmationModalV2 } from "@components/Modals";

import { routes } from "@routes";

import { trpc } from "@api/client";

import { dispatchToast } from "@utils";

import { BasicInfoStep } from "./Steps/BasicInfoStep";
import { ConfigurationStep } from "./Steps/ConfigurationStep";

import { useBasicStepsInfo } from "./hooks";

import { Steps } from "./types";

type StepType = typeof BasicInfoStep | typeof ConfigurationStep;

export const PageSendFeedback = () => {
  const utils = trpc.useContext();

  const { requestId = "", _id = "", receiverId = "", type = "" } = useParams();

  const {
    isLeader,
    isManager,
    breadcrumbs,
    feedback,
    loading,
    error,
    formValues,
    loadingDraftSave,
    setFormValues,
    navigate,
    createDraft,
    updateFeedback,
  } = useBasicStepsInfo({
    requestId,
    feedbackId: _id,
  });

  const [step, setStep] = useState<Steps>("basic-info");

  const [withoutSaveModal, setWithoutSaveModal] = useState<boolean>(false);
  const [withSaveModal, setWithSaveModal] = useState<boolean>(false);

  const isCreate = !!requestId || !_id;
  const isEdit = !!_id;
  const isFromRequest = !!requestId || !!feedback?.request?._id;

  const Step: StepType = useMemo(() => {
    const steps = {
      "basic-info": BasicInfoStep,
      ["configuration"]: ConfigurationStep,
    };

    return steps[step];
  }, [step]);

  return (
    <>
      <Step
        breadcrumbs={breadcrumbs}
        formValues={formValues}
        isLoading={loading}
        error={error}
        isManager={isManager}
        isLeader={isLeader}
        isCreate={isCreate}
        isFromRequest={isFromRequest}
        isEdit={isEdit}
        params={{ requestId, _id, receiverId, type: type as any }}
        onNavigateByStep={(step) => setStep(step)}
        onStepSubmit={(forms) => setFormValues({ ...formValues, ...forms })}
        setWithoutSaveModalOpen={() => setWithoutSaveModal(true)}
        setWithSaveModalOpen={() => setWithSaveModal(true)}
      />

      <ConfirmationModalV2
        open={withoutSaveModal}
        closeButton
        variantType="error"
        icon="IconAlertCircle"
        title="Deseja sair sem salvar o feedback?"
        subTitle="Você perderá todas as configurações do feedback."
        cancel={{ title: "Cancelar" }}
        confirm={{
          title: "Sair sem salvar",
        }}
        onClose={() => setWithoutSaveModal(false)}
        onConfirm={() =>
          navigate(
            isManager ? routes.PageManageFeedbacks : routes.PageMyFeedbacks
          )
        }
      />

      <ConfirmationModalV2
        open={withSaveModal}
        closeButton
        icon="IconInfoCircle"
        title="Deseja sair e salvar o progresso do feedback?"
        subTitle="O conteúdo será salvo como rascunho e você poderá editar mais tarde."
        cancel={{ title: "Cancelar" }}
        confirm={{
          title: "Sair e salvar rascunho",
          icon: "IconArrowRight",
          iconColor: "neutral100",
        }}
        isLoading={loadingDraftSave}
        onClose={() => setWithSaveModal(false)}
        onConfirm={() => {
          if (!formValues.basicInfoForm) return;

          const evaluationCriterials =
            formValues.configurationForm?.evaluationCriterials || [];

          const commentedCriterials = evaluationCriterials.filter(
            (item) => !!item.commentary
          );

          if (isCreate) {
            createDraft(
              {
                requestId: requestId || undefined,
                to: formValues.basicInfoForm.to,
                type: formValues.basicInfoForm.type,
                evaluationId:
                  formValues.basicInfoForm.evaluationId || undefined,
                message: formValues.configurationForm?.message || undefined,
                companyValues:
                  formValues.configurationForm?.companyValues || [],
                evaluationCriterials: commentedCriterials,
              },
              {
                onSuccess: () => {
                  dispatchToast({
                    type: "success",
                    content: "Rascunho criado com sucesso.",
                  });

                  utils.performance.feedback.getFeedbackFull.invalidate();

                  navigate(
                    isManager
                      ? routes.PageManageFeedbacks
                      : routes.PageMyFeedbacks
                  );
                },
              }
            );

            return;
          }

          if (isEdit) {
            updateFeedback(
              {
                feedbackId: _id,
                params: {
                  to: formValues.basicInfoForm.to,
                  type: formValues.basicInfoForm.type,
                  evaluationId:
                    formValues.basicInfoForm.evaluationId || undefined,
                  message: formValues.configurationForm?.message || undefined,
                  companyValues:
                    formValues.configurationForm?.companyValues || [],
                  evaluationCriterials: commentedCriterials,
                },
              },
              {
                onSuccess: () => {
                  dispatchToast({
                    type: "success",
                    content: "Rascunho editado com sucesso.",
                  });

                  utils.performance.feedback.getFeedbackFull.invalidate();
                  utils.performance.feedback.getEmployeeSentFeedbacks.invalidate();
                  utils.performance.feedback.getFeedbackCriterialsBySelection.invalidate();

                  navigate(
                    isManager
                      ? routes.PageManageFeedbacks
                      : routes.PageMyFeedbacks
                  );
                },
              }
            );

            return;
          }
        }}
      />
    </>
  );
};
