import { useTheme } from "styled-components";

import { Grades, Divider } from "@flash-tecnologia/hros-web-ui-v2";

import { FloatingCard, InfoCard } from "@components/Cards";
import { TextEditor } from "@components/TextEditor";

import { StyledTitle, StyledText } from "@utils";

import {
  TitleContainer,
  CriterialsContainer,
  CriterialContainer,
  CriterialTextsContainer,
  CriterialTitleContainer,
} from "./styled";

import type {
  EmployeeEvaluationStep,
  AnswerEvaluationType,
  EmployeeEvaluation,
  EvaluationTypes,
} from "../../types";

interface IEvaluationStepProps {
  scale?: EmployeeEvaluation["scale"];
  step: EmployeeEvaluationStep | undefined;
  errorCriterial?: { _id: string }[] | undefined;
  answerEvaluation: AnswerEvaluationType;
  evaluationType?: EvaluationTypes;
}

const Commentary = ({ step, answerEvaluation }: IEvaluationStepProps) => {
  const theme = useTheme();

  const { generalCommentary } = step || {};

  return (
    <FloatingCard gap={theme.spacings.xs2}>
      <TitleContainer>
        <StyledTitle variant="headline8" setColor="secondary50">
          Comentário (Opcional)
        </StyledTitle>

        <StyledText variant="body3" setColor="neutral30">
          Utilize o campo abaixo para observações caso deseje complementar algum
          assunto ou abordar algo que não foi contemplado durante a avaliação.
        </StyledText>
      </TitleContainer>

      <TextEditor
        id={`general-commentary`}
        styles={{
          container: { width: "100%" },
          editor: { height: "150px" },
        }}
        value={generalCommentary}
        placeholder="Comentário (opcional)"
        maxLength={3000}
        onBlur={(_, changedValue) => {
          if (!changedValue) return;

          answerEvaluation({
            generalCommentary: { value: changedValue },
          });
        }}
      />
    </FloatingCard>
  );
};

const Sections = ({
  scale,
  step,
  errorCriterial,
  answerEvaluation,
  evaluationType,
}: IEvaluationStepProps) => {
  const theme = useTheme();

  const {
    _id: sectionId,
    name,
    description,
    criterials = [],
  } = step?.section || {};

  return (
    <FloatingCard gap={theme.spacings.s}>
      <TitleContainer>
        <StyledTitle variant="headline8" setColor="secondary50">
          {name}
        </StyledTitle>
        <StyledText variant="body3" setColor="neutral30">
          {description}
        </StyledText>
      </TitleContainer>

      {(criterials || []).map((item, index) => {
        const isFirstItem = index === 0;
        const isLastItem = index === criterials.length - 1;

        const showInfoCard =
          evaluationType === "byLeader" &&
          item.commentaryEnabled &&
          isFirstItem;

        const value = item?.value?.toString() || "";

        return (
          <CriterialsContainer key={item._id}>
            <CriterialContainer>
              <CriterialTextsContainer>
                <StyledTitle variant="headline8" setColor="neutral30">
                  {index + 1}.
                </StyledTitle>

                <CriterialTitleContainer>
                  <StyledTitle variant="headline8" setColor="neutral30">
                    {item.name}
                  </StyledTitle>

                  <StyledText variant="body3" setColor="neutral30">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.description || "",
                      }}
                      style={{
                        lineHeight: "normal",
                        width: "100%",
                        listStylePosition: "inside",
                      }}
                    />
                  </StyledText>
                </CriterialTitleContainer>
              </CriterialTextsContainer>

              <Grades
                value={value}
                variant="soft"
                contentArray={(scale || []).map((scale) => ({
                  label: scale?.value?.toString() || "",
                  title: scale?.title || "",
                  subtitle: scale?.subtitle || "",
                  value: scale?.value?.toString() || "",
                  disabled: false,
                }))}
                showTooltip={true}
                error={!!errorCriterial?.find((e) => e._id === item._id)}
                onChange={({ value }) => {
                  if (!value || !sectionId) return;

                  const newValue = Number(value);

                  answerEvaluation({
                    question: {
                      sectionId,
                      criterialId: item._id,
                      value: newValue,
                    },
                  });
                }}
              />

              {showInfoCard && (
                <InfoCard
                  content={
                    <>
                      Use o campo para justificar sua nota e{" "}
                      <b>incluir um feedback</b>. Esse conteúdo poderá ser
                      editado e <b>compartilhado com o liderado</b> na etapa de
                      feedbacks futuramente!
                    </>
                  }
                  icon="IconAlertSquareRoundedFilled"
                  variant={"secondary"}
                />
              )}

              {item.commentaryEnabled && (
                <TextEditor
                  id={`commentary-${item._id}`}
                  styles={{
                    container: { width: "100%" },
                    editor: { height: "120px" },
                  }}
                  value={item.commentary}
                  maxLength={3000}
                  placeholder="Comentário (opcional)"
                  onBlur={(_, changedValue) => {
                    if (!changedValue || !sectionId) return;

                    answerEvaluation({
                      commentary: {
                        sectionId,
                        criterialId: item._id,
                        value: changedValue,
                      },
                    });
                  }}
                />
              )}
            </CriterialContainer>

            {!isLastItem && <Divider orientation="horizontal" />}
          </CriterialsContainer>
        );
      })}
    </FloatingCard>
  );
};

export const EvaluationStep = ({
  scale,
  step,
  errorCriterial,
  answerEvaluation,
}: IEvaluationStepProps) => {
  if (!step) return <></>;

  const { type } = step;

  const stepByType = {
    section: (
      <Sections
        scale={scale}
        step={step}
        errorCriterial={errorCriterial}
        answerEvaluation={answerEvaluation}
      />
    ),
    generalCommentary: (
      <Commentary step={step} answerEvaluation={answerEvaluation} />
    ),
  };

  return stepByType[type] || <></>;
};
