import styled from "styled-components";

import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

const CellArea = styled.div`
  width: 100%;
  max-width: 200px;
  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    max-width: 200px;
  }
`;

const TooltipText = styled(Typography)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral[40]};
  display: flex;
  align-items: center;
`;

const DataGridContainer = styled.div`
  .data-grid-pagination-container {
    margin: 16px 0px 0px 0px !important;
  }

  .data-grid-custom-select div.MuiSelect-select {
    padding-right: 36px !important;
  }

  .select-icon-custom svg {
    top: calc(50% - 0.7em);
    right: 5px;
  }
`;

const CellAreaAnswer = styled.div`
  width: 100%;
  max-width: 200px;

  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    max-width: 200px;
  }
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const TooltipContainer = styled.span`
  margin-left: 4px;
  cursor: pointer;
`;

export {
  CellArea,
  CellAreaAnswer,
  TooltipText,
  DataGridContainer,
  FlexRow,
  TooltipContainer,
};
