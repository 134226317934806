import { useState } from "react";
import { useTheme } from "styled-components";

import { FormikProps } from "formik";
import { cloneDeep, compact, flatMap } from "lodash-es";

import { Divider, Dot, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";

import { getFromLS } from "@flash-tecnologia/hros-web-utility";

import { InfoCard, TipCard, FormCard } from "@components/Cards";
import { EvaluationAnswersDrawer } from "@components/Drawers";
import { ConfirmationModalV2 } from "@components/Modals/common";
import { TextEditor } from "@components/TextEditor";

import { StyledText, StyledTitle, avatarMaker } from "@utils";

import {
  AvatarContainer,
  ButtomsRow,
  CommentaryContainer,
  CriterialContainer,
  CriterialTextsContainer,
  CriterialTitleContainer,
  CriterialsContainer,
  FlexRow,
  GradeContainer,
} from "./styled";

import type {
  ConfigurationFormProps,
  FeedbackCommentaries,
  FeedbackCriterialSections,
  BasicInfoFormProps,
} from "../../../types";

interface IEvaluationSectionsProps {
  formik: FormikProps<ConfigurationFormProps>;
  commentaries: FeedbackCommentaries;
  sections: FeedbackCriterialSections;
  basicInfoForm: BasicInfoFormProps;
}

export const EvaluationSections = ({
  formik,
  commentaries,
  sections,
  basicInfoForm,
}: IEvaluationSectionsProps) => {
  const theme = useTheme();

  const [answersDrawerOpen, setAnswersDrawerOpen] = useState<boolean>(false);
  const [addCommentsModal, setAddCommentsModal] = useState<boolean>(false);

  const userAuth = getFromLS("userAuth");

  const leaderName = userAuth?.attributes?.name || "";

  const hasSections = !!sections.length;

  const addCommentaries = () => {
    const clonedCommentaries = cloneDeep(commentaries || []);

    const generalCommentary = clonedCommentaries.find(
      (c) => c?.type === "general"
    );

    const clonedCriterials = cloneDeep(
      formik.values.evaluationCriterials || []
    );

    const sectionsCommentaries = compact(
      clonedCommentaries.filter((c) => !!c?.criterialId)
    );

    const sectionsCriterials = flatMap(
      sections,
      (item) => item.criterials || []
    );

    sectionsCriterials.forEach((criterial) => {
      const sectionCommentary = sectionsCommentaries.find(
        (s) => s.criterialId === criterial._id
      );

      if (!sectionCommentary) return;

      const criterialIndex = clonedCriterials.findIndex(
        (item) => item._id === criterial._id
      );

      if (criterialIndex !== -1) {
        clonedCriterials[criterialIndex] = {
          ...clonedCriterials[criterialIndex],
          commentary: sectionCommentary?.value || "",
        };
      } else {
        clonedCriterials.push({
          _id: criterial._id,
          commentary: sectionCommentary?.value || "",
        });
      }
    });

    formik.setFieldValue("evaluationCriterials", clonedCriterials);

    if (generalCommentary) {
      formik.setFieldValue("message", generalCommentary.value);
    }

    setAddCommentsModal(false);
  };

  return (
    <>
      {hasSections && (
        <TipCard
          icon="IconInfoSquareRoundedFilled"
          iconColor="secondary50"
          title="Dica da flash: Inclua comentários da avaliação no feedback"
          subTitle={
            <>
              Inclua e edite seus <b>comentários feitos na avaliação</b> deste
              liderado para adicionar contexto e <b>aprimorar o feedback</b>.
            </>
          }
          children={
            <ButtomsRow>
              <LinkButton
                variant="default"
                children="Ver respostas da avaliação"
                onClick={() => setAnswersDrawerOpen(true)}
              />

              <LinkButton
                variant="default"
                children="Incluir no feedback"
                disabled={!commentaries?.length}
                onClick={() => setAddCommentsModal(true)}
              />
            </ButtomsRow>
          }
        />
      )}

      <FormCard
        title="Feedback em critérios da avaliação"
        description="Realize o feedback utilizando os critérios da avaliação escolhida, avaliando e fornecendo contexto para cada item."
        error={
          !!formik.touched.evaluationCriterials &&
          Boolean(formik.errors.evaluationCriterials)
        }
      >
        <InfoCard
          icon="IconInfoCircle"
          variant="info"
          content="O liderado não terá acesso às notas da avaliação via plataforma, mas elas estão disponíveis aqui para te auxiliar na construção do feedback. Consulte o RH sobre a possibilidade de divulgá-las fora da ferramenta."
        />

        {sections.map((section, sectionIndex) => {
          const isLastItem = sectionIndex === sections.length - 1;

          return (
            <div key={section._id}>
              <StyledTitle
                children={section.name}
                variant="body2"
                setColor="secondary50"
                style={{ marginBottom: 16 }}
              />

              {(section.criterials || []).map((criterial, criterialIndex) => {
                const isLastCriterialItem =
                  criterialIndex === (section.criterials || []).length - 1;

                const average = criterial.calibrationAverage
                  ? criterial.calibrationAverage
                  : criterial.average;

                const commentary =
                  formik.values.evaluationCriterials?.find(
                    (c) => c._id === criterial._id
                  )?.commentary || "";

                return (
                  <CriterialsContainer key={`${section._id}-${criterial._id}`}>
                    <CriterialContainer>
                      <CriterialTextsContainer>
                        <StyledTitle variant="headline8" setColor="neutral30">
                          {criterialIndex + 1}.
                        </StyledTitle>

                        <CriterialTitleContainer>
                          <StyledTitle variant="headline8" setColor="neutral30">
                            {criterial.name}
                          </StyledTitle>

                          <StyledText variant="body3" setColor="neutral30">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: criterial.description || "",
                              }}
                              style={{
                                lineHeight: "normal",
                                width: "100%",
                                listStylePosition: "inside",
                              }}
                            />
                          </StyledText>
                        </CriterialTitleContainer>
                      </CriterialTextsContainer>
                      <CommentaryContainer>
                        <AvatarContainer
                          children={avatarMaker(leaderName) || "-"}
                        />

                        <div>
                          <GradeContainer>
                            <StyledText
                              variant="body3"
                              children="Nota e comentário da liderança: "
                              setColor="neutral50"
                              fontWeight={600}
                            />

                            <StyledText
                              variant="body3"
                              children={`${average} - ${criterial.scale}`}
                              setColor="neutral40"
                              fontWeight={700}
                            />

                            <StyledText
                              variant="body4"
                              children={
                                criterial.calibrationAverage ? (
                                  <FlexRow>
                                    <Dot
                                      variant="primary"
                                      style={{
                                        width: 12,
                                        height: 12,
                                        borderRadius: 12,
                                        backgroundColor:
                                          theme.colors.secondary[50],
                                      }}
                                    />
                                    (nota calibrada)
                                  </FlexRow>
                                ) : (
                                  "(nota original)"
                                )
                              }
                              setColor="neutral50"
                            />
                          </GradeContainer>

                          <TextEditor
                            id={`${sectionIndex}${criterialIndex}`}
                            styles={{
                              container: { width: "100%" },
                              editor: { height: "120px" },
                            }}
                            value={commentary}
                            maxLength={3000}
                            placeholder="Comentário ou feedback (Opcional)"
                            onBlur={(_, changedValue) => {
                              const cleanValue =
                                changedValue !== "<p><br></p>"
                                  ? changedValue
                                  : "";

                              const evaluationCriterials = cloneDeep(
                                formik.values.evaluationCriterials || []
                              );

                              const criterialIndex =
                                evaluationCriterials.findIndex(
                                  (item) => item._id === criterial._id
                                );

                              if (criterialIndex !== -1) {
                                evaluationCriterials[criterialIndex] = {
                                  ...evaluationCriterials[criterialIndex],
                                  commentary: cleanValue,
                                };
                              } else {
                                evaluationCriterials.push({
                                  _id: criterial._id,
                                  commentary: cleanValue,
                                });
                              }

                              formik.setFieldValue(
                                "evaluationCriterials",
                                evaluationCriterials
                              );
                            }}
                          />
                        </div>
                      </CommentaryContainer>
                    </CriterialContainer>

                    {!isLastCriterialItem && (
                      <div style={{ marginBottom: 16 }}>
                        <Divider orientation="horizontal" />
                      </div>
                    )}
                  </CriterialsContainer>
                );
              })}

              {!isLastItem && (
                <div style={{ marginBottom: 16, marginTop: 16 }}>
                  <Divider orientation="horizontal" />
                </div>
              )}
            </div>
          );
        })}
      </FormCard>

      <EvaluationAnswersDrawer
        isOpen={answersDrawerOpen}
        evaluatedId={basicInfoForm.to}
        evaluationId={basicInfoForm.evaluationId || ""}
        hasCommentaries={!!commentaries?.length}
        onClose={() => setAnswersDrawerOpen(false)}
        onSubmit={() => {
          setAnswersDrawerOpen(false);
          setAddCommentsModal(true);
        }}
      />

      <ConfirmationModalV2
        open={addCommentsModal}
        closeButton
        iconTitle="Atenção!"
        variantType="default"
        icon="IconAlertCircle"
        title="Deseja incluir os comentários da avaliação no feedback?"
        subTitle={
          <>
            Seus <b>comentários feitos na avaliação</b> deste participante serão
            replicados para adicionar contexto e aprimorar o feedback.
            <p>
              O <b>participante verá esses comentários</b> após o envio, por
              isso, você poderá editá-los e certificar-se de que sejam
              construtivos e ajudem no crescimento do colaborador.
            </p>
          </>
        }
        cancel={{ title: "Cancelar" }}
        confirm={{
          title: "Continuar",
        }}
        onClose={() => setAddCommentsModal(false)}
        onConfirm={() => addCommentaries()}
      />
    </>
  );
};
