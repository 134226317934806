import { InfoCard } from "@components/Cards";
import { ReactElement } from "react";

type ICommentaryFieldProps = {
  commentaryField: ReactElement;
  commentaryEnabled: boolean;
  commentaryExists: boolean;
  isGeneralCommentary: boolean;
};

export const CommentaryField = ({
  commentaryField,
  commentaryEnabled,
  commentaryExists,
  isGeneralCommentary,
}: ICommentaryFieldProps) => {
  const isDisabled = !commentaryEnabled || !commentaryExists;

  const getContent = () => {
    let content = "Você não preencheu este campo de comentários na avaliação.";

    if (!commentaryEnabled) {
      content = isGeneralCommentary
        ? "O RH não habilitou comentários gerais nesta avaliação."
        : "O RH não habilitou comentários em critérios nesta avaliação.";

      return content;
    }

    return content;
  };

  if (isDisabled) {
    const content = getContent();

    return (
      <InfoCard icon={"IconAlertCircle"} content={content} variant="info" />
    );
  }

  return commentaryField;
};
