import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 70%;
  width: 100%;
  padding: ${({ theme }) => theme.spacings.xs};
  gap: ${({ theme }) => theme.spacings.s};
  margin-bottom: ${({ theme }) => theme.spacings.m};
`;

export const SearchAndFilterContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const FilterPillButton = styled.div<{ hasFilters?: boolean }>`
  & button {
    background: ${({ theme, hasFilters = false }) =>
      hasFilters ? theme.colors.neutral[90] : "none"};
    height: 56px;
    width: 56px;
    svg {
      height: 24px !important;
      width: 24px !important;
    }
  }
`;
