import styled, { css } from "styled-components";
import { Avatar } from "@mui/material";

import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";

const TableDataArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
`;

const TableData = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0px;
  .MuiAvatar-root {
    margin-right: 12px;
    background: ${({ theme }) => theme.colors.primary};
  }
`;

const EmployeeName = styled(Typography)`
  text-transform: capitalize;
`;

const NoLeaderText = styled(Typography)`
  color: ${({ theme }) => theme.colors.feedback.error[40]};
  font-weight: 600 !important;
  width: max-content;
  :first-letter {
    text-transform: uppercase;
  }
`;

const AlertIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.feedback.error[40]};
  fill: transparent;
  flex-shrink: 0;

  margin-right: 4px;
`;

const StyledAvatar = styled(Avatar)`
  text-transform: uppercase;
`;

const TransferListArea = styled.div<{ disabled?: boolean }>`
  width: 100%;
  max-width: 100%;
  height: 1200px;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `};
`;

const CountArea = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export {
  TransferListArea,
  AlertIcon,
  CountArea,
  EmployeeName,
  NoLeaderText,
  StyledAvatar,
  TableDataArea,
  TableData,
};
