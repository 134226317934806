import { ReactNode } from "react";
import { IconTypes } from "@flash-tecnologia/hros-web-ui-v2";

import { StyledIcon, StyledText } from "@utils";

import { MainContainer } from "./styled";

interface IInfoCardProps {
  icon?: IconTypes;
  content: string | ReactNode;
  variant: "info" | "secondary";
}

export const InfoCard = ({ icon, content, variant }: IInfoCardProps) => {
  return (
    <MainContainer variant={variant}>
      {icon && (
        <div>
          <StyledIcon
            name={icon}
            setColor={variant === "info" ? `info40` : `secondary50`}
            size={22}
          />
        </div>
      )}
      <div>
        <StyledText
          children={content}
          variant="body4"
          setColor={variant === "info" ? `info10` : `neutral30`}
        />
      </div>
    </MainContainer>
  );
};
