import { useMemo, useState } from "react";

import { Button, EmptyState, dayjs } from "@flash-tecnologia/hros-web-ui-v2";

import { StyledIcon, dispatchToast, track } from "@utils";

import { trpc } from "@api/client";

import { FeedbackDrawer } from "@components/Drawers";
import { DataGrid } from "@components/DataGrid";
import { ProfileTable } from "@components/ProfileTable";
import { TableTag } from "@components/TableTag";
import { SendReminderModal } from "@components/Modals";

import { BulkActions } from "./BulkActions";

import type { TColumnProps } from "@components/DataGrid/types";
import type { TableGridCommonProps } from "@components/Table/types";

interface IBulkActionsGridProps extends TableGridCommonProps {
  cycleId: string;
}

export const BulkActionsGrid = ({
  data,
  rawData,
  cycleId,
  loading,
}: IBulkActionsGridProps) => {
  const [openDrawer, setOpenDrawer] = useState({
    isOpen: false,
    feedbackData: "",
  });

  const [sendReminderModal, setSendReminderModal] = useState<{
    isOpen: boolean;
    peopleToSend: string[];
  }>({
    isOpen: false,
    peopleToSend: [],
  });

  const columns = useMemo<TColumnProps<any>>(
    () => [
      {
        header: "Líder",
        accessorKey: "from",
        cell: ({ row: { original } }) => (
          <ProfileTable
            name={original?.from?.name || "-"}
            email={original?.from?.email}
          />
        ),
      },
      {
        header: "Liderado",
        accessorKey: "to",
        cell: ({ row: { original } }) => (
          <ProfileTable
            name={original?.to?.name || "-"}
            email={original?.to?.email}
          />
        ),
      },
      {
        header: "Status",
        accessorKey: "status",
        minSize: 250,
        cell: (props) => {
          const value = props?.getValue();

          const info = {
            sent: { label: "Feedback enviado", variant: "success" },
            unsent: { label: "Feedback não enviado", variant: "error" },
          };

          return (
            <TableTag
              label={info[value]?.label}
              variant={info[value]?.variant}
              hasDot={true}
              style={{ flexShrink: 0, alignSelf: "center" }}
            />
          );
        },
      },
      {
        header: "Avaliação",
        accessorKey: "evaluation",
        minSize: 200,
        cell: ({ row: { original } }) => {
          const value = original?.evaluation?.name;
          return value || <></>;
        },
      },
      {
        header: "Criado em",
        accessorKey: "createdAt",
        cell: (props) => {
          const value = props?.getValue();
          const isValid = !!value && dayjs(value).isValid();

          return isValid ? dayjs(value).format("DD/MM/YYYY") : "-";
        },
      },
      {
        header: "Ações",
        accessorKey: "action",
        sticky: "right",
        disableSortBy: true,
        cell: ({ row: { original } }) => {
          const feedbackSent = original.status === "sent";

          if (feedbackSent) {
            return (
              <Button
                variant="secondary"
                size="medium"
                style={{ width: "222px" }}
                onClick={() => {
                  setOpenDrawer({
                    isOpen: true,
                    feedbackData: original.feedback,
                  });
                }}
              >
                Ver feedback{" "}
                <StyledIcon
                  name="IconArrowRight"
                  setColor="secondary50"
                  size={12}
                />
              </Button>
            );
          }

          return (
            <Button
              loading={isMutating}
              variant="primary"
              size="medium"
              style={{ width: "222px" }}
              onClick={() => {
                track({
                  name: "people_strategic_hr_performance_company_cycles_cycledetails_feedbacks_sendreminder_clicked",
                });

                setSendReminderModal({
                  isOpen: true,
                  peopleToSend: [original.from._id],
                });
              }}
            >
              Enviar lembrete <StyledIcon name="IconMail" size={12} />
            </Button>
          );
        },
      },
    ],
    [data, cycleId]
  );

  const { mutate, isLoading: isMutating } =
    trpc.performance.communication.sendReminder.useMutation({
      onSuccess: () => {
        dispatchToast({
          type: "success",
          content: "Lembrete enviado com sucesso!",
        });

        setSendReminderModal({ isOpen: false, peopleToSend: [] });
      },
      onError: (e: any) => {
        const companyNotExists = e?.data?.error === "COMPANY_NOT_EXISTS_ERROR";

        const cycleNotExists = e?.data?.error === "CYCLE_NOT_EXISTS_ERROR";

        const employeeNotExists =
          e?.data?.error === "EMPLOYEE_NOT_EXISTS_ERROR";

        let message = "Erro ao enviar lembrete, tente novamente em breve.";

        if (companyNotExists)
          message = "Erro ao enviar lembrete, empresa não encontrada!";

        if (cycleNotExists)
          message = "Erro ao enviar lembrete, ciclo não encontrado!";

        if (employeeNotExists)
          message = "Erro ao enviar lembrete, colaborador não encontrado!";

        dispatchToast({
          type: "error",
          content: message,
        });
      },
    });

  if (!loading && !rawData.length)
    return (
      <EmptyState
        buttonText={""}
        description={"Nenhuma pessoa foi encontrada!"}
        buttonProps={{ size: "small", hidden: true }}
      />
    );

  return (
    <>
      <DataGrid
        data={data}
        columns={columns}
        loading={loading}
        options={{
          selectable: (row) => row._id,
        }}
        empty={{
          isFiltered: true,
          message: "Nenhuma pessoa foi encontrada!",
        }}
        bulkActionsRender={(props) => (
          <BulkActions
            {...props}
            data={data}
            setBulkSelection={setSendReminderModal}
          />
        )}
      />

      <FeedbackDrawer
        feedbackData={openDrawer.feedbackData}
        isOpen={openDrawer.isOpen}
        onClose={() =>
          setOpenDrawer({
            isOpen: false,
            feedbackData: "",
          })
        }
        permission=""
      />

      <SendReminderModal
        origin={"feedback"}
        isLoading={isMutating}
        open={sendReminderModal.isOpen}
        onConfirm={() => {
          track({
            name: "people_strategic_hr_performance_company_cycles_cycledetails_feedbacks_confirmationmodal_sendreminder_clicked",
          });
          mutate({
            cycleId,
            employeeIds: sendReminderModal.peopleToSend,
            type: "feedback",
          });
        }}
        onClose={() =>
          setSendReminderModal({
            isOpen: false,
            peopleToSend: [],
          })
        }
        peopleToSend={sendReminderModal.peopleToSend.length}
      />
    </>
  );
};
