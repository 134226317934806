import { ReactNode, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  PillButton,
  Icons,
  Menu,
  Tag,
  IconTypes,
  MenuOptionType,
} from "@flash-tecnologia/hros-web-ui-v2";

import { routes } from "@routes";

import { trpc } from "@api/client";

import { Timeline, TimelineProps } from "@components/Timeline";
import { TableTag } from "@components/TableTag";

import { WordsConfirmationModal } from "@components/Modals";

import {
  StyledTitle,
  StyledText,
  track,
  dispatchToast,
  StyledIcon,
} from "@utils";

import {
  ActionButton,
  ButtonArea,
  DeleteOption,
  DeleteWarning,
  Option,
  TimelineArea,
  TimelineInfoContainer,
  TimelineTitleArea,
  TimelineTitleWrapper,
} from "./styled";

import type { Cycle } from "server/src/services/cycle/types";

interface ILastCycleProps {
  cycle: Cycle;
}

interface TOption extends MenuOptionType {
  onClick: any;
  children: ReactNode;
}

export const LastCycle = ({ cycle }: ILastCycleProps) => {
  const {
    _id,
    steps = [],
    name,
    cycleStatus,
    evaluationCount,
    description,
  } = cycle || {};

  const navigate = useNavigate();
  const utils = trpc.useContext();

  const [open, setOpen] = useState(false);

  const refetch = () => {
    utils.performance.cycle.getAllCycles.invalidate();
    utils.performance.cycle.getLastCycle.refetch();
  };

  const { mutate: deleteCycle, isLoading: deleteLoading } =
    trpc.performance.cycle.deleteCycle.useMutation({
      onSuccess: () => {
        dispatchToast({
          type: "success",
          content: "Ciclo excluído com sucesso.",
        });

        refetch();
        setOpen(false);
      },
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Erro ao deletar o ciclo, tente novamente mais tarde.",
        });
      },
    });

  const labels = {
    closed: { label: "Ciclo encerrado", variant: "gray" as const },
    scheduled: { label: "Ciclo agendado", variant: "pink" as const },
    available: { label: "Ciclo ativo", variant: "green" as const },
  };

  const timelineItems = useMemo(() => {
    if (!steps?.length) return [];

    const label = {
      evaluation: { label: "Avaliações", icon: "IconFileDescription" },
      calibration: { label: "Calibração", icon: "IconBrandSpeedtest" },
      feedback: { label: "Feedback", icon: "IconMail" },
      idp: { label: "PDI", icon: "IconPlaneTilt" },
    } as { [key: string]: { label: string; icon: IconTypes } };

    const mappedData: TimelineProps["items"] = steps.map((step) => ({
      label: label[step?.type]?.label,
      icon: label[step?.type]?.icon,
      start: step?.startDate,
      end: step?.endDate,
    }));

    return mappedData;
  }, [cycle]);

  const renderOptions = useMemo(() => {
    const itens = [] as TOption[];

    if (cycleStatus != "closed") {
      itens.push({
        onClick: () => {
          navigate(routes.PageCreateCycle(_id));
        },
        children: (
          <Option>
            <Icons name="IconPencil" fill="transparent" />
            <StyledText variant="body3" style={{ fontWeight: 600 }}>
              Editar ciclo
            </StyledText>
          </Option>
        ),
      });
    }

    itens.push({
      onClick: () => {
        setOpen(true);
      },
      children: (
        <DeleteOption>
          <StyledIcon name="IconTrash" fill="transparent" setColor="error50" />
          <StyledText
            variant="body3"
            style={{ fontWeight: 600 }}
            setColor="error50"
          >
            Excluir ciclo
          </StyledText>
        </DeleteOption>
      ),
    });

    return itens;
  }, [cycle]);

  return (
    <TimelineArea>
      <TimelineInfoContainer>
        <TimelineTitleWrapper>
          <TimelineTitleArea>
            <StyledTitle variant="headline7" weight={700} setColor="neutral30">
              {name}
            </StyledTitle>

            {cycleStatus && (
              <TableTag
                label={labels[cycle?.cycleStatus]?.label}
                variant={labels[cycle?.cycleStatus]?.variant}
                hasDot={true}
                style={{ flexShrink: 0, alignSelf: "center" }}
              />
            )}

            {evaluationCount ? (
              <Tag
                variant="pink"
                style={{ flexShrink: 0, alignSelf: "center" }}
              >
                <Icons name="IconFileDescription" size={16} />{" "}
                {evaluationCount > 1
                  ? `${evaluationCount} avaliações`
                  : "avaliação"}
              </Tag>
            ) : null}
          </TimelineTitleArea>

          <StyledText variant="body3" weight={400} setColor="neutral40">
            {description}
          </StyledText>
        </TimelineTitleWrapper>

        <ButtonArea>
          <ActionButton
            variant="primary"
            size="medium"
            onClick={() => {
              if (_id) navigate(routes.PageCycleDetail(_id));

              track({
                name: "people_strategic_hr_performance_company_view_cycles",
              });
            }}
          >
            Ver ciclo
            <Icons name="IconArrowRight" />
          </ActionButton>

          <Menu
            type={"select"}
            options={renderOptions}
            disableAutoFocusItem={true}
            anchorOrigin={{ vertical: "center", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <PillButton
              variant="default"
              size="small"
              type="secondary"
              icon="IconDotsVertical"
            />
          </Menu>
        </ButtonArea>
      </TimelineInfoContainer>

      <Timeline items={timelineItems || []} />

      <WordsConfirmationModal
        open={open}
        wordToConfirm="EXCLUIR"
        showWarning
        icon="IconAlertCircle"
        title="Tem certeza que deseja excluir este ciclo?"
        customSubTitle={
          <StyledText
            variant="body3"
            setColor="neutral50"
            style={{
              textAlign: "center",
              marginBottom: "14px",
            }}
          >
            <b>
              <DeleteWarning>Importante:</DeleteWarning> Todos os dados{" "}
            </b>
            relacionados as <b>avaliações associadas</b> (caso existam) serão{" "}
            <b>perdidos</b> e essa ação não poderá ser desfeita.
            <br />
            <br />
            Os feedbacks e <b>PDIs</b> associados ao ciclo{" "}
            <b>não serão afetados</b> por essa ação e poderão ser encontrados em
            suas respectivas áreas.
          </StyledText>
        }
        confirm={{ title: "Confirmar exclusão", icon: "IconTrash" }}
        onClose={() => {
          setOpen(false);
        }}
        onConfirm={() => {
          deleteCycle({
            cycleId: _id,
          });
        }}
        isLoading={deleteLoading}
      />
    </TimelineArea>
  );
};
