import { useState, useMemo } from "react";

import {
  Button,
  PillButton,
  LinkButton,
} from "@flash-tecnologia/hros-web-ui-v2";

import { trpc } from "@api/client";

import { Table } from "./Table";

import { StyledIcon, StyledText, StyledTitle, track } from "@utils";

import type { CommonModalProps } from "@components/Modals/types";
import type { Feedback } from "server/src/services/feedback/types";

import {
  FooterContainer,
  HeaderContainer,
  TitleContainer,
  StyledModal,
} from "./styled";

interface IAddCompanyValuesModal extends CommonModalProps {
  selectedValues: Required<Feedback>["companyValues"];
  onValuesChanged: (values: Required<Feedback>["companyValues"]) => void;
  type: "send" | "request";
}

type AddCompanyValuesModalProps = Omit<IAddCompanyValuesModal, "onConfirm">;

export const AddCompanyValuesModal = ({
  open,
  onClose,
  selectedValues,
  onValuesChanged,
  type,
}: AddCompanyValuesModalProps) => {
  const [selectedValuesUpdate, setSelectedValuesUpdate] = useState([]);

  const { data = [], isLoading } =
    trpc.performance.feedback.getCompanyValues.useQuery(undefined, {
      enabled: open,
    });

  const filteredData = useMemo(
    () =>
      data.filter(
        (companyValue) =>
          !selectedValues.some((value) => value._id === companyValue._id)
      ),
    [data, selectedValues]
  );

  const Header = () => {
    return (
      <HeaderContainer>
        <TitleContainer>
          <StyledTitle variant="headline6">
            Selecione os valores da empresa
          </StyledTitle>
          <StyledText variant="body3" setColor="neutral50">
            Insira valores empresariais que orientarão a realização dos
            feedbacks da empresa.
          </StyledText>
        </TitleContainer>
        <div style={{ marginTop: "-20px" }}>
          <PillButton
            variant="default"
            size="small"
            type="secondary"
            icon="IconX"
            onClick={onClose}
          />
        </div>
      </HeaderContainer>
    );
  };

  const Footer = () => (
    <FooterContainer>
      <LinkButton
        variant="neutral"
        children="Cancelar"
        style={{ alignSelf: "center" }}
        onClick={onClose}
      />
      <Button
        size="large"
        variant="primary"
        disabled={!filteredData.length}
        style={{ alignSelf: "center" }}
        onClick={() => {
          track({
            name:
              type === "send"
                ? "performance_myfeedbacks_sendfeedback_addvalues_clicked"
                : "performance_myfeedbacks_requestfeedback_selectcompanyvalues_addvalues_clicked",
          });

          onValuesChanged(selectedValuesUpdate);
          onClose?.(new Event("click"));
        }}
      >
        Adicionar Valores
        <StyledIcon name="IconArrowRight" fill="none" size={40} />
      </Button>
    </FooterContainer>
  );

  return (
    <StyledModal
      onClose={() => onClose?.(new Event("click"))}
      open={open}
      header={<Header />}
      footer={<Footer />}
    >
      <Table
        data={filteredData || []}
        selectedValues={selectedValues || []}
        onValuesSelected={setSelectedValuesUpdate}
        loading={isLoading}
      />
    </StyledModal>
  );
};
