import * as yup from "yup";

import { removeHtmlTags } from "@utils";

export const EDITOR_MAX_LENGTH = 3000;

export const validationSchema = yup.object({
  message: yup
    .string()
    .test(
      "len",
      `O texto deve ter até ${EDITOR_MAX_LENGTH} caracteres`,
      (val) => removeHtmlTags(val).length <= EDITOR_MAX_LENGTH
    )
    .test("empty text editor", "Este campo deve ser preenchido", (val) =>
      Boolean(removeHtmlTags(val).trim().length)
    )
    .required("Este campo deve ser preenchido"),
  companyValues: yup.array(
    yup.object({
      _id: yup.string(),
      name: yup.string(),
      description: yup.string().optional(),
      value: yup.string().oneOf(["doing_well", "could_improve"]).notRequired(),
      commentary: yup.string().notRequired(),
    })
  ),
  evaluationCriterials: yup.array(
    yup.object({
      _id: yup.string(),
      value: yup.string().oneOf(["doing_well", "could_improve"]).notRequired(),
      commentary: yup.string().notRequired(),
    })
  ),
});
