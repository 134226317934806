import { useMemo, useState } from "react";
import { cloneDeep } from "lodash-es";

import { EmptyState, Icons } from "@flash-tecnologia/hros-web-ui-v2";

import { StyledText, renderHtmlText } from "@utils";

import { SearchField } from "@components/SearchField";

import {
  Comment,
  EmptyStateArea,
  ExpandArea,
  ExpandButton,
  InfoArea,
  Line,
  ValueArea,
  ValueNumber,
} from "./styled";

type IBusinessValue = {
  number: number;
  name: string;
  description: string;
  commentary?: string;
};

const BusinessValue = ({
  number,
  name,
  description,
  commentary,
}: IBusinessValue) => {
  const [expand, setExpand] = useState(false);

  const commentaryText = commentary ? renderHtmlText(commentary) : undefined;

  return (
    <>
      <ValueArea>
        <ValueNumber>
          <StyledText variant="headline9" setColor="neutral30" fontWeight={700}>
            {number}.
          </StyledText>
        </ValueNumber>
        <InfoArea>
          <StyledText
            variant="headline9"
            setColor="neutral30"
            fontWeight={700}
            style={{ marginBottom: "8px" }}
          >
            {name}
          </StyledText>
          <StyledText
            variant="body3"
            setColor="neutral30"
            style={{ marginBottom: "12px" }}
          >
            {description}
          </StyledText>

          {commentaryText && (
            <>
              <StyledText variant="caption" setColor="neutral50">
                Comentário
              </StyledText>
              <Comment
                variant="body3"
                setColor="neutral30"
                expandComment={expand}
              >
                {expand
                  ? commentaryText
                  : commentaryText.length > 185
                  ? commentaryText?.substr(0, 185) + "..."
                  : commentaryText}{" "}
                {commentaryText?.length > 185 && (
                  <ExpandArea>
                    <ExpandButton
                      variant="neutral"
                      onClick={() => setExpand((prev) => !prev)}
                    >
                      Ver {expand ? "Menos" : "Mais"}{" "}
                      <Icons
                        name={expand ? "IconChevronUp" : "IconChevronDown"}
                        size={16}
                      />
                    </ExpandButton>
                  </ExpandArea>
                )}
              </Comment>
            </>
          )}
        </InfoArea>
      </ValueArea>
      <Line marginBottom="20px" />
    </>
  );
};

export const BusinessValues = ({ data }) => {
  const [search, setSearch] = useState("");

  const filteredData = useMemo(() => {
    if (!search) return data;

    let dataToFilter = cloneDeep(data);

    dataToFilter = dataToFilter?.filter((item) => {
      return item?.name?.toLowerCase().includes(search.toLowerCase());
    });

    return dataToFilter;
  }, [data, search]);

  return (
    <div>
      <SearchField
        onChange={(s) => setSearch(s)}
        placeholder={"Buscar valor"}
        disabled={false}
        style={{ marginBottom: "24px" }}
      />

      {filteredData?.map((item, key) => (
        <BusinessValue key={key} {...item} number={key + 1} />
      ))}

      {!filteredData.length && (
        <EmptyStateArea>
          <EmptyState
            buttonText={""}
            description={"Não foram encontrados valores empresariais"}
            buttonProps={{ size: "small", hidden: true }}
          />
        </EmptyStateArea>
      )}
    </div>
  );
};
