import { Body, Footer, Header, InfoGrid } from "./components";

import { MainContainer, StyledDrawer, ContentContainer } from "./styled";

import { useAnswersInfo } from "./hooks";

import type { EvaluationStatus } from "server/src/services/evaluation/types";

type IEvaluationAnswersDrawerProps = {
  isOpen: boolean;
  hasCommentaries?: boolean;
  evaluationStatus?: EvaluationStatus;
  evaluatedId?: string;
  evaluationId?: string;
  onClose: () => void;
  onSubmit: () => void;
};

export const EvaluationAnswersDrawer = ({
  isOpen,
  evaluatedId = "",
  evaluationId = "",
  hasCommentaries,
  onClose,
  onSubmit,
}: IEvaluationAnswersDrawerProps) => {
  const {
    employeeData,
    employeeResults,
    loadingEmployeeData,
    loadingResults,
    disabledSend,
  } = useAnswersInfo({
    isOpen,
    evaluatedId,
    evaluationId,
  });

  return (
    <StyledDrawer
      id={evaluatedId}
      anchor="right"
      open={isOpen}
      onClose={onClose}
    >
      <MainContainer>
        <Header
          employeeData={employeeData}
          loading={loadingEmployeeData}
          onClose={onClose}
        />

        <ContentContainer>
          <InfoGrid
            loading={loadingEmployeeData}
            evaluated={employeeData?.evaluatedName}
            evaluator={employeeData?.reportsToName}
          />

          <Body
            loadingSections={loadingResults}
            sections={employeeResults?.sections || []}
            scale={employeeResults?.scale || []}
            generalCommentary={employeeResults?.generalCommentary || ""}
            generalCommentaryEnabled={
              !!employeeResults?.generalCommentaryEnabled
            }
          />
        </ContentContainer>
      </MainContainer>

      <Footer
        onSubmit={() => onSubmit()}
        onCancel={onClose}
        isLoading={false}
        disableSendButton={disabledSend || !hasCommentaries}
      />
    </StyledDrawer>
  );
};
