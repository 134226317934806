import { Header } from "../Header";
import { SelectedList } from "../List";

import { Container } from "./styled";

export const SelectedListArea = ({
  selectedLabel,
}: {
  selectedLabel: string;
}) => {
  return (
    <Container>
      <Header selectedLabel={selectedLabel} />
      <SelectedList />
    </Container>
  );
};
