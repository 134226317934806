import { ReactNode } from "react";

import {
  Dot,
  Tag,
  Typography,
  TagProps,
} from "@flash-tecnologia/hros-web-ui-v2";

export interface ITableTagProps {
  label: string | ReactNode;
  variant: TagProps["variant"];
  hasDot?: boolean;
  style?: React.CSSProperties;
}

export const TableTag = ({
  label,
  variant,
  hasDot = false,
  style,
}: ITableTagProps) => {
  return (
    <Tag variant={variant} style={style} as="div">
      {hasDot && <Dot variant={variant} />}
      <Typography variant="caption" style={{ fontWeight: 600 }}>
        {label}
      </Typography>
    </Tag>
  );
};
