import styled from "styled-components";
import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";

const StyledTitle = styled(Typography)<{
  setColor?: string;
  disabled?: boolean;
}>`
  && {
    font-weight: 700;

    color: ${({ theme, setColor }) => {
      switch (setColor) {
        case "primary":
          return theme.colors.primary;
        case "secondary40":
          return theme.colors.secondary[40];
        case "secondary50":
          return theme.colors.secondary[50];
        case "tertiary40":
          return theme.colors.tertiary[40];
        case "neutral10":
          return theme.colors.neutral[10];
        case "neutral20":
          return theme.colors.neutral[20];
        case "neutral30":
          return theme.colors.neutral[30];
        case "neutral40":
          return theme.colors.neutral[40];
        case "neutral50":
          return theme.colors.neutral[50];
        case "neutral60":
          return theme.colors.neutral[60];
        case "neutral70":
          return theme.colors.neutral[70];
        case "error40":
          return theme.colors.feedback.error[40];
        case "info10":
          return theme.colors.feedback.info[10];
        case "info40":
          return theme.colors.feedback.info[40];
        case "success40":
          return theme.colors.feedback.success[40];
        default:
          "";
          break;
      }
    }};

    ${({ theme, disabled }) => {
      if (disabled) {
        return `color: ${theme.colors.neutral[70]}`;
      }
    }}
  }
`;

const StyledText = styled(Typography)<{
  setColor?: string;
  fontWeight?: string | number;
}>`
  && {
    color: ${({ theme, setColor }) => {
      switch (setColor) {
        case "neutral10":
          return theme.colors.neutral[10];
        case "info10":
          return theme.colors.feedback.info[10];
        case "neutral20":
          return theme.colors.neutral[20];
        case "neutral30":
          return theme.colors.neutral[30];
        case "neutral40":
          return theme.colors.neutral[40];
        case "neutral50":
          return theme.colors.neutral[50];
        case "neutral60":
          return theme.colors.neutral[60];
        case "neutral70":
          return theme.colors.neutral[70];
        case "neutral100":
          return theme.colors.neutral[100];
        case "secondary50":
          return theme.colors.secondary[50];
        case "primary":
          return theme.colors.primary;
        case "error40":
          return theme.colors.feedback.error[40];
        case "error50":
          return theme.colors.feedback.error[40];
        default:
          "";
          break;
      }
    }};

    ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight}`};
  }
`;

const StyledIcon = styled(Icons)<{ setColor?: string; disabled?: boolean }>`
  && {
    color: ${({ theme, setColor }) => {
      switch (setColor) {
        case "neutral30":
          return theme.colors.neutral[30];
        case "primary":
          return theme.colors.primary;
        case "tertiary40":
          return theme.colors.tertiary[40];
        case "secondary50":
          return theme.colors.secondary[50];
        case "secondary80":
          return theme.colors.secondary[80];
        case "secondary95":
          return theme.colors.secondary[95];
        case "error40":
          return theme.colors.feedback.error[40];
        case "error50":
          return theme.colors.feedback.error[50];
        case "success40":
          return theme.colors.feedback.success[40];
        case "info40":
          return theme.colors.feedback.info[40];
        case "neutral100":
          return theme.colors.neutral[100];
        case "neutral40":
          return theme.colors.neutral[40];
        case "neutral60":
          return theme.colors.neutral[60];
        case "neutral90":
          return theme.colors.neutral[90];
        default:
          "";
          break;
      }
    }};

    ${({ theme, disabled }) => {
      if (disabled) {
        return `color: ${theme.colors.neutral[70]}`;
      }
    }}
  }
`;

const StyledSpan = styled.span<{
  setColor?: string;
  disabled?: boolean;
  fontWeight?: string | number;
}>`
  && {
    font-weight: 400;
    ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight}`};

    color: ${({ theme, setColor }) => {
      switch (setColor) {
        case "primary":
          return theme.colors.primary;
        case "secondary50":
          return theme.colors.secondary[50];
        case "tertiary40":
          return theme.colors.tertiary[40];
        case "neutral10":
          return theme.colors.neutral[10];
        case "neutral20":
          return theme.colors.neutral[20];
        case "neutral30":
          return theme.colors.neutral[30];
        case "neutral40":
          return theme.colors.neutral[40];
        case "neutral50":
          return theme.colors.neutral[50];
        case "neutral60":
          return theme.colors.neutral[60];
        case "neutral70":
          return theme.colors.neutral[70];
        case "error40":
          return theme.colors.feedback.error[40];
        case "info10":
          return theme.colors.feedback.info[10];
        case "info40":
          return theme.colors.feedback.info[40];
        case "success40":
          return theme.colors.feedback.success[40];
        default:
          "";
          break;
      }
    }};

    ${({ theme, disabled }) => {
      if (disabled) {
        return `color: ${theme.colors.neutral[70]}`;
      }
    }}
  }
`;

const StyledPLine = styled.p<{ numberOfLines: number; wordBreak?: string }>`
  display: block;
  display: -webkit-box;
  line-height: ${({ numberOfLines }) => numberOfLines};
  -webkit-line-clamp: ${({ numberOfLines }) => numberOfLines};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: ${({ wordBreak }) => wordBreak || "break-all"};
`;

export { StyledIcon, StyledText, StyledTitle, StyledSpan, StyledPLine };
