import { Root, SelectedListArea, Table } from "./components";

import { ITransferListProps } from "./components/types";

export const TransferList = <T extends object & { _id: string }>({
  columns,
  data,
  filters,
  loading,
  pagination,
  tableProps,
  selectedListProps,
  viewByProps,
  selected,
  filterInitialValues,
  filterBanner,
  searchValue,
  onSearchChange,
  onFilterChange,
  onPaginationChanged,
  onSelectedChanged,
  customFilterEmptyState,
  customTableEmptyState,
}: ITransferListProps<T>) => {
  return (
    <Root selected={selected} onSelectedChanged={onSelectedChanged}>
      <Table
        columns={columns}
        data={data}
        filters={filters}
        loading={loading}
        pagination={pagination}
        tableTitle={tableProps.title}
        tableTagLabel={tableProps.tagLabel}
        options={tableProps.options}
        viewByProps={viewByProps}
        filterInitialValues={filterInitialValues}
        filterBanner={filterBanner}
        searchValue={searchValue}
        onSearchChange={onSearchChange}
        onFilterChange={onFilterChange}
        onPaginationChanged={onPaginationChanged}
        customFilterEmptyState={customFilterEmptyState}
        customTableEmptyState={customTableEmptyState}
      />

      <SelectedListArea selectedLabel={selectedListProps.title} />
    </Root>
  );
};
