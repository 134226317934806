import { useEffect, useMemo, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { routes } from "@routes";

import { dispatchToast, getAccessTokenSync } from "@utils";

import { trpc } from "@api/client";

import type { FormValuesProps } from "./types";

interface IUseBasicStepsInfoProps {
  requestId?: string;
  feedbackId?: string;
}

const useBasicStepsInfo = ({
  feedbackId,
  requestId,
}: IUseBasicStepsInfoProps) => {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const isManager = useMemo(
    () => !!pathname.match("/manage-feedbacks"),
    [pathname]
  );

  const breadcrumbs = [
    {
      label: isManager ? "Gerenciar Feedbacks" : `Feedbacks`,
      route: isManager ? routes.PageManageFeedbacks : routes.PageMyFeedbacks,
    },
    {
      label: "Enviar novo feedback",
    },
  ];

  const [formValues, setFormValues] = useState<FormValuesProps>({
    basicInfoForm: undefined,
    configurationForm: undefined,
  });

  const {
    data: feedbackRequest,
    isFetching: feedbackRequestLoading,
    isError: feedbackRequestError,
  } = trpc.performance.feedback.getFeedbackRequestById.useQuery(
    { _id: requestId || "" },
    {
      enabled: !!requestId,
      onError: (e: any) => {
        let message =
          "Erro ao buscar solicitação, favor tentar novamente mais tarde!";

        const notExistsError =
          e?.data?.error === "FEEDBACK_REQUEST_NOT_EXISTS_ERROR";

        if (notExistsError) message = "Solicitação de feedback não encontrada!";

        dispatchToast({
          type: "error",
          content: message,
        });
      },
    }
  );

  const { data: employeeHierarchy, isFetching: hierarchyLoading } =
    trpc.employee.getEmployeeHierarchy.useQuery();

  const {
    data: feedback,
    isFetching: feedbackLoading,
    isError: feedbackError,
  } = trpc.performance.feedback.getFeedbackFull.useQuery(
    { _id: feedbackId || "" },
    {
      enabled: !!feedbackId,
      onError: (e: any) => {
        let message =
          "Erro ao buscar feedback, favor tentar novamente mais tarde!";

        const notExistsError = e?.data?.error === "FEEDBACK_NOT_EXISTS_ERROR";

        if (notExistsError) message = "Feedback não encontrado!";

        dispatchToast({
          type: "error",
          content: message,
        });
      },
    }
  );

  const { mutate: createDraft, isLoading: createDraftLoading } =
    trpc.performance.feedback.createFeedbackDraft.useMutation({
      onError: (e: any) => {
        let message =
          "Erro ao salvar rascunho, favor tentar novamente mais tarde!";

        const samePersonError = e?.data?.error === "FEEDBACK_SAME_PERSON_ERROR";

        const feedbackRequestNotExistsError =
          e?.data?.error === "FEEDBACK_REQUEST_NOT_EXISTS_ERROR";

        if (samePersonError)
          message = "Erro, destinatário é a mesma pessoa que está enviando!";

        if (feedbackRequestNotExistsError)
          message = "Erro, solicitação de feedback não encontrada!";

        dispatchToast({
          type: "error",
          content: message,
        });
      },
    });

  const { mutate: updateFeedback, isLoading: updateFeedbackLoading } =
    trpc.performance.feedback.updateFeedback.useMutation({
      onError: (e: any) => {
        let message =
          "Erro ao atualizar feedback, favor tentar novamente mais tarde!";

        const samePersonError = e?.data?.error === "FEEDBACK_SAME_PERSON_ERROR";

        const feedbackRequestNotExistsError =
          e?.data?.error === "FEEDBACK_REQUEST_NOT_EXISTS_ERROR";

        if (samePersonError)
          message = "Erro, destinatário é a mesma pessoa que está enviando!";

        if (feedbackRequestNotExistsError)
          message = "Erro, solicitação de feedback não encontrada!";

        dispatchToast({
          type: "error",
          content: message,
        });
      },
    });

  useEffect(() => {
    if (!requestId) return;

    const accessToken = getAccessTokenSync();

    const requestFrom = feedbackRequest && feedbackRequest.from?._id;

    if (requestFrom && requestFrom !== accessToken?.employeeId) {
      dispatchToast({
        type: "error",
        content:
          "Solicitação de feedback vinculada a outro usuário, favor tentar novamente!",
      });

      navigate(isManager ? routes.PageManageFeedbacks : routes.PageMyFeedbacks);

      return;
    }

    if (feedbackRequest?.status === "sent") {
      dispatchToast({
        type: "error",
        content:
          "Solicitação de feedback já enviada, favor criar uma nova solicitação!",
      });

      navigate(isManager ? routes.PageManageFeedbacks : routes.PageMyFeedbacks);

      return;
    }

    setFormValues({
      basicInfoForm: {
        type: feedbackRequest?.type || "voluntary",
        to: feedbackRequest?.to?._id || "",
        evaluationId: "",
        requestMessage: feedbackRequest?.message || "",
      },
      configurationForm: {
        companyValues: feedbackRequest?.companyValues || [],
        evaluationCriterials: [],
        message: "",
      },
    });
  }, [feedbackRequest]);

  useEffect(() => {
    if (!feedbackId) return;

    const accessToken = getAccessTokenSync();

    const feedbackFrom = feedback?.feedback && feedback.feedback?.from?._id;

    if (feedbackFrom && feedbackFrom !== accessToken?.employeeId) {
      dispatchToast({
        type: "error",
        content: "Feedback vinculado a outro usuário, favor tentar novamente!",
      });

      navigate(isManager ? routes.PageManageFeedbacks : routes.PageMyFeedbacks);

      return;
    }

    if (["answered", "unanswered"].includes(feedback?.feedback?.status || "")) {
      dispatchToast({
        type: "error",
        content: "Feedback já enviado, favor iniciar um novo envio!",
      });

      navigate(isManager ? routes.PageManageFeedbacks : routes.PageMyFeedbacks);

      return;
    }

    setFormValues({
      basicInfoForm: {
        type: feedback?.feedback?.type || "voluntary",
        to: feedback?.feedback?.to?._id || "",
        evaluationId: feedback?.feedback.evaluationId || "",
        requestMessage: feedback?.request?.message || "",
      },
      configurationForm: {
        companyValues: feedback?.feedback.companyValues || [],
        evaluationCriterials: feedback?.feedback.evaluationCriterials || [],
        message: feedback?.feedback?.message || "",
      },
    });
  }, [feedback]);

  return {
    breadcrumbs,
    isManager,
    isLeader: !!employeeHierarchy?.isLeader,
    loading: hierarchyLoading || feedbackRequestLoading || feedbackLoading,
    error: feedbackError || feedbackRequestError,
    feedbackRequest,
    feedback,
    formValues,
    loadingDraftSave: updateFeedbackLoading || createDraftLoading,
    setFormValues,
    navigate,
    updateFeedback,
    createDraft,
  };
};

export { useBasicStepsInfo };
