import styled from "styled-components";

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const AverageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0px;
  gap: 10px;

  width: 100%;

  background: ${({ theme }) => theme.colors.neutral[95]};
  border-radius: 8px;
`;
export { TitleContainer, AverageContainer };
