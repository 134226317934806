import styled from "styled-components";

import { SelectField } from "@flash-tecnologia/hros-web-ui-v2";

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Span = styled.span`
  color: ${({ theme }) => theme.colors.feedback.error[40]};
`;

export const StyledSelect = styled(SelectField)`
  width: 100%;
`;

export const MainContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 38px;
`;
