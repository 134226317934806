import styled from "styled-components";

import { PillButton } from "@flash-tecnologia/hros-web-ui-v2";

export const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledPillButton = styled(PillButton)`
  align-self: center;
`;

export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 31px;
`;

export const SelectButton = styled.button<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  padding: 10px 16px;
  background-color: ${({ theme, selected }) => {
    return selected ? theme.colors.secondary[95] : theme.colors.neutral[100];
  }};

  color: ${({ theme, selected }) => {
    return selected ? theme.colors.secondary : theme.colors.neutral[40];
  }};

  transition: 0.5s;
`;
export const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 16px;
  padding: 10px 8px;
`;
