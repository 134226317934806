import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Grid } from "@mui/material";

import { useFormik } from "formik";
import * as yup from "yup";

import { uniqBy } from "lodash-es";

import { Icons } from "@flash-tecnologia/hros-web-ui-v2";

import { EvaluationWithoutSaveModal } from "@components/Modals";
import { PageTemplate } from "@components/PageTemplate";

import { BasicInfoForm } from "./components";

import { trpc } from "@api/client";
import { routes } from "@routes";

import {
  StyledTitle,
  StyledText,
  removeHtmlTags,
  track,
  dispatchToast,
  getObjDiffs,
} from "@utils";

import type { BasicStepProps, BasicInfoFormProps } from "../../Steps/types";

import { Header, LeftContainer } from "../../Steps/styled";

interface LocationState {
  modelId?: string;
  cycleId?: string;
}

const validationSchema = yup.object({
  privateName: yup
    .string()
    .max(50, "Campo deve ter no máximo 50 caracteres")
    .required("Este campo deve ser preenchido"),
  privateDescription: yup.string().notRequired(),
  cycleId: yup.string().required("Este campo deve ser preenchido"),
  name: yup
    .string()
    .max(50, "Campo deve ter no máximo 50 caracteres")
    .required("Este campo deve ser preenchido"),
  description: yup
    .string()
    .test("empty text editor", "Este campo deve ser preenchido", (val) =>
      Boolean(removeHtmlTags(val).trim().length)
    )
    .required("Este campo deve ser preenchido"),
});

export const BasicInfoStep = ({
  evaluationId,
  isEdit,
  disabledEdit,
  isLoading,
  isUpdating,
  error,
  steps,
  breadcrumbs,
  evaluation,
  onSubmit,
  onNavigateByStep,
}: BasicStepProps) => {
  const navigate = useNavigate();

  const location = useLocation();

  const state = location.state as LocationState;

  const disabled = disabledEdit.all && disabledEdit.partial;

  const [withoutSaveModal, setWithoutSaveModal] = useState(false);

  const { mutate: createEvaluation, isLoading: isMutating } =
    trpc.performance.evaluation.createEvaluation.useMutation({
      onSuccess: (data) => onNavigateByStep("configurations", data._id),
      onError: (error: any) => {
        dispatchToast({
          type: "error",
          content:
            error?.data?.error === "EVALUATION_EXISTS_ERROR"
              ? "Nome público da avaliação já foi utilizado em outra avaliação. Por favor, tente outro."
              : "Erro ao salvar as informações da avaliação, tente novamente em breve.",
        });
      },
    });

  const { data: cycleNames, isLoading: loadingCycles } =
    trpc.performance.cycle.getEvaluationAvailableCycleNames.useQuery(
      undefined,
      {
        onError: () => {
          dispatchToast({
            content:
              "Não foi possível encontrar a lista de ciclos. Por favor, tente em breve.",
            type: "error",
          });
        },
        enabled: !disabled,
        retry: false,
        retryDelay: 3000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      }
    );

  const formik = useFormik<BasicInfoFormProps>({
    initialValues: {
      privateName: "",
      privateDescription: "",
      cycleId: "",
      name: "",
      description:
        '<p style="text-align: justify;">Siga as seguintes instruções para responder à avaliação de desempenho:</p><ol><li>Leia cuidadosamente as perguntas.</li><li>Seja honesto e objetivo em suas respostas.</li><li>Revise suas respostas antes de enviá-las.</li></ol>',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const hasChangedAnyValue = getObjDiffs(values, {
        privateName: evaluation?.privateName,
        privateDescription: evaluation?.privateDescription,
        cycleId: evaluation?.cycleId,
        name: evaluation?.name,
        description: evaluation?.description,
      });

      if (!hasChangedAnyValue) {
        return onNavigateByStep("configurations");
      }

      if (evaluationId) return onSubmit(values, "configurations");

      createEvaluation({ ...values, modelId: state?.modelId });
    },
  });

  useEffect(() => {
    if (!evaluation) return;

    formik.setValues({
      privateName: evaluation?.privateName || "",
      privateDescription: evaluation?.privateDescription || "",
      cycleId: evaluation?.cycleId || "",
      name: evaluation?.name || "",
      description: evaluation?.description || "",
    });
  }, [evaluation]);

  const cycleNamesMapped = useMemo(() => {
    if (!evaluation?.cycleId && !cycleNames?.length) return [];

    const options: { _id: string; name: string }[] = [];

    options.push(...(cycleNames || []));

    if (evaluation?.cycleId) {
      options.push({
        _id: evaluation.cycleId,
        name: evaluation?.cycleName || "",
      });
    }

    const uniqCycleNames = uniqBy(options, "_id");

    return uniqCycleNames.sort((a, b) => a.name.localeCompare(b.name));
  }, [cycleNames, evaluation]);

  useEffect(() => {
    if (!state?.cycleId) return;

    formik.setFieldValue("cycleId", state?.cycleId);
  }, [state?.cycleId]);

  return (
    <PageTemplate
      stepper={{
        steps: steps,
        activeStep: 1,
      }}
      routes={breadcrumbs || []}
      footer={{
        cancelProps: {
          title: "Sair",
          callback: () => {
            setWithoutSaveModal(true);
          },
        },
        confirmProps: {
          title: (
            <>
              Continuar
              <Icons name="IconArrowRight" fill="transparent" />
            </>
          ),
          disabled: error || isLoading || loadingCycles,
          loading: isMutating || isUpdating,
          callback: () => {
            track({
              name: "people_strategic_hr_performance_company_evaluations_createevaluation_basicinformation_continue_clicked",
            });
            formik.handleSubmit();
          },
        },
      }}
    >
      <Header>
        <StyledTitle
          setColor="neutral20"
          variant="headline6"
          children={`${isEdit ? "Editar" : "Criar"} Avaliação`}
        />
      </Header>
      <Grid container paddingBottom={"40px"} spacing={2}>
        <Grid item sm={12} md={4} lg={3} style={{ width: "100%" }}>
          <LeftContainer>
            <StyledTitle
              setColor="secondary50"
              variant="headline7"
              children={"Informações básicas"}
            />

            <StyledText
              setColor="neutral50"
              variant="body3"
              children={
                "Adicione as informações básicas da avaliação, como nome e descrição."
              }
            />
          </LeftContainer>
        </Grid>
        <Grid item sm={12} md={8} lg={9} style={{ width: "100%" }}>
          <BasicInfoForm
            formik={formik}
            isLoading={isLoading}
            loadingCycles={loadingCycles}
            cycleNames={cycleNamesMapped}
            disabledEdit={disabledEdit}
          />
        </Grid>
      </Grid>

      <EvaluationWithoutSaveModal
        open={withoutSaveModal}
        onClose={() => setWithoutSaveModal(false)}
        onConfirm={() => navigate(routes.PageManageEvaluations)}
      />
    </PageTemplate>
  );
};
