import styled from "styled-components";

import { Icons, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

export const MainContainer = styled.div`
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  padding: 24px;
  padding-right: 34px;

  display: flex;
  margin-bottom: 24px;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    gap: 25px;
  }
`;

export const PeopleArea = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  gap: 4px;
`;

export const FlexCenter = styled.div<{ rightSide?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ rightSide }) => (rightSide ? "flex-end" : "")};
  width: 100%;
  gap: 6px;
`;

export const ArrowIcon = styled(Icons)`
  margin: 0 12px;
  flex-shrink: 0;
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

export const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  margin-right: 6px;
  width: 34px;
  height: 34px;
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  border-radius: 200px;
`;

export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const PersonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledLoading = styled(Skeleton).attrs(() => ({
  variant: "rounded",
  width: "100%",
}))`
  margin-bottom: 24px;
`;
