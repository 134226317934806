import { useState, useMemo, Fragment } from "react";
import { cloneDeep, groupBy, flatMap, compact } from "lodash-es";

import { dayjs, Icons } from "@flash-tecnologia/hros-web-ui-v2";

import { FormCard } from "@components/Cards";
import { TransferList } from "@components/TransferList";
import { OverflowCheck } from "@components/OverflowCheck";

import { TableEmptyState } from "../TableEmptyState";
import { FilterBanner } from "../FilterBanner";

import { StyledText } from "@utils";

import {
  TransferListArea,
  AlertIcon,
  EmployeeName,
  NoLeaderText,
  StyledAvatar,
  TableData,
  TableDataArea,
  CountArea,
} from "./styled";

import type { AddEvaluatedsProps, DataType, TransferFilter } from "./types";
import type { ColumnDef } from "@tanstack/react-table";

export const AddEvaluateds = ({
  base,
  selectedBase,
  filtersBase,
  loading = false,
  hasSelfType,
  hasLeaderType,
  hasLedType,
  disabledEdit,
  onSelectedChanged,
}: AddEvaluatedsProps) => {
  const [viewBy, setViewBy] = useState<string>("people");
  const [search, setSearch] = useState<string>("");
  const [filtered, setFiltered] = useState<{ [key: string]: string[] }>({});

  const generateView = ({
    data,
    type,
  }: {
    data: DataType[];
    type: "departments" | "groups" | "roles";
  }) => {
    const dataToShow: DataType[] = [];

    const individualData = compact(
      flatMap(data || [], (item) => {
        return item[type]?.map((d) => ({
          ...item,
          [type]: [d],
        }));
      })
    );

    const peopleGroup = groupBy(individualData, type);

    Object.keys(peopleGroup).map((key) => {
      if (!key) return;

      const people = peopleGroup[key];
      const name = filtersBase[type].find((d) => d._id === key)?.label;

      if (!name) return;

      const item = {
        _id: key,
        name,
        subRows: people,
      };

      dataToShow.push(item);
    });

    return dataToShow;
  };

  const dataByView: DataType[] = useMemo(() => {
    const data = cloneDeep(base);

    const mappedData = data?.map((item) => ({
      ...item,
      departments: item?.departments?.length ? item?.departments : ["0"],
      groups: item?.groups?.length ? item?.groups : ["0"],
      roles: item?.roles?.length ? item?.roles : ["0"],
    }));

    if (viewBy === "people") return mappedData;

    if (viewBy === "departments")
      return generateView({ data: mappedData, type: "departments" });

    if (viewBy === "groups")
      return generateView({ data: mappedData, type: "groups" });

    if (viewBy === "roles")
      return generateView({ data: mappedData, type: "roles" });

    return [];
  }, [viewBy, JSON.stringify(base), JSON.stringify(filtersBase)]);

  const filtersToShow: TransferFilter[] = useMemo(() => {
    const filters: TransferFilter[] = [];

    const filtersLabels = {
      departments: "Departamentos",
      groups: "Grupos",
      roles: "Cargos",
      reportsTo: "Líder imediato",
      startDate: "Admitidos a partir de",
      endDate: "Admitidos até",
    };

    const emptyFilters = ["startDate", "endDate"];

    Object.keys(filtersBase)?.map((key) => {
      const selectedFilter = filtersBase[key];

      const options = selectedFilter?.map((selected) => ({
        label: selected.label,
        value: selected._id,
      }));

      if (!emptyFilters.includes(key) && !options?.length) return;

      const item = {
        accessor: key,
        label: filtersLabels[key],
        options,
        type: options?.length ? "checkbox" : "date",
      } as TransferFilter;

      filters.push(item);
    });

    return filters;
  }, [JSON.stringify(filtersBase)]);

  const viewByOptions = useMemo(() => {
    const options = [{ label: "Pessoas", value: "people" }];

    const { departments = [], groups = [], roles = [] } = filtersBase;

    if (departments?.length) {
      setViewBy("departments");
      options.push({ label: "Departamento", value: "departments" });
    }

    if (groups?.length) options.push({ label: "Grupos", value: "groups" });

    if (roles?.length) options.push({ label: "Cargos", value: "roles" });

    return options;
  }, [JSON.stringify(filtersBase)]);

  const handleFilter = ({
    data,
    filtered,
  }: {
    data: DataType[];
    filtered: { [key: string]: string[] };
  }) => {
    return data?.filter((item) => {
      for (const key in filtered) {
        const itemValue = item[key] || undefined;

        if (Array.isArray(itemValue)) {
          if (!itemValue.some((el) => filtered[key].includes(el))) {
            return false;
          }
        }

        if (
          key === "startDate" &&
          !dayjs(itemValue).isSameOrAfter(
            dayjs(filtered[key][0]) || itemValue === undefined
          )
        ) {
          return false;
        }

        if (
          key === "endDate" &&
          !dayjs(item["startDate"]).isSameOrBefore(dayjs(filtered[key][0]))
        ) {
          return false;
        }

        if (key === "reportsTo" && !filtered[key].includes(itemValue)) {
          return false;
        }
      }

      return true;
    });
  };

  const filteredData = useMemo(() => {
    let dataToFilter = cloneDeep(dataByView);

    if (Object.keys(filtered)?.length) {
      if (viewBy != "people") {
        dataToFilter = dataToFilter?.map((data) => {
          if (!data?.subRows?.length) return data;
          const subRows = handleFilter({ data: data?.subRows, filtered });

          return {
            ...data,
            subRows: subRows,
          };
        });
      } else {
        dataToFilter = handleFilter({ data: dataToFilter, filtered });
      }
    }

    if (!search) return dataToFilter;

    if (viewBy != "people") {
      dataToFilter = dataToFilter?.map((item) => {
        const subRows =
          item?.subRows?.filter((sub) =>
            sub?.name?.toLowerCase().includes(search.toLowerCase())
          ) ?? [];

        return {
          ...item,
          subRows: subRows,
        };
      });
    } else {
      dataToFilter = dataToFilter?.filter((item) =>
        item?.name?.toLowerCase().includes(search.toLowerCase())
      );
    }

    return dataToFilter;
  }, [JSON.stringify(dataByView), search, JSON.stringify(filtered)]);

  const handleClear = () => {
    setFiltered({});
    setSearch("");
  };

  const expandable = viewBy != "people" ? true : undefined;

  const labelByType = {
    departments: "Todos os departamentos",
    people: "Todas as pessoas",
    groups: "Todos os grupos",
    roles: "Todos os cargos",
  };

  const departmentColumn = {
    header: "Departamento",
    accessorKey: "departments",
    minSize: 270,
    cell: ({
      row: {
        original: { departments },
      },
    }) => {
      const departmentWithName = departments?.map((dep) => ({
        name: filtersBase?.departments?.find(({ _id }) => _id === dep)?.label,
      }));

      return (
        <TableData>
          <TableDataArea>
            <StyledText variant="body3" setColor="neutral40">
              {departmentWithName?.map(({ name }, index) => (
                <>
                  {name}
                  {departmentWithName?.length != index + 1 && ", "}
                </>
              ))}
            </StyledText>
          </TableDataArea>
        </TableData>
      );
    },
  } as ColumnDef<DataType>;

  return (
    <FormCard
      title="Seleção de avaliados"
      description="Quais pessoas serão avaliadas?"
    >
      <TransferListArea disabled={disabledEdit?.all}>
        <TransferList
          data={filteredData}
          filters={filtersToShow || []}
          columns={[
            {
              header: "Nome",
              accessorKey: "name",
              minSize: 270,
              cell: ({
                row: {
                  original: {
                    name = "",
                    hasLeader = false,
                    isLeader = false,
                    startDate,
                    subRows,
                  },
                },
              }) => {
                const missingTypes: string[] = [];
                if (hasLeaderType && !hasLeader) missingTypes.push("líder");
                if (hasLedType && !isLeader) missingTypes.push("liderado");

                const errorMessage = missingTypes.reduce(
                  (acc: string, value) => {
                    if (acc) return `${acc} e ${value}`;
                    return value;
                  },
                  ""
                );

                if (subRows) {
                  return (
                    <TableData>
                      <TableDataArea>
                        <StyledText
                          variant="body3"
                          setColor="neutral40"
                          fontWeight={700}
                        >
                          {name ?? ""}
                        </StyledText>
                        <CountArea>
                          <Icons name="IconUsers" size={16} />
                          <StyledText variant="body4" setColor="neutral50">
                            {subRows?.length + " pessoas"}
                          </StyledText>
                        </CountArea>
                      </TableDataArea>
                    </TableData>
                  );
                }

                return (
                  <TableData>
                    <StyledAvatar>{name?.charAt(0) ?? ""}</StyledAvatar>
                    <TableDataArea>
                      <EmployeeName variant="body3">{name ?? ""}</EmployeeName>

                      {(errorMessage || !startDate) && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <AlertIcon name="IconAlertTriangle" size={16} />
                          <div>
                            {errorMessage && (
                              <NoLeaderText variant="body4">
                                {errorMessage +
                                  ` não associado${
                                    missingTypes.length > 1 ? "s" : ""
                                  }`}
                              </NoLeaderText>
                            )}
                            {!startDate && (
                              <NoLeaderText variant="body4">
                                Sem data de admissão
                              </NoLeaderText>
                            )}
                          </div>
                        </div>
                      )}
                    </TableDataArea>
                  </TableData>
                );
              },
            },
            {
              header: "Quem irá avaliar",
              accessorKey: "evaluatedBy",
              minSize: 320,
              cell: ({
                row: {
                  original: { _id, name, reportsTo, subRows },
                },
              }) => {
                const foundLeader =
                  hasLeaderType && base?.find(({ _id }) => _id === reportsTo);

                const foundLed =
                  (hasLedType &&
                    base
                      ?.filter(({ reportsTo }) => reportsTo === _id)
                      ?.map(({ name }) => name)) ||
                  [];

                const parts: JSX.Element[] = [];

                if (hasSelfType) {
                  parts.push(
                    <Fragment key={"self"}>
                      <b>{name} </b>
                      (autoavaliação)
                      {foundLeader || foundLed.length ? ", " : ""}
                    </Fragment>
                  );
                }

                if (foundLeader) {
                  parts.push(
                    <Fragment key={"byLeader"}>
                      <b>{foundLeader?.name} </b>
                      (líder direto)
                      {foundLed.length ? ", " : ""}
                    </Fragment>
                  );
                }

                if (foundLed.length) {
                  parts.push(
                    <Fragment key={"byLed"}>
                      <b>{foundLed.join(", ")} </b>
                      (liderado)
                    </Fragment>
                  );
                }

                if (subRows) return <></>;

                return (
                  <TableData>
                    <TableDataArea>
                      <OverflowCheck
                        text={
                          <StyledText variant="body4" setColor="neutral50">
                            {parts}
                          </StyledText>
                        }
                        width="270px"
                        customTooltipText={
                          <StyledText variant="body4" setColor="neutral100">
                            {parts}
                          </StyledText>
                        }
                      />
                    </TableDataArea>
                  </TableData>
                );
              },
            },
            ...(viewBy === "people" ? [departmentColumn] : []),
          ]}
          loading={loading}
          tableProps={{
            title: labelByType[viewBy],
            tagLabel: `${dataByView.length}`,
            options: { expandable },
          }}
          selectedListProps={{
            title: "Selecionadas",
          }}
          viewByProps={{
            options: viewByOptions,
            selected: viewBy,
            onSelectedChanged: setViewBy,
            renderLabel: (value, options) => (
              <StyledText variant="body4" setColor="neutral30" weight={700}>
                Ver por: {options.find((o) => o.value === value)?.label}
              </StyledText>
            ),
          }}
          filterInitialValues={filtered}
          onSelectedChanged={(value) => onSelectedChanged(value)}
          searchValue={search}
          onSearchChange={(value) => setSearch(value)}
          onFilterChange={(value) => setFiltered(value)}
          selected={selectedBase}
          filterBanner={<FilterBanner />}
          customTableEmptyState={<TableEmptyState onClick={handleClear} />}
        />
      </TransferListArea>
    </FormCard>
  );
};
