import { useMemo } from "react";

import { dispatchToast } from "@utils";

import { trpc } from "@api/client";

import type { BasicInfoFormProps } from "../../../types";

interface IUseBasicInfoFormProps {
  isLeader?: boolean;
  evaluationId?: string;
  type: BasicInfoFormProps["type"];
}

const useBasicInfoForm = ({
  isLeader,
  type,
  evaluationId,
}: IUseBasicInfoFormProps) => {
  const { data: evaluations = [], isFetching: loadingEvaluations } =
    trpc.performance.evaluation.getAnsweredEvaluationNameByEvaluator.useQuery(
      { type: ["byLeader"] },
      {
        enabled: isLeader && type === "evaluation",
        onSuccess: (data) => {
          if (data?.length) return;

          dispatchToast({
            type: "error",
            content: `Nenhuma avaliação respondida pelo líder encontrada!`,
          });
        },
      }
    );

  const evaluationSelected = useMemo(() => {
    if (!evaluationId) return undefined;

    const evaluation = evaluations?.find((e) => e.value === evaluationId);

    if (!evaluation) return undefined;

    return {
      _id: evaluation.value,
      name: evaluation.label,
    };
  }, [evaluationId, evaluations]);

  const { data: employeesByLeader, isFetching: loadingEmployeesByLeader } =
    trpc.employee.getNamesByEvaluationEmployee.useQuery(
      {
        evaluationId: evaluationSelected?._id || "",
        status: "answered",
        type: ["byLeader"],
      },
      {
        enabled: type === "evaluation" && !!evaluationSelected?._id,
        onError: () => {
          dispatchToast({
            content:
              "Erro ao carregar a lista de pessoas. Tente novamente em breve.",
            type: "error",
          });
        },
      }
    );

  const { data: feedbackEmployees, isFetching: loadingEmployees } =
    trpc.performance.feedback.getSendFeedbackBaseData.useQuery(undefined, {
      enabled: type === "voluntary",
      onError: () => {
        dispatchToast({
          content:
            "Erro ao carregar a lista de pessoas. Tente novamente em breve.",
          type: "error",
        });
      },
    });

  return {
    loadingEmployees: loadingEmployees || loadingEmployeesByLeader,
    employees:
      type === "evaluation"
        ? employeesByLeader || []
        : feedbackEmployees?.employees || [],
    loadingEvaluations,
    evaluations,
    evaluationSelected,
  };
};

export { useBasicInfoForm };
