import { StyledIcon, StyledTitle } from "@utils";
import { IconBg, MainContainer } from "./styled";

export const RenderReaction = ({ reaction }) => {
  switch (reaction) {
    case "liked":
      return (
        <MainContainer>
          <IconBg setColor="info90">
            <StyledIcon fill="none" name="IconThumbUp" setColor="info40" />
          </IconBg>
          <StyledTitle
            variant="body4"
            children={"Gostei"}
            setColor="neutral30"
          />
        </MainContainer>
      );
    case "loved":
      return (
        <MainContainer>
          <IconBg setColor="tertiary90">
            <StyledIcon fill="none" name="IconHeart" setColor="tertiary40" />
          </IconBg>
          <StyledTitle variant="body4" children={"Amei"} setColor="neutral30" />
        </MainContainer>
      );
    case "support":
      return (
        <MainContainer>
          <IconBg setColor="success90">
            <StyledIcon
              fill="none"
              name="IconHeartHandshake"
              setColor="success40"
            />
          </IconBg>
          <StyledTitle
            variant="body4"
            children={"Apoio"}
            setColor="neutral30"
          />
        </MainContainer>
      );
    case "congratulate":
      return (
        <MainContainer>
          <IconBg setColor="error90">
            <StyledIcon fill="none" name="IconConfetti" setColor="error40" />
          </IconBg>
          <StyledTitle
            variant="body4"
            children={"Parabéns"}
            setColor="neutral30"
          />
        </MainContainer>
      );
    default:
      return (
        <MainContainer>
          <StyledIcon fill="none" name="IconMoodHappy" setColor="neutral40" />
          <StyledTitle
            variant="body4"
            children={"Reagir"}
            setColor="neutral30"
          />
        </MainContainer>
      );
  }
};
