import styled from "styled-components";

export const MainContainer = styled.div<{ variant: "info" | "secondary" }>`
  display: flex;
  align-items: center;
  padding: 8px 10px;
  gap: 16px;

  width: 100%;

  background: ${({ theme, variant }) => {
    const setColor = {
      info: theme.colors.feedback.info[90],
      secondary: theme.colors.secondary[90],
    };
    return setColor[variant];
  }};
  border: 1px solid
    ${({ theme, variant }) => {
      const setColor = {
        info: theme.colors.feedback.info[40],
        secondary: theme.colors.secondary[40],
      };
      return setColor[variant];
    }};
  border-radius: 8px;
`;
