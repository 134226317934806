import { LinkButton } from "@flash-tecnologia/hros-web-ui-v2";

import { StyledTitle } from "@utils";

import { EmptyStateContainer, EmptyStateImg } from "./styled";

interface ITableEmptyStateProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const TableEmptyState = ({ onClick }: ITableEmptyStateProps) => {
  return (
    <EmptyStateContainer>
      <EmptyStateImg />
      <StyledTitle
        variant="body3"
        children={
          <>
            Nenhum participante encontrado.
            <br />
            Por favor, tente novamente.
          </>
        }
        setColor="neutral50"
        weight={600}
      />
      {onClick && (
        <LinkButton variant="default" onClick={onClick}>
          Limpar filtros
        </LinkButton>
      )}
    </EmptyStateContainer>
  );
};
