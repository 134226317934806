import { useMemo, useState } from "react";

import {
  DataGrid,
  Skeleton,
  EmptyState,
  dayjs,
  Tag,
  Button,
  Dot,
} from "@flash-tecnologia/hros-web-ui-v2";

import { RenderNameField } from "@components/TableNameField";
import { FeedbackDrawer } from "@components/Drawers";

import { StyledText, pageSizeOptions } from "@utils";

interface TableGridProps {
  data: any[];
  rawData: any[];
  pageSize: number;
  loading: boolean;
  refetch?: () => void;
}

export const TableGrid = ({
  data,
  rawData,
  pageSize,
  loading,
}: TableGridProps) => {
  const [openDrawer, setOpenDrawer] = useState({
    open: false,
    feedbackData: "",
    answering: false,
  });

  const columns = useMemo(
    () => [
      {
        Header: "Enviado por",
        accessor: "from",
        Cell: ({ row }) => RenderNameField(row.original.from.name || "-"),
      },
      {
        Header: "Enviado para",
        accessor: "to",
        Cell: ({ row }) => RenderNameField(row.original.to.name || "-"),
      },
      {
        Header: "Tipo de feedback",
        accessor: "type",
        Cell: ({ value }) => {
          const info = {
            evaluation: {
              label: "Atrelado a uma avaliação",
            },
            voluntary: { label: "Espontâneo" },
          };

          return (
            <div style={{ display: "flex", gap: "8px" }}>
              <StyledText variant="body3" style={{ fontWeight: 600 }}>
                {info[value]?.label}
              </StyledText>
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          const info = {
            visualized: { label: "Visualizado", variant: "success" },
            sent: { label: "Não visualizado", variant: "error" },
            draft: { label: "Rascunho", variant: "info" },
          };

          return (
            <div style={{ display: "flex", gap: "8px", width: "150px" }}>
              <Tag variant={info[value]?.variant}>
                <Dot variant={info[value]?.variant} />
                <StyledText variant="caption" style={{ fontWeight: 600 }}>
                  {info[value]?.label}
                </StyledText>
              </Tag>
            </div>
          );
        },
      },

      {
        Header: "Criado em",
        accessor: "createdAt",
        Cell: ({ value }) => {
          const isValid = dayjs(value).isValid();
          return isValid ? dayjs(value).format("DD/MM/YYYY") : "-";
        },
      },
      {
        Header: "Ações",
        accessor: "action",
        sticky: "right",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Button
              variant="secondary"
              size="medium"
              style={{ width: "222px" }}
              onClick={() => {
                setOpenDrawer({
                  open: true,
                  feedbackData: row.original,
                  answering: false,
                });
              }}
            >
              Ver feedback
            </Button>
          );
        },
      },
    ],
    []
  );

  if (loading) return <Skeleton variant="rounded" height={"400px"} />;

  if (!rawData.length)
    return (
      <EmptyState
        buttonText={""}
        description={"Ainda não existem feedbacks disponíveis."}
        buttonProps={{ size: "small", hidden: true }}
      />
    );

  return (
    <>
      <DataGrid
        hasPagination={true}
        columns={columns}
        data={data || []}
        initialState={{ pageSize: Number(pageSize) }}
        emptyState={{
          isFiltered: true,
          message: "Nenhum feedback foi encontrado.",
        }}
        pageSizeOptions={pageSizeOptions}
      />
      <FeedbackDrawer
        isOpen={openDrawer.open}
        onClose={() =>
          setOpenDrawer({ open: false, feedbackData: "", answering: false })
        }
        feedbackData={openDrawer.feedbackData}
        permission={"view"}
      />
    </>
  );
};
