import styled from "styled-components";

import { Typography } from "@mui/material";

export const TimelineContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  gap: 6px;
`;

export const TopLine = styled.div<{ center?: boolean }>`
  display: flex;
  justify-content: ${({ center }) => (center ? "center" : "space-between")};
  align-items: center;
`;
export const BottomLine = styled.div<{ center?: boolean }>`
  display: flex;
  justify-content: ${({ center }) => (center ? "center" : "space-between")};
`;

export const IconContainer = styled.div<{
  firstChild?: boolean;
  lastChild?: boolean;
  active: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

  margin-left: ${({ firstChild }) => (firstChild ? "36px" : "")};
  margin-right: ${({ lastChild }) => (lastChild ? "36px" : "")};
  background-color: ${({ theme, active }) =>
    active ? theme.colors.secondary[95] : theme.colors.neutral[90]};
  border-radius: 200px;
`;

export const Line = styled.div<{
  active: boolean;
}>`
  width: 100%;
  border-top: 4px dotted
    ${({ theme, active }) =>
      active ? theme.colors.secondary[95] : theme.colors.neutral[90]};
  height: 1px;
  margin: 0px 10px;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 113px;
  width: 100%;
`;

export const DateContainer = styled.div`
  display: flex;
  align-items: center;

  gap: 4px;
  @media screen and (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
    gap: 0px;
  }
`;

export const Separator = styled(Typography)`
  @media screen and (max-width: 1100px) {
    display: none;
  }
`;
