import { EvaluationStep } from "../EvaluationStep";
import { ReviewStep } from "../ReviewStep";

import { StyledSkeleton } from "./styled";

import type {
  EmployeeEvaluationStep,
  AnswerEvaluationType,
  EvaluationStepTypes,
  EvaluationReviewData,
  EmployeeEvaluation,
  EvaluationTypes,
} from "../../types";

interface IStepRendererProps {
  isLoading?: boolean;
  errorCriterial?: { _id: string }[] | undefined;
  type: EvaluationStepTypes;
  currentStep: EmployeeEvaluationStep | undefined;
  reviewData?: EvaluationReviewData[];
  answerAverage: number;
  scale: EmployeeEvaluation["scale"];
  answerEvaluation: AnswerEvaluationType;
  evaluationType?: EvaluationTypes;
}

export const StepRenderer = ({
  isLoading,
  errorCriterial,
  scale,
  type,
  currentStep,
  reviewData = [],
  answerEvaluation,
  answerAverage,
  evaluationType,
}: IStepRendererProps) => {
  const types = {
    evaluation: (
      <EvaluationStep
        scale={scale}
        step={currentStep}
        errorCriterial={errorCriterial}
        answerEvaluation={answerEvaluation}
        evaluationType={evaluationType}
      />
    ),
    review: (
      <ReviewStep
        reviewData={reviewData}
        errorCriterial={errorCriterial}
        answerAverage={answerAverage}
        maxGrade={scale?.length}
        evaluationType={evaluationType}
      />
    ),
  };

  if (isLoading) return <StyledSkeleton />;

  return types[type] || <></>;
};
