import { LinkButton } from "@flash-tecnologia/hros-web-ui-v2";

import {
  VerticalAxis,
  HorizontalAxis,
  Matrix,
  LoadingSkeleton,
} from "./components";

import { trpc } from "@api/client";

import { StyledTitle, StyledText, StyledIcon } from "@utils";

import {
  Row,
  MainContainer,
  EmptyStateContainer,
  EmptyStateImg,
  EmptyStateTextContainer,
} from "./styled";

import type {
  EvalDetailsMatrixResults,
  Evaluation,
  MatrixViewTypes,
} from "../types";

interface IEvaluationMatrix {
  data?: EvalDetailsMatrixResults;
  evaluation: Evaluation;
  loading?: boolean;
  type: MatrixViewTypes;
}

export const EvaluationMatrix = ({
  data,
  evaluation,
  loading,
  type,
}: IEvaluationMatrix) => {
  const utils = trpc.useContext();

  const isEmpty = !data?.quadrants.length;

  if (loading && isEmpty) return <LoadingSkeleton />;

  if (isEmpty)
    return (
      <EmptyStateContainer>
        <EmptyStateImg />

        <EmptyStateTextContainer>
          <StyledTitle
            variant="headline6"
            setColor="neutral20"
            style={{ textAlign: "center" }}
            children={"Ocorreu um erro"}
          />

          <StyledText
            variant="body3"
            setColor="neutral40"
            style={{ textAlign: "center" }}
            children={
              "Não foi possível exibir as informações, por favor recarregue a página."
            }
          />
        </EmptyStateTextContainer>

        <LinkButton
          variant="neutral"
          children={
            <>
              Recarregar <StyledIcon name="IconRefresh" size={16} />
            </>
          }
          onClick={() =>
            utils.performance.evaluation.getEvaluationDetailsMatrix.reset()
          }
        />
      </EmptyStateContainer>
    );

  return (
    <MainContainer>
      <Row>
        <VerticalAxis
          scales={data?.verticalAxisScale || []}
          verticalName={data?.verticalAxisName || ""}
        />

        <Matrix
          type={type}
          evaluation={evaluation}
          quadrants={data?.quadrants || []}
          loading={loading}
        />
      </Row>

      <Row>
        <HorizontalAxis
          scales={data?.horizontalAxisScale || []}
          horizontalName={data?.horizontalAxisName || ""}
        />
      </Row>
    </MainContainer>
  );
};
