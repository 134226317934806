import { ThemeSpacings } from "@flash-tecnologia/hros-web-ui-v2/dist/types/themeGlobalTokens/spacings";

import styled from "styled-components";

export const Container = styled.div<{ gap: keyof ThemeSpacings }>`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: 100%;
`;
