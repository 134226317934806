import { useCallback, useEffect, useState } from "react";
import { flatMap, groupBy, mapValues } from "lodash-es";

import { StyledText, StyledTitle } from "@utils";
import { EmptyState } from "@assets/index";

import { LinkButton, PillButton } from "@flash-tecnologia/hros-web-ui-v2";

import {
  Body,
  ButtonContainer,
  ContentContainer,
  EmptyStateArea,
  EmptyStateDescription,
  EmptyStateImg,
  FilterButton,
  FlexRow,
  Header,
  HeaderButtons,
  HeaderTitleContainer,
  MainContainer,
  StyledDrawer,
} from "./styled";

import { BodySkeleton, FilterItem } from "./components";

import type { FilterType, IFilterDrawerProps } from "./types";

export const FilterDrawer = ({
  isOpen = false,
  isLoading = false,
  filters = [],
  initialValues,
  onClose,
  onFilter,
  onClear,
  customEmptyState,
  filterBanner,
}: IFilterDrawerProps) => {
  const [filterList, setFilterList] = useState<FilterType[]>([]);

  useEffect(() => {
    const mappedFilters = filters?.map((filter) => {
      if (filter?.type === "date") {
        const initialDate = initialValues?.[filter.accessor];

        return {
          ...filter,
          date: filter?.date || (initialDate && initialDate[0]),
        };
      }

      return {
        ...filter,
        options: filter.options.map((option) => {
          const value = initialValues?.[filter.accessor]?.includes(
            option.value
          );

          return {
            ...option,
            checked: option?.checked || !!value,
          };
        }),
      };
    });

    setFilterList(mappedFilters);
  }, [JSON.stringify(filters), initialValues]);

  const renderEmptyState = useCallback((filterList: FilterType[]) => {
    if (filterList?.length) return;

    if (customEmptyState) {
      return <>{customEmptyState}</>;
    }

    return (
      <EmptyStateArea>
        <EmptyStateImg src={EmptyState} width={128} height={116} />
        <StyledText variant="headline6" fontWeight={700}>
          Não foi possível encontrar filtros
        </StyledText>
        <EmptyStateDescription variant="body3">
          Filtros não encontrados.
        </EmptyStateDescription>
      </EmptyStateArea>
    );
  }, []);

  const onClearHandler = () => {
    setFilterList((old) => {
      return old?.map((item) => ({
        ...item,
        _id: item.accessor,
        date: undefined,
        options: item?.options?.map((option) => ({
          ...option,
          checked: false,
        })),
      }));
    });

    onClear();
  };

  const onFilterHandler = () => {
    const optionsMapped = flatMap(filterList || [], (item) => {
      if (item?.options?.length) {
        return item.options.map((option) => ({
          _id: item.accessor,
          ...option,
        }));
      }

      return {
        _id: item.accessor,
        date: item.date,
      };
    });

    const checkedOptions = optionsMapped.filter(
      (option) => option.checked || option.date
    );

    const filteredValues = mapValues(groupBy(checkedOptions, "_id"), (items) =>
      items.map((item) => item?.value || item?.date)
    );

    onFilter(filteredValues);
  };

  return (
    <StyledDrawer anchor="right" open={isOpen} onClose={onClose}>
      <MainContainer>
        <Header>
          <FlexRow>
            <HeaderTitleContainer>
              <StyledTitle
                variant="headline6"
                children={"Filtros"}
                setColor="neutral10"
              />
            </HeaderTitleContainer>
            <HeaderButtons>
              <div style={{ marginTop: "-16px" }}>
                <PillButton
                  variant="default"
                  size="small"
                  type="secondary"
                  icon="IconX"
                  onClick={onClose}
                />
              </div>
            </HeaderButtons>
          </FlexRow>
        </Header>

        <Body>
          {isLoading ? (
            <BodySkeleton />
          ) : (
            <>
              {filterBanner && <>{filterBanner}</>}
              <ContentContainer>
                {filterList?.map((item) => (
                  <FilterItem
                    key={item.accessor}
                    setFilterList={setFilterList}
                    {...item}
                  />
                ))}
                {renderEmptyState(filters)}
              </ContentContainer>
            </>
          )}
        </Body>

        <ButtonContainer>
          <LinkButton variant="default" onClick={onClearHandler}>
            Redefinir
          </LinkButton>
          <FilterButton
            variant="primary"
            size="large"
            onClick={onFilterHandler}
          >
            Filtrar
          </FilterButton>
        </ButtonContainer>
      </MainContainer>
    </StyledDrawer>
  );
};
