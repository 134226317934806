import { useEffect, useMemo } from "react";

import {
  Dot,
  PillButton,
  Icons,
  Menu,
  Tab,
  dayjs,
  Skeleton,
} from "@flash-tecnologia/hros-web-ui-v2";

import { Feedback } from "./Tabs/Feedback";
import { Competences } from "./Tabs/Competences";
import { BusinessValues } from "./Tabs/BusinessValues";

import { trpc } from "@api/client";

import {
  StyledText,
  StyledTitle,
  dispatchToast,
  getAccessTokenSync,
} from "@utils";

import {
  Body,
  FlexRow,
  Header,
  MainContainer,
  NameContainer,
  FlexContainer,
  Span,
  StyledAvatar,
  StyledDrawer,
  Column,
  Tag,
  HeaderButtons,
} from "./styled";

type IFeedbackDrawerProps = {
  isOpen: boolean;
  feedbackData: any;
  permission: string;
  refetch?: () => void;
  onClose: () => void;
};
export const FeedbackDrawer = ({
  isOpen,
  feedbackData,
  permission,
  refetch,
  onClose,
}: IFeedbackDrawerProps) => {
  const utils = trpc.useContext();

  const { employeeId = "" } = getAccessTokenSync();

  const { data: feedback, isFetching: loading } =
    trpc.performance.feedback.getFeedbackFull.useQuery(
      {
        _id: feedbackData._id || "",
      },
      {
        retry: false,
        refetchOnWindowFocus: false,
        enabled: !!isOpen && !!feedbackData._id,
      }
    );

  const { mutate, isLoading } =
    trpc.performance.feedback.shareFeedback.useMutation({
      onSuccess: () => {
        dispatchToast({
          content: "Feedback compartilhado com sucesso.",
          type: "success",
        });
        refetch && refetch();
      },
      onError: () =>
        dispatchToast({
          content: "Erro ao compartilhar o Feedback. Tente novamente em breve.",
          type: "error",
        }),
    });

  const { mutate: setFeedbackVisualized, isLoading: isSettingVisualized } =
    trpc.performance.feedback.setFeedbackVisualized.useMutation({
      onSuccess: () => {
        utils.performance.feedback.getFeedbackFull.setData(
          {
            _id: feedbackData._id || "",
          },
          (oldData) => {
            if (!oldData) return oldData;

            return {
              ...oldData,
              feedback: { ...oldData.feedback, status: "visualized" },
            };
          }
        );

        refetch && refetch();
      },
    });

  useEffect(() => {
    if (!isOpen) return;

    const isSent = feedback?.feedback.status === "sent";
    const isEmployee = feedback?.feedback?.to?._id === employeeId;

    if (!isSent || !isEmployee) return;

    setFeedbackVisualized({ feedbackId: feedbackData._id });
  }, [feedback?.feedback, isOpen]);

  const renderTag = (status: string | undefined) => {
    if (!status) return;

    const info = {
      draft: {
        label: "Rascunho",
        color: "blue",
        variant: "blue",
      },
      sent: {
        label: "Não visualizado",
        color: "yellow",
        variant: "yellow",
      },
      visualized: {
        label: "Visualizado",
        color: "green",
        variant: "success",
      },
    };

    return (
      <div style={{ display: "flex" }}>
        <Tag setColor={info[status]?.color}>
          <Dot variant={info[status]?.variant} />
          <StyledTitle variant="caption">{info[status]?.label}</StyledTitle>
        </Tag>
      </div>
    );
  };

  const tabItems = useMemo(() => {
    const items = [
      {
        component: (
          <Feedback
            data={feedback?.feedback || feedbackData || {}}
            feedbackRequest={feedback?.request || {}}
            permission={permission}
            onCloseDrawer={onClose}
          />
        ),
        label: "Feedback",
      },
    ];

    if (feedback?.feedback?.companyValues?.length) {
      items.push({
        component: (
          <BusinessValues data={feedback?.feedback?.companyValues || []} />
        ),
        label: "Valores empresariais",
      });
    }

    if (feedback?.feedback?.type === "evaluation")
      items.push({
        component: <Competences feedbackId={feedbackData?._id} />,
        label: "Critérios Avaliativos",
      });

    return items;
  }, [feedbackData, feedback?.feedback]);

  return (
    <StyledDrawer anchor="right" open={isOpen} onClose={onClose}>
      <MainContainer>
        <Header>
          <FlexRow>
            <StyledTitle variant="body1" children={"Feedback"} />
            <HeaderButtons>
              {permission === "answer" && (
                <Menu
                  type={"select"}
                  options={[
                    {
                      onClick: () => {
                        feedbackData.privacy !== "leader" &&
                          mutate({
                            feedbackId: feedbackData._id,
                          });
                      },
                      children:
                        feedbackData.privacy === "leader" ? (
                          <FlexContainer>
                            <Icons name="IconShare" fill="transparent" />
                            <StyledTitle variant="body3">
                              Feedback compartilhado com o líder
                            </StyledTitle>
                          </FlexContainer>
                        ) : (
                          <FlexContainer>
                            <Icons name="IconShare" fill="transparent" />
                            <StyledTitle variant="body3">
                              Compartilhar com líder
                            </StyledTitle>
                          </FlexContainer>
                        ),
                    },
                  ]}
                  disableAutoFocusItem={true}
                  disabled={isLoading}
                  anchorOrigin={{ vertical: "center", horizontal: "center" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <PillButton
                    variant="default"
                    size="small"
                    type="secondary"
                    icon="IconDotsVertical"
                  />
                </Menu>
              )}

              <PillButton
                variant="default"
                size="small"
                icon="IconX"
                type="secondary"
                onClick={onClose}
              />
            </HeaderButtons>
          </FlexRow>

          <FlexContainer>
            <StyledAvatar children={feedbackData?.to?.name.charAt(0)} />
            <NameContainer>
              <StyledTitle variant="body1" setColor="neutral30">
                {feedbackData?.to?.name} <Span>recebeu um</Span>
              </StyledTitle>

              <StyledTitle variant="body3" setColor="neutral30">
                <Span>feedback de </Span>
                {feedbackData?.from?.name}
              </StyledTitle>
            </NameContainer>
          </FlexContainer>
          <FlexContainer style={{ marginTop: "22px" }}>
            <Column style={{ marginRight: "40px" }}>
              <StyledText
                variant="body4"
                children={"Feedback visualizado"}
                style={{ fontWeight: "600" }}
              />
              {isSettingVisualized ? (
                <Skeleton variant="rectangular" width={100} height={26} />
              ) : (
                renderTag(
                  feedback?.feedback?.status || feedbackData?.status || ""
                )
              )}
            </Column>
            <Column>
              <StyledTitle variant="body4" children={"Enviado em"} />
              <StyledTitle variant="body3" style={{ marginBottom: "4px" }}>
                {dayjs(feedbackData?.createdAt).isValid()
                  ? dayjs(feedbackData?.createdAt).format("DD/MM/YY")
                  : "-"}
              </StyledTitle>
            </Column>
          </FlexContainer>
        </Header>
        <Body>
          {loading ? (
            <Skeleton variant="rectangular" height={"500px"} />
          ) : (
            <Tab tabItens={tabItems} />
          )}
        </Body>
      </MainContainer>
    </StyledDrawer>
  );
};
