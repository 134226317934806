import { FormikProps } from "formik";

import { Skeleton, TextEditor } from "@flash-tecnologia/hros-web-ui-v2";

import Banner from "@components/Banner";

import { FormCard } from "@components/Cards";

import { StyledText, track } from "@utils";

import { useBasicInfoForm } from "./hooks";

import { MainContainer, Span, StyledSelect } from "./styled";

import type { BasicInfoFormProps, BasicStepProps } from "../../../types";

interface IBasicInfoFormProps {
  formik: FormikProps<BasicInfoFormProps>;
  isFromRequest?: boolean;
  isLeader?: boolean;
  params?: BasicStepProps["params"];
  onStepSubmit: BasicStepProps["onStepSubmit"];
}

export const BasicInfoForm = ({
  formik,
  isFromRequest,
  isLeader,
  params,
  onStepSubmit,
}: IBasicInfoFormProps) => {
  const hasReceiver = !!params?.receiverId;

  const {
    evaluations,
    loadingEvaluations,
    employees,
    loadingEmployees,
    evaluationSelected,
  } = useBasicInfoForm({
    isLeader,
    type: formik.values.type,
    evaluationId: formik.values.evaluationId,
  });

  return (
    <MainContainer onSubmit={formik.handleSubmit}>
      <FormCard
        title="Dados do feedback"
        description="Configure o tipo de feedback que deseja enviar."
        mandatory={true}
        error={
          (!!formik.touched.type && Boolean(formik.errors.type)) ||
          (!!formik.touched.evaluationId && Boolean(formik.errors.evaluationId))
        }
      >
        <StyledSelect
          label={
            <StyledText variant="body3" setColor="neutral30">
              Tipo de feedback<Span>*</Span>
            </StyledText>
          }
          value={formik?.values?.type || ""}
          disabled={isFromRequest || !isLeader || !!params?.type}
          onSelectChange={(_, option) => {
            track({
              name:
                option?.value === "evaluation"
                  ? "performance_myfeedbacks_sendfeedback_feedbacklinkedtoevaluation_selected"
                  : "performance_myfeedbacks_sendfeedback_spontaneousfeedback_selected",
            });

            formik.setValues({
              ...formik.values,
              type: option?.value,
              to: hasReceiver ? formik.values.to : "",
              evaluationId:
                option?.value != formik.values.type ? "" : formik.values.type,
            });

            onStepSubmit({
              configurationForm: {
                companyValues: [],
                evaluationCriterials: [],
                message: "",
              },
            });
          }}
          options={[
            {
              label: "Feedback espontâneo",
              value: "voluntary",
            },
            {
              label: "Feedback atrelado à avaliação",
              value: "evaluation",
            },
          ]}
          error={formik.touched.type && Boolean(formik.errors.type)}
          helperText={formik.touched.type && formik.errors.type}
          fullWidth
        />

        {formik.values.type === "evaluation" && loadingEvaluations && (
          <Skeleton variant="rounded" width={"100%"} height={"60px"} />
        )}

        {formik.values.type === "evaluation" && !loadingEvaluations && (
          <StyledSelect
            label={
              <StyledText variant="body3" setColor="neutral30">
                Selecionar avaliação<Span>*</Span>
              </StyledText>
            }
            value={formik.values.evaluationId || ""}
            searchable
            onSelectChange={(_, option) => {
              formik.setValues({
                ...formik.values,
                to: hasReceiver ? formik.values.to : "",
                evaluationId: option?.value,
              });

              onStepSubmit({
                configurationForm: {
                  companyValues: [],
                  evaluationCriterials: [],
                  message: "",
                },
              });
            }}
            options={evaluations}
            error={
              formik.touched.evaluationId && Boolean(formik.errors.evaluationId)
            }
            helperText={
              formik.touched.evaluationId && formik.errors.evaluationId
            }
            fullWidth
            disabled={!isLeader}
            noOptionsText="Nenhuma avaliação encontrada"
          />
        )}
      </FormCard>

      <FormCard
        title="Destinatário"
        description="Selecione para quem o feedback será enviado."
        mandatory={true}
        error={!!formik.touched.to && Boolean(formik.errors.to)}
      >
        {isFromRequest && (
          <Banner
            type="info"
            icon="IconInfoCircle"
            title="Responder solicitação de feedback"
            subTitle="Escreva feedbacks a partir das solicitações de seus colegas e ajude a promover uma cultura de trocas construtivas, além de contribuir com o desenvolvimento coletivo de uma forma alinhada com os objetivos da empresa."
            hasHideBanner={false}
            style={{ marginTop: "24px" }}
          />
        )}

        {loadingEmployees && (
          <Skeleton variant="rounded" width={"100%"} height={"60px"} />
        )}

        {!loadingEmployees && (
          <StyledSelect
            label={
              <StyledText variant="body3" setColor="neutral30">
                Para quem será o feedback<Span>*</Span>
              </StyledText>
            }
            disabled={
              !formik.values.type ||
              isFromRequest ||
              hasReceiver ||
              (formik.values.type === "evaluation" && !evaluationSelected)
            }
            fullWidth
            searchable={true}
            value={formik.values.to}
            onSelectChange={(_, option) => {
              formik.setValues({
                ...formik.values,
                to: option?.value,
              });

              onStepSubmit({
                configurationForm: {
                  companyValues: [],
                  evaluationCriterials: [],
                  message: "",
                },
              });
            }}
            options={employees || []}
            error={formik.touched.to && Boolean(formik.errors.to)}
            helperText={formik.touched.to && formik.errors.to}
            noOptionsText="Nenhum colaborador encontrado"
          />
        )}

        {isFromRequest && (
          <TextEditor
            styles={{
              container: { width: "100%" },
            }}
            readOnly={true}
            id="request_message"
            value={formik.values.requestMessage}
            placeholder="Mensagem de solicitação*"
          />
        )}
      </FormCard>
    </MainContainer>
  );
};
