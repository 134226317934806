import { Skeleton, Table } from "@flash-tecnologia/hros-web-ui-v2";

import { BulkActions } from "./BulkActions";

import { useTransferList } from "../../Root";

import { useTable } from "../";

interface IGridProps {
  customEmptyState?: React.ReactNode;
}

export const Grid = ({ customEmptyState }: IGridProps) => {
  const { table, loading } = useTable();

  const { selected } = useTransferList();

  const isAnyRowSelected = !!selected.length;

  if (loading)
    return <Skeleton variant="rounded" height={"800px"} width={"100%"} />;

  if (!table.rows?.length && customEmptyState) return <>{customEmptyState}</>;

  return (
    <Table.Grid.Root>
      {isAnyRowSelected ? (
        <BulkActions />
      ) : (
        <Table.Grid.Header
          getHeaderGroups={table.getHeaderGroups}
          toggleAllRowsExpanded={table.toggleAllRowsExpanded}
        />
      )}

      {table.rows.map((row, index) => (
        <Table.Grid.Row key={index + row.id} row={row} />
      ))}
    </Table.Grid.Root>
  );
};
