import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  user-select: none;
`;

export const IconBg = styled.div<{ setColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 200px;
  width: 40px;
  background-color: ${({ theme, setColor }) => {
    switch (setColor) {
      case "success90":
        return theme.colors.feedback.success[90];
      case "info90":
        return theme.colors.feedback.info[90];
      case "error90":
        return theme.colors.feedback.error[90];
      case "tertiary90":
        return theme.colors.tertiary[90];
      case "secondary95":
        return theme.colors.secondary[95];
      default:
        return "";
    }
  }};
  height: 40px;
`;
