import { useMemo, useState } from "react";
import { cloneDeep, flatMap, compact, uniq } from "lodash-es";

import { EmptyState, Icons, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

import { SearchField } from "@components/SearchField";
import { TagCheckboxFilter } from "@components/TagCheckboxFilter";

import { trpc } from "@api/client";

import { StyledText, dispatchToast } from "@utils";

import {
  Comment,
  ExpandArea,
  ExpandButton,
  InfoArea,
  Line,
  ValueArea,
  ValueNumber,
  Tag,
  FilterArea,
  EmptyStateArea,
} from "./styled";

type ICompetenceValue = {
  order: number;
  name?: string;
  description?: string;
  value?: string;
  scale?: string;
  commentary?: string;
};

type FilterProps = {
  section: string[];
  scale: string[];
};

const CompetenceValue = ({
  order,
  name,
  description,
  value,
  scale,
  commentary,
}: ICompetenceValue) => {
  const [expand, setExpand] = useState(false);

  const info = {
    could_improve: { label: "Pode melhorar" },
    doing_well: { label: "Mandando bem" },
  };

  const hasScaleOrValue = !!value || !!scale;

  return (
    <>
      <ValueArea>
        <ValueNumber>
          <StyledText variant="headline9" setColor="neutral30" fontWeight={700}>
            {order}.
          </StyledText>
        </ValueNumber>
        <InfoArea>
          {hasScaleOrValue && (
            <div style={{ display: "flex", marginBottom: "12px" }}>
              <Tag setColor={"gray"}>
                <StyledText variant="caption" style={{ fontWeight: 600 }}>
                  {value ? info[value]?.label || "" : scale}
                </StyledText>
              </Tag>
            </div>
          )}

          <StyledText
            variant="headline9"
            setColor="neutral30"
            fontWeight={700}
            style={{ marginBottom: "8px" }}
          >
            {name}
          </StyledText>
          <StyledText
            variant="body3"
            setColor="neutral30"
            style={{ marginBottom: "12px" }}
          >
            {description}
          </StyledText>
          {commentary && (
            <>
              <StyledText variant="caption" setColor="neutral50">
                Comentário
              </StyledText>
              <Comment
                variant="body3"
                setColor="neutral30"
                expandComment={expand}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: expand
                      ? commentary
                      : commentary?.length > 160
                      ? commentary?.substr(0, 160) + "..."
                      : commentary,
                  }}
                  style={{
                    lineHeight: "normal",
                    listStylePosition: "inside",
                    position: "relative",
                    display: "inline",
                  }}
                ></div>{" "}
                {commentary?.length > 160 && (
                  <ExpandArea>
                    <ExpandButton
                      variant="neutral"
                      onClick={() => setExpand((prev) => !prev)}
                    >
                      Ver {expand ? "Menos" : "Mais"}{" "}
                      <Icons
                        name={expand ? "IconChevronUp" : "IconChevronDown"}
                        size={16}
                      />
                    </ExpandButton>
                  </ExpandArea>
                )}
              </Comment>
            </>
          )}
        </InfoArea>
      </ValueArea>
      <Line marginBottom="20px" />
    </>
  );
};

export const Competences = ({ feedbackId }) => {
  const [search, setSearch] = useState<string>("");

  const [filter, setFilter] = useState<FilterProps>({
    section: [],
    scale: [],
  });

  const { data: sections = [], isFetching: loadingCriterials } =
    trpc.performance.feedback.getFeedbackCriterialsById.useQuery(
      {
        feedbackId: feedbackId || "",
      },
      {
        enabled: !!feedbackId,
        onError: () => {
          dispatchToast({
            type: "error",
            content:
              "Erro ao procurar critérios avaliativos, favor tentar novamente!",
          });
        },
      }
    );

  const filteredSections = useMemo(() => {
    let dataToFilter = cloneDeep(sections);

    if (filter?.section?.length) {
      dataToFilter = dataToFilter?.filter(({ _id }) =>
        filter?.section.includes(_id)
      );
    }

    if (filter?.scale?.length) {
      dataToFilter = dataToFilter
        ?.map((section) => ({
          ...section,
          criterials: section?.criterials?.filter(
            ({ scale }) => scale && filter?.scale.includes(scale)
          ),
        }))
        .filter((s) => !!s.criterials.length);
    }

    if (!search) return dataToFilter;

    dataToFilter = dataToFilter?.map((section) => ({
      ...section,
      criterials: section?.criterials?.filter((item) => {
        return item?.name?.toLowerCase().includes(search.toLowerCase());
      }),
    }));

    return dataToFilter;
  }, [sections, search, filter]);

  const scaleOptions = uniq(
    flatMap(sections, (item) => compact(item.criterials.map((c) => c.scale)))
  );

  if (loadingCriterials) return <Skeleton width={"100%"} height={"500px"} />;

  return (
    <div>
      <SearchField
        onChange={(s) => setSearch(s)}
        placeholder={"Buscar critério"}
        disabled={false}
        style={{ marginBottom: "24px" }}
      />
      <FilterArea>
        <StyledText variant="body3" setColor="neutral40" fontWeight={600}>
          Filtrar por
        </StyledText>
        <TagCheckboxFilter
          hasLeftIcon={false}
          filterLabel="Seção"
          badgeNumber={filter?.section?.length || 0}
          onClick={(v) => setFilter((prev) => ({ ...prev, section: v }))}
          variant="secondary"
          options={sections?.map((value) => ({
            label: value?.name,
            value: value?._id,
          }))}
        />
        {!!scaleOptions?.length && (
          <TagCheckboxFilter
            hasLeftIcon={false}
            filterLabel="Escala"
            badgeNumber={filter?.scale?.length || 0}
            onClick={(v) => setFilter((prev) => ({ ...prev, scale: v }))}
            variant="secondary"
            showSelectAllButton
            options={scaleOptions?.map((scale) => ({
              label: scale,
              value: scale,
            }))}
          />
        )}
      </FilterArea>

      {filteredSections.map((item) => (
        <>
          <StyledText
            key={item._id}
            variant="headline8"
            children={item.name}
            setColor="secondary50"
            fontWeight={700}
            style={{ marginBottom: "20px" }}
          />
          {item.criterials.map((item) => (
            <CompetenceValue {...item} />
          ))}
        </>
      ))}

      {!filteredSections.length && (
        <EmptyStateArea>
          <EmptyState
            buttonText={""}
            description={"Não foram encontradas competências"}
            buttonProps={{ size: "small", hidden: true }}
          />
        </EmptyStateArea>
      )}
    </div>
  );
};
