import { useCallback } from "react";

import { last } from "lodash-es";

import { DataCard } from "@components/Cards";

import { CardTable } from "../CardTable";

import { StyledTitle, track } from "@utils";

import type { EvaluationType, SectionType } from "../../../types";

import { CardsContainer, FlexContainer } from "./styled";

interface CalibrationMatrixTabProps {
  data?: Partial<EvaluationType["matrix"]>;
  sections: SectionType[];
  onNavigate: React.MouseEventHandler<HTMLButtonElement> | undefined;
  disabled: boolean;
}

export const CalibrationMatrix = ({
  data,
  sections = [],
  onNavigate,
  disabled,
}: CalibrationMatrixTabProps) => {
  const getMatrixSectionName = useCallback(
    (sectionId?: string) => {
      if (!sectionId) return;

      const found = sections.find((section) => section._id === sectionId);

      return found?.name;
    },
    [sections]
  );

  const quadrantsMapped = (data?.quadrants || []).map((quadrant) => ({
    order: quadrant.position,
    value: quadrant.position,
    title: quadrant.name,
    subtitle: quadrant.description,
  }));

  return (
    <CardsContainer>
      <DataCard
        title="Matriz de calibração"
        subTitle="Confira os eixos da matriz."
        editable={!disabled}
        onEditClick={onNavigate}
        sections={[
          {
            title: "Eixo horizontal",
            items: [
              {
                description: (
                  <FlexContainer>
                    <div>
                      <StyledTitle
                        variant="caption"
                        children="Nome do eixo"
                        setColor="neutral50"
                      />
                      <StyledTitle
                        variant="body3"
                        children={data?.horizontalAxis?.name || "-"}
                        setColor="neutral50"
                      />
                    </div>
                    <div>
                      <StyledTitle
                        variant="caption"
                        children="Seção do formulário"
                        setColor="neutral50"
                      />
                      <StyledTitle
                        variant="body3"
                        children={
                          getMatrixSectionName(
                            data?.horizontalAxis?.sectionId
                          ) || "-"
                        }
                        setColor="neutral50"
                      />
                    </div>
                  </FlexContainer>
                ),
              },
            ],
          },
          {
            title: "Eixo vertical",
            items: [
              {
                description: (
                  <FlexContainer>
                    <div>
                      <StyledTitle
                        variant="caption"
                        children="Nome do eixo"
                        setColor="neutral50"
                      />
                      <StyledTitle
                        variant="body3"
                        children={data?.verticalAxis?.name || "-"}
                        setColor="neutral40"
                      />
                    </div>
                    <div>
                      <StyledTitle
                        variant="caption"
                        children="Seção do formulário"
                        setColor="neutral50"
                      />
                      <StyledTitle
                        variant="body3"
                        children={
                          getMatrixSectionName(data?.verticalAxis?.sectionId) ||
                          "-"
                        }
                        setColor="neutral40"
                      />
                    </div>
                  </FlexContainer>
                ),
              },
            ],
          },
        ]}
      />
      <DataCard
        title="Intervalos de nota"
        subTitle="Confira os intervalos de nota da matriz."
        editable={!disabled}
        onEditClick={(e) => {
          track({
            name: "people_strategic_hr_performance_company_evaluations_createevaluation_evaluationform_editmatrix_click",
          });
          onNavigate && onNavigate(e);
        }}
        sections={[
          {
            title: "Eixo horizontal",
            items: [
              {
                description: (
                  <FlexContainer>
                    <div>
                      <StyledTitle
                        variant="caption"
                        children="Início"
                        setColor="neutral50"
                      />
                      <StyledTitle
                        variant="body3"
                        children={data?.horizontalScale?.[0].init || "-"}
                        setColor="neutral40"
                      />
                    </div>
                    <div>
                      <StyledTitle
                        variant="caption"
                        children="Início do quadrante 2"
                        setColor="neutral50"
                      />
                      <StyledTitle
                        variant="body3"
                        children={data?.horizontalScale?.[1].init || "-"}
                        setColor="neutral40"
                      />
                    </div>
                    <div>
                      <StyledTitle
                        variant="caption"
                        children="Fim do quadrante 2"
                        setColor="neutral50"
                      />
                      <StyledTitle
                        variant="body3"
                        children={data?.horizontalScale?.[1].end || "-"}
                        setColor="neutral40"
                      />
                    </div>
                    <div>
                      <StyledTitle
                        variant="caption"
                        children="Final"
                        setColor="neutral50"
                      />
                      <StyledTitle
                        variant="body3"
                        children={last(data?.horizontalScale)?.end || "-"}
                        setColor="neutral40"
                      />
                    </div>
                  </FlexContainer>
                ),
              },
            ],
          },
          {
            title: "Eixo vertical",
            items: [
              {
                description: (
                  <FlexContainer>
                    <div>
                      <StyledTitle
                        variant="caption"
                        children="Início"
                        setColor="neutral50"
                      />
                      <StyledTitle
                        variant="body3"
                        children={data?.verticalScale?.[0].init || "-"}
                        setColor="neutral40"
                      />
                    </div>
                    <div>
                      <StyledTitle
                        variant="caption"
                        children="Início do quadrante 2"
                        setColor="neutral50"
                      />
                      <StyledTitle
                        variant="body3"
                        children={data?.verticalScale?.[1].init || "-"}
                        setColor="neutral40"
                      />
                    </div>
                    <div>
                      <StyledTitle
                        variant="caption"
                        children="Fim do quadrante 2"
                        setColor="neutral50"
                      />
                      <StyledTitle
                        variant="body3"
                        children={data?.verticalScale?.[1].end || "-"}
                        setColor="neutral40"
                      />
                    </div>
                    <div>
                      <StyledTitle
                        variant="caption"
                        children="Final"
                        setColor="neutral50"
                      />
                      <StyledTitle
                        variant="body3"
                        children={last(data?.horizontalScale)?.end || "-"}
                        setColor="neutral40"
                      />
                    </div>
                  </FlexContainer>
                ),
              },
            ],
          },
        ]}
      />
      <DataCard
        title="Quadrantes"
        subTitle="Confira os quadrantes da matriz."
        editable={!disabled}
        onEditClick={onNavigate}
        children={
          <CardTable
            leftColumnTitle="Nome do quadrante"
            rightColumnTitle="Contexto/recomendação"
            sections={[
              {
                data: quadrantsMapped,
              },
            ]}
          />
        }
      />
    </CardsContainer>
  );
};
