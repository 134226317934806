import { Icons, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import { StyledText } from "@utils";

import Banner from "@components/Banner";

import { BannerArea } from "./styled";

export const FilterBanner = () => {
  return (
    <BannerArea>
      <Banner
        type="info"
        icon="IconInfoCircle"
        children={
          <StyledText variant="body4">
            Ao aplicar os filtros de Admitidos, pessoas sem data de admissão
            ainda aparecerão na lista de participantes. Para corrigir, atualize
            as datas
            <LinkButton
              variant="info"
              onClick={() => window.open(`/employees`, "_blank")}
            >
              em massa aqui
              <Icons name="IconExternalLink" fill="transparent" size={14} />
            </LinkButton>
            Se novos admitidos forem incluídos após salvar a avaliação, será
            preciso adicioná-los manualmente.
          </StyledText>
        }
        hasHideBanner={false}
        style={{ padding: "8px 10px" }}
      />
    </BannerArea>
  );
};
