import { useMemo, useState } from "react";
import { cloneDeep } from "lodash-es";
import { MenuItem } from "@mui/material";

import {
  Checkbox,
  DataGrid,
  Icons,
  Typography,
  Pagination,
  Tooltip,
} from "@flash-tecnologia/hros-web-ui-v2";

import {
  dispatchToast,
  pageSizeOptions,
  renderHtmlText,
  StyledText,
} from "@utils";

import {
  CheckboxArea,
  CellArea,
  ItensPerPageArea,
  PaginationArea,
  PaginationContainer,
  StyledSelectField,
  TooltipText,
  TooltipContainer,
} from "./styled";

import { onSelectedChangedType, CriteriaType } from "../../../types";

import type { TableGridCommonProps } from "@components/PaginationTable/types";

interface ITableGridProps extends TableGridCommonProps {
  selectedCriterials: CriteriaType[];
  evaluationCriterials: string[];
  onSelectedChanged: onSelectedChangedType;
}

export const TableGrid = ({
  data,
  pagination,
  loading,
  selectedCriterials,
  evaluationCriterials,
  onPaginationChanged,
  onSelectedChanged,
}: ITableGridProps) => {
  const [selectedItems, setSelectedItems] =
    useState<CriteriaType[]>(selectedCriterials);

  const columns = useMemo(
    () => [
      {
        disableSortBy: true,
        Cell: (info: any) => {
          const _id = info?.row?.original?._id;

          const alreadyOnEvaluation = evaluationCriterials.some(
            (item) => item === _id
          );

          const isSelected =
            alreadyOnEvaluation ||
            selectedItems.some((item) => item._id === _id);

          return (
            <CheckboxArea
              disabled={alreadyOnEvaluation}
              onClick={() => {
                if (!alreadyOnEvaluation) return;

                dispatchToast({
                  type: "error",
                  content: `Este critério avaliativo já foi incluído na avaliação.`,
                });
              }}
            >
              <Checkbox
                checked={isSelected}
                disabled={alreadyOnEvaluation}
                onChange={(_, value) => {
                  let clonedSelected = cloneDeep(selectedItems);

                  if (value) clonedSelected.push(info?.row?.original);
                  else {
                    clonedSelected = clonedSelected.filter(
                      (cs) => cs._id !== _id
                    );
                  }

                  setSelectedItems(clonedSelected);
                  onSelectedChanged(clonedSelected);
                }}
              />
            </CheckboxArea>
          );
        },
        accessor: "checkBoxColumn",
      },
      {
        Header: "Critério",
        disableSortBy: true,
        accessor: "name",
        Cell: ({ row: { original } }) => {
          const { name, description = "" } = original;
          return (
            <CellArea>
              <TooltipText variant="body3" weight={700}>
                {name}
                <TooltipContainer>
                  <Tooltip title={renderHtmlText(description)}>
                    <div>
                      <Icons
                        name="IconInfoCircle"
                        fill="transparent"
                        size={16}
                      />
                    </div>
                  </Tooltip>
                </TooltipContainer>
              </TooltipText>
              <Typography variant="body4">
                {renderHtmlText(description)}
              </Typography>
            </CellArea>
          );
        },
      },
    ],
    [data, selectedItems, evaluationCriterials, evaluationCriterials]
  );

  const { page, pageSize, totalCount } = pagination;

  return (
    <DataGrid
      hasPagination={true}
      columns={columns}
      data={data || []}
      loading={loading}
      initialState={{ pageSize: Number(pageSize) }}
      emptyState={{
        isFiltered: true,
        message: loading ? "" : "Nenhum resultado encontrado!",
      }}
      pageSizeOptions={pageSizeOptions}
      customPagination={({ setPageSize }) => (
        <PaginationArea>
          <ItensPerPageArea>
            <StyledSelectField
              value={pageSize ?? 10}
              onChange={(e) => {
                const newPageSize = Number(e?.target?.value);

                setPageSize(newPageSize);
                onPaginationChanged(page, newPageSize);
              }}
            >
              {pageSizeOptions?.map((opt) => (
                <MenuItem key={opt?.value} value={opt?.value}>
                  {opt?.label}
                </MenuItem>
              ))}
            </StyledSelectField>
            <StyledText variant="body3" setColor={"neutral50"} fontWeight={700}>
              Resultados:&nbsp;
              {page === 1 ? 1 : page * pageSize - pageSize + 1}-
              {page * pageSize > totalCount ? totalCount : page * pageSize}
              &nbsp;de&nbsp;{totalCount || 0}&nbsp;
              {totalCount === 1 ? "item" : "itens"}
            </StyledText>
          </ItensPerPageArea>
          <PaginationContainer>
            <Pagination
              defaultPage={1}
              page={page}
              count={Math.ceil(totalCount / pageSize)}
              onChange={(_, value) => {
                onPaginationChanged(value, pageSize);
              }}
            />
          </PaginationContainer>
        </PaginationArea>
      )}
    />
  );
};
