import { StyledIcon, StyledText } from "@utils";

import {
  MainContainer,
  LeftContainer,
  SelectButton,
  SelectContainer,
  // StyledPillButton,
} from "./styled";

interface IHeaderProps {
  selectedTab: number;
  onTabSelected: (tab: number) => void;
}

export const Header = ({ selectedTab, onTabSelected }: IHeaderProps) => (
  <MainContainer>
    <LeftContainer>
      <SelectContainer>
        <SelectButton
          onClick={() => onTabSelected(0)}
          selected={selectedTab === 0}
        >
          <StyledIcon
            name="IconMenu2"
            size={24}
            style={{ flexShrink: 0 }}
            setColor={selectedTab === 0 ? "secondary50" : "neutral40"}
          />
          <StyledText
            variant="body3"
            weight={600}
            children="Lista"
            setColor={selectedTab === 0 ? "secondary50" : "neutral40"}
          />
        </SelectButton>

        <SelectButton
          onClick={() => onTabSelected(1)}
          selected={selectedTab === 1}
        >
          <StyledIcon
            name="IconLayoutGrid"
            size={24}
            style={{ flexShrink: 0 }}
            setColor={selectedTab === 1 ? "secondary50" : "neutral40"}
          />
          <StyledText
            variant="body3"
            weight={600}
            children="Matriz"
            setColor={selectedTab === 1 ? "secondary50" : "neutral40"}
          />
        </SelectButton>
      </SelectContainer>
    </LeftContainer>

    {/* <StyledPillButton
      size="medium"
      variant="default"
      type="secondary"
      icon="IconDownload"
    /> */}
  </MainContainer>
);
