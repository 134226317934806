import { Button, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";

import { MainContainer } from "./styled";

interface FooterProps {
  isLoading: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  disableSendButton?: boolean;
}

export const Footer = ({
  isLoading,
  disableSendButton,
  onCancel,
  onSubmit,
}: FooterProps) => {
  return (
    <MainContainer>
      <LinkButton
        variant="default"
        children="Voltar"
        style={{ alignSelf: "center" }}
        disabled={false}
        onClick={onCancel}
      />
      <Button
        variant="primary"
        size="large"
        children="Incluir comentários no feedback"
        onClick={() => {
          onSubmit();
        }}
        disabled={isLoading || disableSendButton}
      />
    </MainContainer>
  );
};
