import { useState } from "react";

import { useSearchParams } from "react-router-dom";

import { Header } from "./Header";
import { List } from "./Tabs/List";
import { Matrix } from "./Tabs/Matrix";

import { track } from "@utils";

import type { Evaluation } from "server/src/services/evaluation/types";

interface IResultsProps {
  evaluation: Evaluation;
}

const tabsNames = {
  ["list"]: 0,
  ["matrix"]: 1,
};

const tabsIndexes = {
  0: "list",
  1: "matrix",
};

export const Results = ({ evaluation }: IResultsProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedTab, setSelectedTab] = useState(
    tabsNames[searchParams.get("resultsTab") || ""] || 0
  );

  const tabs = {
    0: <List evaluation={evaluation} />,
    1: <Matrix evaluation={evaluation} />,
  };

  const onTabSelected = (tab: number) => {
    setSelectedTab(tab);

    searchParams.set("resultsTab", tabsIndexes[tab] || "list");

    setSearchParams(searchParams);

    const tracks = {
      0: "people_strategic_hr_performance_company_evaluations_evaluationdetails_results_matrixview_clicked",
      1: "people_strategic_hr_performance_company_evaluations_evaluationdetails_results_list_calibrate_clicked",
    };

    const trackName = tracks[selectedTab];

    if (trackName) track({ name: trackName });
  };

  return (
    <>
      <Header selectedTab={selectedTab} onTabSelected={onTabSelected} />
      {tabs[selectedTab] || <></>}
    </>
  );
};
