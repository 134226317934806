import styled from "styled-components";

import { Radio } from "@flash-tecnologia/hros-web-ui-v2";

export const ViewContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
  gap: 32px;
  user-select: none;
`;

export const RadioContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
  :hover {
    cursor: ${({ disabled }) => (disabled ? "" : "pointer")};
  }
`;

export const StyledRadio = styled(Radio)`
  & .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
  }
`;
