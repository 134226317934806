import styled from "styled-components";

export const IconStyled = styled.div`
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 200px;
  background: var(--color-neutral-90);
  gap: 10px;
`;

export const BodyStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: center;
`;

export const EmptyListContainer = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.spacings.xs3};
  align-items: center;
  padding: ${({ theme }) => `0px ${theme.spacings.xs}`};
  gap: 16px;

  flex-direction: column;
`;

export const List = styled.div`
  max-height: 1000px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  padding-left: ${({ theme }) => `${theme.spacings.xs}`};
  padding-right: ${({ theme }) => `${theme.spacings.xs}`};
  gap: ${({ theme }) => `${theme.spacings.xs}`};
`;

export const ListItemStyled = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => `${theme.spacings.xs4}`};
`;

export const ListItemName = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const ListItemTrashStyled = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  align-items: center;
  float: right;
`;
