import styled from "styled-components";

export const CardContainer = styled.div<{ error?: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 40px;
  border: 1px solid
    ${({ theme, error }) =>
      error ? theme.colors.feedback.error[50] : theme.colors.neutral[90]};
  border-radius: 12px;
  margin-bottom: 38px;
`;
