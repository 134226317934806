import { FormikProps } from "formik";

import { Tag } from "@flash-tecnologia/hros-web-ui-v2";

import { ValuesList } from "./ValuesList";

import { StyledText, StyledTitle } from "@utils";

import { CardContainer } from "./styled";

import type { ConfigurationFormProps } from "../../../types";

interface ICompanyValuesProps {
  formik: FormikProps<ConfigurationFormProps>;
  isLoading: boolean;
  isFromRequest?: boolean;
}

export const CompanyValues = ({
  formik,
  isLoading,
  isFromRequest,
}: ICompanyValuesProps) => {
  return (
    <CardContainer
      error={
        !!formik.touched.companyValues && Boolean(formik.errors.companyValues)
      }
    >
      <Tag
        variant={"primary"}
        children={"Opcional"}
        style={{ marginBottom: "16px" }}
      />
      <StyledTitle
        setColor="neutral30"
        variant="body2"
        children={"Valores da empresa"}
        style={{ marginBottom: "4px" }}
      />
      <StyledText
        setColor="neutral50"
        variant="body4"
        children={
          "Insira valores empresariais que orientarão a realização dos feedbacks."
        }
        style={{ marginBottom: "24px" }}
      />

      <ValuesList
        formik={formik}
        onValuesChanged={(values) =>
          formik.handleChange({
            target: {
              id: "companyValues",
              value: values,
            },
          })
        }
        loading={isLoading}
        isFromRequest={isFromRequest}
      />
    </CardContainer>
  );
};
