import styled from "styled-components";

import { Drawer } from "@mui/material";
import { Button } from "@flash-tecnologia/hros-web-ui-v2";
import { StyledText } from "@utils";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

export const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    width: 100%;
    max-width: 671px;
  }
`;

export const Header = styled.div`
  padding: 40px 24px 24px 40px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`;

export const HeaderTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  overflow-y: scroll;
  overflow-x: hidden;

  padding: 40px;
  gap: 40px;
`;

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 40px;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  button {
    height: max-content;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const FilterButton = styled(Button)`
  width: 266px;
`;

export const EmptyStateArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 40px;
`;

export const EmptyStateImg = styled.img`
  margin-bottom: 32px;
  width: 128px;
  height: 116px;
`;

export const EmptyStateDescription = styled(StyledText)`
  margin: 16px 0 32px;
  text-align: center;
`;
