import styled from "styled-components";

const Container = styled.div<{ error?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 40px;
  border: 1px solid
    ${({ theme, error }) =>
      error ? theme.colors.feedback.error[50] : theme.colors.neutral[90]};
  border-radius: 12px;
  gap: 32px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Asterisk = styled.span`
  color: ${({ theme }) => theme.colors.feedback.error[40]};
`;

export { Container, Asterisk, TitleContainer, Row };
