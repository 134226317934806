import { WarnText, InfoText } from "./styled";

interface UnfilledFieldProps {
  value: string;
}
export const UnfilledField = ({ value }: UnfilledFieldProps) => {
  return value ? (
    <InfoText variant="body3" tag="span">
      <div
        dangerouslySetInnerHTML={{
          __html: value,
        }}
        style={{
          lineHeight: "normal",
          listStylePosition: "inside",
        }}
      ></div>
    </InfoText>
  ) : (
    <WarnText variant="body3">Não preenchido</WarnText>
  );
};
